import React from 'react';
import { bool } from 'prop-types';
import cx from 'classnames';

import { transformIndexToUppercaseLetter } from 'utils/common';
import useStyles from 'hooks/useStyles';

import styles from './QuizQuestionsContent.scss';

const QuizQuestionsContent = ({ showEnterAnimation }) => {
  useStyles(styles);

  return (
    <div
      className={cx(styles.root, {
        [styles.moveIn]: showEnterAnimation,
      })}
    >
      {[...Array(4).keys()].map(index => (
        <div key={index} className={styles.item}>
          {transformIndexToUppercaseLetter(index)}
        </div>
      ))}
    </div>
  );
};

QuizQuestionsContent.propTypes = { showEnterAnimation: bool.isRequired };

export default QuizQuestionsContent;
