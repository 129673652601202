// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1_bey{display:-ms-flexbox;display:flex;grid-gap:32px;gap:32px;grid-gap:2rem;gap:2rem}@media(max-width:1439px){._1_bey{-ms-flex-direction:column;flex-direction:column}}@media(min-width:1440px){._1Mqge{width:23rem}}._38rbI{display:grid;-ms-flex:1 1;flex:1 1}@media(min-width:768px){._38rbI{grid-template-columns:repeat(2,minmax(0,1fr));grid-gap:2rem;gap:2rem}}@media(max-width:767px){._38rbI{grid-template-columns:minmax(0,1fr);grid-gap:1rem;gap:1rem}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "_1_bey",
	"titleSection": "_1Mqge",
	"contentList": "_38rbI"
};
module.exports = exports;
