// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./images/helpOnDemand.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@media(min-width:1024px){._17yND{grid-template-areas:\"allSubjects helpOnDemand\" \"tutors tutors\"}._17yND ._3Mx1U{grid-area:allSubjects}._17yND ._1eLDb{grid-area:helpOnDemand}._17yND .W1aUe{grid-area:tutors}}@media(min-width:1440px){._1K2UX{grid-template-columns:minmax(0,1fr) 32rem}}@media(min-width:1024px){._1K2UX{display:grid;-ms-flex-align:center;align-items:center;height:29.5rem;padding:2rem 0 2rem 5rem}}@media(min-width:1024px)and (max-width:1439px){._1K2UX{grid-template-columns:minmax(0,1fr) 27.5rem}}@media(min-width:1024px){.TmFK-{padding:0}}@media(min-width:1024px){._17YIB{height:100%}}@media(max-width:1023px){._17YIB{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}}._1z7hD{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;padding-top:1rem;text-transform:uppercase}._12CFc{padding-top:.125rem}._12CFc path[fill]{fill:#0373f9}._2vNtI{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat bottom}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"highlightSection": "_17yND",
	"allSubjects": "_3Mx1U",
	"helpOnDemand": "_1eLDb",
	"tutors": "W1aUe",
	"tutorsHighlight": "_1K2UX",
	"tutorsTitleSection": "TmFK-",
	"tutorsContent": "_17YIB",
	"link": "_1z7hD",
	"icon": "_12CFc",
	"helpOnDemandContent": "_2vNtI"
};
module.exports = exports;
