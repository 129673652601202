import React, { useContext, useRef, useEffect } from 'react';
import { oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';

import useStyles from 'hooks/useStyles';
import Button from 'components/Button';
import ContextStore from 'utils/createContext';

import { REGULAR_CLASS, COURSE, QA } from '../../../../../../constants';
import ChattingAnimation from '../../../../../ChattingAnimation';

import styles from './Container.scss';

const Container = ({ currentSection }) => {
  useStyles(styles);
  const { t } = useTranslation(['landingPage']);
  const playerRef = useRef(null);
  const { onSlideCTAClick } = useContext(ContextStore);
  const currentSectionI18nKey = `home.jumbotron.${currentSection}`;
  const isQASection = currentSection === QA;

  const youtubeOptions = {
    height: '100%',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      modestbranding: 1,
      controls: 0,
    },
  };

  useEffect(() => {
    if (!playerRef.current) {
      return;
    }
    playerRef.current.internalPlayer.mute();
  }, [currentSection]);

  return (
    <div className={styles.root}>
      <div className={styles.titleSection}>
        <h6>{t(`${currentSectionI18nKey}.subtitle`)}</h6>
        <h1
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: t(`${currentSectionI18nKey}.title`),
          }}
        />
        <Button
          className={styles.ctaButton}
          text={t(`${currentSectionI18nKey}.cta`)}
          onClick={() => onSlideCTAClick(currentSection)}
        />
      </div>
      <div className={styles.content}>
        {isQASection ? (
          <ChattingAnimation showBackground={false} />
        ) : (
          <YouTube
            ref={playerRef}
            opts={youtubeOptions}
            containerClassName={styles.player}
            videoId={t(`${currentSectionI18nKey}.videoId`)}
          />
        )}
      </div>
    </div>
  );
};

Container.propTypes = {
  currentSection: oneOf([REGULAR_CLASS, COURSE, QA]).isRequired,
};

export default Container;
