import { useContext } from 'react';
import ApplicationContext from 'components/ApplicationContext';

export default function useGetCurrentDevice() {
  const { screenWidth } = useContext(ApplicationContext);

  const { isWideDesktop, isDesktop } = screenWidth;

  return { ...screenWidth, isPC: isWideDesktop || isDesktop };
}
