// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1MFyj{position:relative}._1MFyj .swiper-container{height:620px;height:38.75rem}._1MFyj .swiper-pagination-bullet-active{background-color:#0373f9}._1MFyj .swiper-pagination-bullets{bottom:0}._1ca4d{margin-top:2rem}._3lx7h{display:inline-block;margin-right:1rem}._2TqGU{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;position:absolute;bottom:32px;bottom:2rem;left:0;border:none;background-color:#fff;padding:1.125rem;-webkit-box-shadow:0 .25rem 2rem rgba(98,168,253,.08);box-shadow:0 .25rem 2rem rgba(98,168,253,.08);border-radius:100px;color:#0373f9;z-index:2;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;width:100%;font-size:16px;font-size:1rem;font-weight:500;line-height:20px;line-height:1.25rem;font-family:var(--heading-font);letter-spacing:var(--letter-spacing)}@media(min-width:768px)and (max-width:1023px)and (orientation:portrait){._2TqGU{right:40%}}.Ns384{margin-left:.5rem}.Ns384 path[fill]{fill:#0373f9}", ""]);
// Exports
exports.locals = {
	"root": "_1MFyj",
	"thumbnailContainer": "_1ca4d",
	"thumbnail": "_3lx7h",
	"nextButton": "_2TqGU",
	"chevron": "Ns384"
};
module.exports = exports;
