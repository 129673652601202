import React from 'react';
import { bool, number, string, oneOfType, object, func } from 'prop-types';
import cx from 'classnames';
import useStyles from 'hooks/useStyles';

import styles from './CircleProgressBar.scss';

function CircleProgressBar({
  id,
  progress,
  classNameMap,
  radius,
  strokeWidth,
  renderInnerCircle,
}) {
  useStyles(styles);
  const isCompleted = progress === 1;
  const circumference = radius * 2 * Math.PI;
  const centerPointDistance = radius + strokeWidth / 2;
  const svgSize = radius * 2 + strokeWidth;
  const strokeDashoffset = (1 - progress) * circumference;

  const circleInfo = [
    {
      key: 'bar',
      className: cx(styles.bar, classNameMap.bar),
      style: {
        strokeWidth,
      },
    },
    {
      key: 'progress',
      className: cx(
        styles.progress,
        {
          [styles.completed]: isCompleted,
        },
        classNameMap.progress,
      ),
      style: {
        strokeDasharray: circumference,
        strokeDashoffset,
        strokeWidth,
      },
    },
  ];

  const renderPercentageOfProgress = () => {
    const percentageOfProgress = Math.trunc(progress * 100);

    return (
      <span
        className={styles.percentage}
        style={{
          width: svgSize,
          height: svgSize,
          lineHeight: `${svgSize}px`,
        }}
      >
        {`${percentageOfProgress}%`}
      </span>
    );
  };

  const innerCircle = renderInnerCircle
    ? renderInnerCircle({ className: styles.percentage })
    : renderPercentageOfProgress();

  return (
    <div className={cx(styles.root, classNameMap.root)}>
      {innerCircle}
      <div className={cx(styles.graphics, classNameMap.graphics)}>
        <svg id={id} width={svgSize} height={svgSize}>
          {circleInfo.map(({ key, ...props }) => (
            <circle
              key={key}
              cx={centerPointDistance}
              cy={centerPointDistance}
              r={radius}
              {...props}
            />
          ))}
        </svg>
      </div>
    </div>
  );
}

CircleProgressBar.propTypes = {
  id: oneOfType([number, string]).isRequired,
  isStopAutoPlay: bool,
  progress: number,
  radius: number,
  strokeWidth: number,
  classNameMap: object,
  renderInnerCircle: func,
};

CircleProgressBar.defaultProps = {
  isStopAutoPlay: false,
  progress: 1,
  radius: 26,
  strokeWidth: 4,
  classNameMap: {},
  renderInnerCircle: undefined,
};
export default CircleProgressBar;
