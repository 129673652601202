import React from 'react';
import { string, oneOf, arrayOf } from 'prop-types';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';
import { COVER, REGULAR_CLASS, COURSE, QA } from '../../../../../../constants';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './Background.scss';

const Background = ({ className, sectionList, currentSection }) => {
  useStyles(styles);
  const slidesSectionList = sectionList.filter(
    sectionName => sectionName !== COVER,
  );
  const sectionImageNameList = {
    [REGULAR_CLASS]: ['RCImageLeft', 'RCImageRight'],
    [COURSE]: ['courseImageLeft', 'courseImageRight'],
    [QA]: ['QAImageLeft', 'QAImageRight'],
  };

  return (
    <ul
      style={{
        '--length': slidesSectionList.length,
        '--currentSectionIndex': slidesSectionList.indexOf(currentSection),
      }}
      className={cx(styles.root, className)}
    >
      {slidesSectionList.map(sectionName => (
        <li key={sectionName} className={styles.section}>
          {sectionImageNameList[sectionName].map(imageName => (
            <div key={imageName} className={styles[imageName]} />
          ))}
        </li>
      ))}
    </ul>
  );
};

Background.propTypes = {
  className: string.isRequired,
  sectionList: arrayOf(string).isRequired,
  currentSection: oneOf([COVER, REGULAR_CLASS, COURSE, QA]).isRequired,
};

export default Background;
