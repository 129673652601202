// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3-WFB{min-height:70vh;width:100%;margin:0 auto;padding:0 1rem;position:relative}@media(max-width:767px){._3-WFB{margin-top:-3rem}}@media(min-width:768px){._3-WFB{padding:0 2.5rem}}@media(min-width:1024px)and (max-width:1439px){._3-WFB{padding:0 3rem}}@media(min-width:1440px){._3-WFB{max-width:78rem;padding:0}}@media(max-width:1023px){.QP0c8{position:relative;top:0;display:-ms-inline-flexbox!important;display:inline-flex!important}}@media(max-width:767px){.QP0c8{display:-ms-flexbox!important;display:flex!important;position:absolute!important;z-index:1;top:-1.5rem;width:calc(100% - 2rem)}}._165rm{position:absolute;top:-120px;top:-7.5rem;z-index:1}@media(min-width:1024px)and (max-width:1439px){._165rm{width:calc(100% - 6rem)!important}}@media(max-width:1023px){.TtmIm{margin-left:.5rem;display:-ms-inline-flexbox;display:inline-flex}}@media(max-width:767px){.TtmIm{margin-left:0;margin-top:1.6rem}}@media(max-width:767px){.TtmIm,._3bVEE{display:-ms-flexbox;display:flex}._3bVEE{margin-top:4.7rem}}.oZF9F{position:relative;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;grid-gap:16px;gap:16px;grid-gap:1rem;gap:1rem;margin-top:2.5rem}@media(max-width:1023px){.oZF9F{margin-top:1.5rem}}._27evg{width:49%}@media(max-width:1439px){._27evg{width:100%}}._2uFuS{position:absolute;left:-220px;left:-13.75rem;bottom:168px;bottom:10.5rem;z-index:-1}@media(max-width:1439px){._2uFuS{display:none}}", ""]);
// Exports
exports.locals = {
	"root": "_3-WFB",
	"levelSelectorSelect": "QP0c8",
	"levelSelectorFilter": "_165rm",
	"courseGroup": "TtmIm",
	"shiftCourseGroup": "_3bVEE",
	"classCardList": "oZF9F",
	"classCard": "_27evg",
	"squareWithRound": "_2uFuS"
};
module.exports = exports;
