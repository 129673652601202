import React, { Fragment } from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';
import UniverseBackground from '../../../UniverseBackground';
import commonVerticalPadding from '../../../../../../commonVerticalPadding.scss';
import commonHorizontalPaddingStyles from '../../../../../../commonHorizontalPadding.scss';

import styles from './TopSection.scss';

const TopSection = ({ titleSectionClassName }) => {
  useStyles(commonVerticalPadding, commonHorizontalPaddingStyles, styles);
  const { t } = useTranslation(['landingPage']);

  return (
    <Fragment>
      <UniverseBackground />
      <div
        className={cx(
          styles.titleSection,
          commonVerticalPadding.container,
          commonHorizontalPaddingStyles.container,
          titleSectionClassName,
        )}
      >
        <h1
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: t('home.jumbotron.general.title'),
          }}
        />
        <h4
          className={styles.subTitle}
          dangerouslySetInnerHTML={{
            __html: t('home.jumbotron.general.subtitle'),
          }}
        />
      </div>
    </Fragment>
  );
};

TopSection.propTypes = {
  titleSectionClassName: string.isRequired,
};

export default TopSection;
