// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./images/stars.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./images/leftCloud.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./images/rightCloud.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./images/planet.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./images/mobileStars.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, ".bmFwL{color:#fff;text-align:center;padding:7.5rem 0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");background-position-x:calc(50% + 2.5rem),calc(50% - 27rem),calc(50% + 29rem),calc(50% - 23rem);background-position-y:52%,bottom,bottom,top;background-repeat:no-repeat;background-color:#0373f9}@media(min-width:1440px){.bmFwL{background-position-x:55%,left,right,18%}}@media(max-width:767px){.bmFwL{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");background-position-x:calc(50% + 5.5rem),left,right,calc(50% - 3rem);background-position-y:75%,bottom,bottom,top;background-size:auto,50%,50%,auto}}.DUKa6{margin-bottom:1rem}._1-gU2{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-pack:center;justify-content:center;grid-gap:8px;gap:8px;grid-gap:.5rem;gap:.5rem}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "bmFwL",
	"title": "DUKa6",
	"download": "_1-gU2"
};
module.exports = exports;
