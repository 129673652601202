import React, { useMemo, useRef, useState } from 'react';
import { bool, array, func } from 'prop-types';

import useStyles from 'hooks/useStyles';
import { Swiper, SwiperSlide } from 'swiper/react';
import swiperStyles from 'swiper/swiper.scss';

import { ChevronIcon } from 'components/Icon';
import CaseBlock from '../CaseBlock';
import CaseCard from '../CaseCard';
import Thumbnail from '../Thumbnail';

import styles from './TestimonialCommon.scss';

const TestimonialCommon = ({ data, isTablet, isDesktop, t }) => {
  useStyles(swiperStyles, styles);
  const [currentIndex, setCurrentIndex] = useState(0);
  const swiperRef = useRef();
  const slidesPerView = useMemo(() => {
    if (isDesktop) {
      return 1.5;
    }
    return 2;
  }, [isDesktop]);

  const CaseComponent = isTablet ? CaseCard : CaseBlock;

  return (
    <div className={styles.root}>
      <button
        className={styles.nextButton}
        type="button"
        onClick={() => swiperRef.current.slideNext()}
      >
        {t('testimonial.cta')}
        <ChevronIcon className={styles.chevron} />
      </button>
      <Swiper
        onInit={ref => {
          swiperRef.current = ref;
        }}
        slidesPerView={slidesPerView}
        spaceBetween={64}
        onSlideChange={swiper => {
          setCurrentIndex(swiper.realIndex);
        }}
        loop
      >
        {data.map(content => (
          <SwiperSlide key={content.studentName}>
            {({ isActive }) => (
              <CaseComponent
                isActive={isActive}
                exceedMode="scroll"
                {...content}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={styles.thumbnailContainer}>
        {data.map(({ avatar, studentName }, index) => (
          <Thumbnail
            key={studentName}
            avatar={avatar}
            alt={studentName}
            isActive={currentIndex === index}
            onClick={() => {
              swiperRef.current.slideTo(index + 2);
            }}
            className={styles.thumbnail}
          />
        ))}
      </div>
    </div>
  );
};

TestimonialCommon.propTypes = {
  data: array.isRequired,
  isTablet: bool.isRequired,
  isDesktop: bool.isRequired,
  t: func.isRequired,
};

TestimonialCommon.defaultProps = {};

export default TestimonialCommon;
