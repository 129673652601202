// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2gJNj{padding-bottom:.75rem}._1XE2K{font-size:80px;font-size:5rem;font-weight:500;line-height:92px;line-height:5.75rem;font-family:var(--heading-font);letter-spacing:.5px}@media(max-width:1023px){._1XE2K{font-size:3.5rem;line-height:3.75rem}}@media(min-width:768px){._1XE2K{padding-bottom:1.5rem}}@media(max-width:767px){._1XE2K{padding-bottom:1rem}}.o7Bn9{color:#595757;font-size:18px;font-weight:400;line-height:29px;font-family:var(--base-font)}._36g1- ._2gJNj{color:#0373f9}._2xcaF ._2gJNj{color:#5656e2}._17Zgn{text-align:center}._17Zgn ._2gJNj{color:#03d8d8}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"subtitle": "_2gJNj",
	"title": "_1XE2K",
	"description": "o7Bn9",
	"regularClass": "_36g1-",
	"course": "_2xcaF",
	"qa": "_17Zgn"
};
module.exports = exports;
