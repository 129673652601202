// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3n4pI path[fill]{fill:#f4c708}._1aXK8 path[fill]{fill:#dcdddd}._1NSuB{stroke:#9fa0a0}._1Qnuy{stroke:#f4c708}._3UQHj path[fill]{fill:#595757}._2poWI path[fill]{fill:#231815}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"filled": "_3n4pI",
	"unfilled": "_1aXK8",
	"hollow": "_1NSuB",
	"brightHollow": "_1Qnuy",
	"grayFilled": "_3UQHj",
	"darkFilled": "_2poWI"
};
module.exports = exports;
