import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  getFetchBannerListState,
  getFetchRegularClassLessonListState,
  getFetchRegularClassInstructorListState,
  getFetchCourseListState,
  getFetchAcademyListState,
  getFetchSiteInfoState,
} from 'reduxModules/actionStatus/selector';

const useLoading = () => {
  const [loading, setLoading] = useState(true);

  const fetchSiteInfoState = useSelector(getFetchSiteInfoState);
  const fetchBannerListState = useSelector(getFetchBannerListState);
  const fetchRegularClassLessonListState = useSelector(
    getFetchRegularClassLessonListState,
  );
  const fetchRegularClassInstructorListState = useSelector(
    getFetchRegularClassInstructorListState,
  );
  const fetchCourseListState = useSelector(getFetchCourseListState);
  const fetchAcademyListState = useSelector(getFetchAcademyListState);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (
      fetchSiteInfoState.isReady &&
      !fetchBannerListState.isLoading &&
      !fetchRegularClassLessonListState.isLoading &&
      !fetchRegularClassInstructorListState.isLoading &&
      !fetchCourseListState.isLoading &&
      !fetchAcademyListState.isLoading
    ) {
      // Wait for DOM render
      const timeout = setTimeout(() => setLoading(false), 1500);

      return () => clearTimeout(timeout);
    }
  }, [
    fetchSiteInfoState,
    fetchBannerListState,
    fetchRegularClassLessonListState,
    fetchRegularClassInstructorListState,
    fetchCourseListState,
    fetchAcademyListState,
  ]);

  return loading;
};

export default useLoading;
