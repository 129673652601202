import React from 'react';
import { string, array, oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { isMobile } from 'react-device-detect';

import useStyles from 'hooks/useStyles';

import styles from './ShortcutTooltip.scss';

const ShortcutTooltip = ({
  className,
  controllerClassName,
  name,
  shortcutList,
  placement,
}) => {
  useStyles(styles);
  const { t } = useTranslation(['common']);

  if (isMobile) {
    return null;
  }

  return (
    <div
      className={cx(
        styles.root,
        `${controllerClassName}-tooltip`,
        {
          [styles.center]: placement === 'center',
          [styles.right]: placement === 'right',
        },
        className,
      )}
    >
      <p className={styles.text}>{t(`common.videoPlayer.${name}`)}</p>
      <div className={styles.shortcutList}>
        {shortcutList?.map(({ i18nKey, text }) => (
          <p key={text || i18nKey} className={styles.shortcut}>
            {i18nKey ? t(`common.videoPlayer.${i18nKey}`) : text}
          </p>
        ))}
      </div>
    </div>
  );
};

ShortcutTooltip.propTypes = {
  controllerClassName: string.isRequired,
  name: string.isRequired,
  shortcutList: array.isRequired,
  className: string,
  placement: oneOf(['left', 'center', 'right']),
};

ShortcutTooltip.defaultProps = {
  className: '',
  placement: 'center',
};

export default ShortcutTooltip;
