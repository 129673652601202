// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1c035{position:relative;min-height:382.88px;min-height:23.93rem}._1c035 .swiper-container{height:632px;height:39.5rem}@media(min-width:768px)and (max-width:1023px)and (orientation:portrait){._1c035 .swiper-container{height:39.5rem}}@media(min-width:1024px)and (max-width:1439px){._1c035 .swiper-container{width:calc(100% + 12rem);margin-left:-6rem;padding-left:6rem;padding-right:6rem}}@media(min-width:1440px){._1c035 .swiper-container{padding-left:0;padding-right:0}}._1iLhn{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;position:absolute;top:36%;-webkit-transform:translate3d(50%,0,0);transform:translate3d(50%,0,0);border:none;background-color:#fff;padding:1.125rem 1.5rem;-webkit-box-shadow:0 .25rem 2rem rgba(98,168,253,.08);box-shadow:0 .25rem 2rem rgba(98,168,253,.08);border-radius:100px;color:#0373f9;z-index:2;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;right:44%;font-size:16px;font-size:1rem;font-weight:500;line-height:20px;line-height:1.25rem;font-family:var(--heading-font);letter-spacing:var(--letter-spacing)}@media(max-width:1023px){._1iLhn{right:39%}}@media(min-width:1024px)and (max-width:1439px){._1iLhn{right:24%}}.EXKn5{margin-left:.5rem}.EXKn5 path[fill]{fill:#0373f9}._35dt9{margin-top:2rem}.Ro4_6{display:inline-block;margin-right:1rem}", ""]);
// Exports
exports.locals = {
	"root": "_1c035",
	"nextButton": "_1iLhn",
	"chevron": "EXKn5",
	"thumbnailContainer": "_35dt9",
	"thumbnail": "Ro4_6"
};
module.exports = exports;
