// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._38kEK{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;grid-gap:32px;gap:32px;grid-gap:2rem;gap:2rem;width:640px;width:40rem}@media(min-width:1440px){._38kEK{grid-gap:1.5rem;gap:1.5rem;width:79.5rem}}@media(max-width:767px){._38kEK{grid-gap:1.5rem 0;gap:1.5rem 0;width:17rem}}._12_HD{width:192px;width:12rem}@media(max-width:767px){._12_HD{width:8.5rem}}._2ulEd{width:192px;width:12rem;height:48px;height:3rem}@media(max-width:767px){._2ulEd{width:8.5rem;height:2.125rem}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"container": "_38kEK",
	"img": "_12_HD",
	"loading": "_2ulEd"
};
module.exports = exports;
