// eslint-disable-next-line import/prefer-default-export
export const getZendeskFAQLink = state => state.siteInfo.zendeskFAQLink;
export const getOfferingList = state => state.siteInfo.offerings;
export const getMultipleOfferingMode = state =>
  state.siteInfo.multipleOfferingMode;
export const getCampaignLinks = state => state.siteInfo.campaignLinks;
// show instructor links
export const getShowLiveInstructorBtn = state =>
  state.siteInfo.showInstructorBtn.liveTopic;
export const getShowCourseInstructorBtn = state =>
  state.siteInfo.showInstructorBtn.course;
