// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1NQ0q{position:relative;z-index:1;padding-bottom:0}@media(min-width:1440px){._1NQ0q{padding-top:5rem}}@media(min-width:768px){._1NQ0q{text-align:center}}._2xnKU{font-size:80px;font-size:5rem;font-weight:500;line-height:92px;line-height:5.75rem;font-family:var(--heading-font);letter-spacing:.5px}@media(max-width:1023px){._2xnKU{font-size:3.5rem;line-height:3.75rem;font-size:3rem;line-height:3.5rem}}._21eXr{padding-top:.75rem}@media(max-width:767px){._21eXr{display:none}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"titleSection": "_1NQ0q",
	"title": "_2xnKU",
	"subTitle": "_21eXr"
};
module.exports = exports;
