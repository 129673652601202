import React from 'react';
import { string, func } from 'prop-types';

import useStyles from 'hooks/useStyles';
import Button from 'components/Button';

import styles from './SubSectionTitle.scss';

const SubSectionTitle = ({
  className,
  title,
  description,
  buttonText,
  onClick,
}) => {
  useStyles(styles);

  return (
    <div className={className}>
      <h3
        className={styles.title}
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      {description && <p className={styles.description}>{description}</p>}
      {buttonText && (
        <Button
          className={styles.button}
          type="primary"
          onClick={onClick}
          text={buttonText}
        />
      )}
    </div>
  );
};

SubSectionTitle.propTypes = {
  title: string.isRequired,
  className: string,
  description: string,
  buttonText: string,
  onClick: func,
};

SubSectionTitle.defaultProps = {
  className: '',
  description: '',
  buttonText: '',
  onClick: null,
};

export default SubSectionTitle;
