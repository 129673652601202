// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2HUgp{padding:0 .75rem}@media(min-width:1024px){._2HUgp{padding:0 6.125rem}}@media(min-width:1024px)and (max-width:1439px){._2HUgp{padding:0 2.625rem}}@media(min-width:768px)and (max-width:1023px){._2HUgp{padding:0 4.625rem}}._17UzP{text-align:center;font-size:14px;font-weight:400;line-height:23px;font-family:var(--base-font)}@media(max-width:767px){._17UzP{font-size:16px;line-height:26px}}._1Tvqx{text-align:center;padding-bottom:2rem}._7AYZA{display:grid;grid-template-columns:repeat(3,minmax(0,1fr));grid-gap:24px;gap:24px;grid-gap:1.5rem;gap:1.5rem;margin-bottom:3.75rem}@media(max-width:1023px){._7AYZA{grid-template-columns:repeat(2,minmax(0,1fr))}}@media(max-width:767px){._7AYZA{grid-template-columns:repeat(1,minmax(0,1fr))}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "_2HUgp",
	"sectionName": "_17UzP",
	"title": "_1Tvqx",
	"postList": "_7AYZA"
};
module.exports = exports;
