import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';

import commonVerticalPaddingStyles from '../../commonVerticalPadding.scss';
import styles from './UserStateSection.scss';

const UserStateSection = () => {
  useStyles(commonVerticalPaddingStyles, styles);
  const { t } = useTranslation(['landingPage']);

  return (
    <div className={cx(styles.root, commonVerticalPaddingStyles.container)}>
      <div className={styles.container}>
        <h3 className={styles.title}>{t(`home.numbersBanner.title`)}</h3>
        <div className={styles.state}>
          {[...Array(3).keys()].map(index => (
            <div key={index}>
              <h4 className={styles.stateValue}>
                {t(`home.numbersBanner.highlights.${index}.number`)}
              </h4>
              <div>{t(`home.numbersBanner.highlights.${index}.text`)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserStateSection;
