import React, { useState, useRef, useEffect } from 'react';
import { array, number, func, string, oneOfType } from 'prop-types';
import cx from 'classnames';
import { ChevronDownIcon, CheckIcon } from '@snapask/common-components';

import useBaseOnWindowSize from 'hooks/useBaseOnWindowSize';
import useStyles from 'hooks/useStyles';
import SelectPullUp from '../SelectPullUp';
import styles from './Select.scss';

const PULL_UP_WIDTH_LIMIT = 768;

const checkIsPullUPMode = () => window.innerWidth < PULL_UP_WIDTH_LIMIT;

function Select({
  value: selectedValue,
  options,
  title,
  defaultValue,
  onChange,
  className,
}) {
  useStyles(styles);
  const [isOpen, setIsOpen] = useState(false);
  const [currentLabel, setCurrentLabel] = useState(
    (options?.find(option => option.value === defaultValue) || {}).label,
  );
  const [isPullUpMode, setIsPullUpMode] = useState();
  const rootRef = useRef();
  useBaseOnWindowSize(() => {
    setIsPullUpMode(checkIsPullUPMode());
  });

  const handleOnClick = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (value, option) => {
    onChange(value, option);
    setIsOpen(false);
  };

  useEffect(() => {
    setCurrentLabel(
      (options.find(option => option.value === selectedValue) || {}).label,
    );
  }, [options, selectedValue]);

  useEffect(() => {
    const handleOnBlur = evt => {
      if (rootRef.current && !rootRef.current.contains(evt.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOnBlur);
    return () => {
      document.removeEventListener('click', handleOnBlur);
    };
  }, []);

  useEffect(() => {
    setIsPullUpMode(checkIsPullUPMode());
  }, []);

  return (
    <div className={cx(styles.root, className)} ref={rootRef}>
      <div className={styles.buttonContainer} onClick={handleOnClick}>
        <div className={styles.button}>
          <div className={styles.label}>{currentLabel}</div>
        </div>
        <ChevronDownIcon
          width={24}
          height={24}
          className={cx(styles.chevron, {
            [styles.chevronActive]: isOpen,
          })}
        />
      </div>
      {isPullUpMode ? (
        <SelectPullUp
          options={options}
          value={selectedValue}
          title={title}
          visible={isOpen}
          onClose={() => setIsOpen(false)}
          onChange={handleSelect}
        />
      ) : (
        <div className={cx(styles.options, { [styles.optionVisible]: isOpen })}>
          {options.map(({ label, value }) => (
            <div
              className={cx(styles.option, {
                [styles.optionActive]: value === selectedValue,
              })}
              key={value}
              onClick={() => handleSelect(value, { label, value })}
            >
              {label}
              {value === selectedValue && (
                <CheckIcon className={styles.check} width={24} height={24} />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

Select.propTypes = {
  className: string,
  defaultValue: oneOfType([string, number]),
  options: array.isRequired,
  value: oneOfType([string, number]),
  onChange: func.isRequired,
  title: string.isRequired,
};

Select.defaultProps = {
  className: '',
  defaultValue: 0,
  value: null,
};

export default Select;
