import React from 'react';
import { bool } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';
import useGetOfficialSiteURL from 'hooks/useGetOfficialSiteURL';

import Button from 'components/Button';
import Link from 'components/Link';
import { AppStoreIcon, GooglePlayIcon } from 'components/Icon';

import { push } from 'reduxModules/navigation/action';
import { sendGTMEvent } from 'reduxModules/googleTagManager/action';
import { getAppRegion } from 'reduxModules/app/selector';

import { SIGNUP } from 'constants/routingPath';
import { HOME_REGISTER_CLICK, HOME_DOWNLOAD_CLICK } from 'constants/gtmEvent';
import { ANDROID, IOS } from 'constants/appDownloadType';

import commonVerticalPaddingStyles from '../../commonVerticalPadding.scss';
import styles from './CallToActionSection.scss';

const CallToActionSection = ({ hasContentOffering }) => {
  useStyles(commonVerticalPaddingStyles, styles);
  const { t } = useTranslation(['landingPage']);
  const dispatch = useDispatch();
  const getOfficialSiteURL = useGetOfficialSiteURL();
  const appRegion = useSelector(getAppRegion);

  const handleClick = () => {
    dispatch(push(getOfficialSiteURL(SIGNUP)));
    dispatch(
      sendGTMEvent({
        eventName: HOME_REGISTER_CLICK,
      }),
    );
  };

  const onIosDownloadClick = () => {
    dispatch(
      sendGTMEvent({
        eventName: HOME_DOWNLOAD_CLICK,
        data: {
          region: appRegion,
          type: IOS,
        },
      }),
    );
  };

  const onAndroidDownloadClick = () => {
    dispatch(
      sendGTMEvent({
        eventName: HOME_DOWNLOAD_CLICK,
        data: {
          region: appRegion,
          type: ANDROID,
        },
      }),
    );
  };

  return (
    <div className={cx(styles.root, commonVerticalPaddingStyles.container)}>
      <h3 className={styles.title}>{t('home.banner.title')}</h3>
      {hasContentOffering ? (
        <Button reversed onClick={handleClick}>
          {t('home.banner.cta')}
        </Button>
      ) : (
        <div className={styles.download}>
          <Link
            newTab
            to={t('common:common.ios_download_link')}
            onClick={onIosDownloadClick}
          >
            <AppStoreIcon />
          </Link>
          <Link
            newTab
            to={t('common:common.android_download_link')}
            onClick={onAndroidDownloadClick}
          >
            <GooglePlayIcon />
          </Link>
        </div>
      )}
    </div>
  );
};

CallToActionSection.propTypes = {
  hasContentOffering: bool.isRequired,
};

export default CallToActionSection;
