import React from 'react';
import { string, bool, func } from 'prop-types';
import cx from 'classnames';
import useStyles from 'hooks/useStyles';

import styles from './Thumbnail.scss';

const Thumbnail = ({ avatar, alt, isActive, className, onClick }) => {
  useStyles(styles);

  return (
    <div
      className={cx(styles.root, className, {
        [styles.rootIsActive]: isActive,
      })}
      onClick={onClick}
    >
      <img
        className={cx(styles.img, { [styles.imgIsActive]: isActive })}
        src={avatar}
        alt={alt}
      />
    </div>
  );
};

Thumbnail.propTypes = {
  alt: string.isRequired,
  avatar: string.isRequired,
  className: string,
  isActive: bool.isRequired,
  onClick: func.isRequired,
};

Thumbnail.defaultProps = {
  className: '',
};

export default Thumbnail;
