// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./images/cover.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./images/coverActive.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./images/live.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./images/liveActive.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./images/projection.svg");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./images/projectionActive.svg");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./images/ask.svg");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./images/askActive.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
exports.push([module.id, "._2-yBO{position:relative;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;height:100%;max-width:1240px;max-width:77.5rem;margin:0 auto;padding-top:7.5rem;padding-bottom:2rem}@media(min-width:768px)and (max-width:1023px){._2-yBO{padding-top:5rem}}@media(max-width:767px){._2-yBO{padding-top:3rem;padding-bottom:1rem}}.Mh2eA{display:-ms-flexbox;display:flex;background-color:#fff;border-radius:3.5rem;-webkit-box-shadow:0 8px 16px rgba(64,64,66,.08);box-shadow:0 8px 16px rgba(64,64,66,.08);height:56px;height:3.5rem}._3df-B{cursor:pointer;width:126px;width:7.875rem;border-radius:3.5rem;background-repeat:no-repeat;background-position:50%;background-size:2rem}@media(max-width:767px){._3df-B{width:4.25rem;background-size:1.5rem}}._3TgG7{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}._3TgG7._1xD24{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.fUPaS{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.fUPaS._1xD24{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}._1ujcF{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}._1ujcF._1xD24{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ")}._1E9S6{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ")}._1E9S6._1xD24{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ")}._1xD24{background-color:#e0eeff}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "_2-yBO",
	"controllerList": "Mh2eA",
	"controller": "_3df-B",
	"cover": "_3TgG7",
	"active": "_1xD24",
	"regularClass": "fUPaS",
	"course": "_1ujcF",
	"qa": "_1E9S6"
};
module.exports = exports;
