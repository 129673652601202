// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3-Mfs{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:justify;justify-content:space-between;border-radius:3rem;overflow:hidden;-webkit-mask-image:-webkit-radial-gradient(#fff,#000);mask-image:-webkit-radial-gradient(#fff,#000)}@media(min-width:1024px){._3-Mfs{grid-gap:4.25rem;gap:4.25rem}}@media(max-width:1023px){._3-Mfs{grid-gap:2rem;gap:2rem}}.mHbxl{background-color:#e0eeff}._2gy_A{background-color:#ebebfe}._3irRz{background-color:#d9fcfe}._2s8jP{padding-top:.75rem;font-size:18px;font-weight:400;line-height:29px;font-family:var(--base-font)}._2BhNK{padding:2rem 2rem 0}._1l3J2{max-width:100%;height:200px;height:12.5rem;overflow:hidden}._3eZKN{padding-bottom:.75rem}.mHbxl ._3eZKN{color:#0373f9}._2gy_A ._3eZKN{color:#5656e2}._3irRz ._3eZKN{color:#03d8d8}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "_3-Mfs",
	"regularClass": "mHbxl",
	"course": "_2gy_A",
	"qa": "_3irRz",
	"description": "_2s8jP",
	"titleSection": "_2BhNK",
	"content": "_1l3J2",
	"tag": "_3eZKN"
};
module.exports = exports;
