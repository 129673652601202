import { useEffect } from 'react';

export default function useBaseOnWindowSize(callBack) {
  useEffect(() => {
    callBack();
    window.addEventListener('resize', callBack);
    return () => {
      window.removeEventListener('resize', callBack);
    };
  }, [callBack]);

  return useBaseOnWindowSize;
}
