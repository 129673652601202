import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { HOME_CONTACT_US_CLICK } from 'constants/gtmEvent';
import { sendGTMEvent } from 'reduxModules/googleTagManager/action';
import { openSocialWidget } from 'reduxModules/socialWidget/action';
import useStyles from 'hooks/useStyles';

import { ReactComponent as InfoSvg } from './images/img-info.svg';
import { AskFriendIcon } from 'components/Icon';
import styles from './ContactSection.scss';

const ContactSection = () => {
  useStyles(styles);
  const dispatch = useDispatch();
  const { t } = useTranslation(['landingPage']);

  const onContactClick = () => {
    dispatch(openSocialWidget());
    dispatch(
      sendGTMEvent({
        eventName: HOME_CONTACT_US_CLICK,
      }),
    );
  };

  return (
    <section className={styles.root}>
      <div>
        <h2 className={styles.title}>{t('home.contactUsBanner.title')}</h2>
        <div className={styles.description}>
          {t('home.contactUsBanner.description')}
        </div>
      </div>
      <InfoSvg className={styles.img} />
      <button className={styles.button} type="button" onClick={onContactClick}>
        <span className={styles.buttonText}>
          {t('home.contactUsBanner.cta')}
        </span>
        <AskFriendIcon />
      </button>
    </section>
  );
};

export default ContactSection;
