// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1JsGd{margin-top:2rem;font-size:32px;font-size:2rem;font-weight:500;line-height:40px;line-height:2.5rem;font-family:var(--heading-font);letter-spacing:var(--letter-spacing)}@media(max-width:767px){._1JsGd{font-size:1.625rem;line-height:2.03125rem;text-align:center}}._2RQD5{position:relative;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-bottom:1.5rem;margin-top:1rem}._3R-GJ{-ms-flex:calc(25% - 0.75rem) 0;flex:calc(25% - 0.75rem) 0;margin-right:1rem;margin-bottom:1.5rem}@media(max-width:1023px){._3R-GJ{-ms-flex:calc(50% - 0.5rem) 0;flex:calc(50% - 0.5rem) 0}._3R-GJ:nth-child(4n){margin-right:1rem}._3R-GJ:nth-child(2n){margin-right:0}}@media(max-width:767px){._3R-GJ{-ms-flex:100% 1;flex:100% 1;margin-right:0}}._3R-GJ:nth-child(4n){margin-right:0}", ""]);
// Exports
exports.locals = {
	"title": "_1JsGd",
	"cardList": "_2RQD5",
	"courseCard": "_3R-GJ"
};
module.exports = exports;
