// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Wg43{position:relative;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}._7hj5V svg{-webkit-transform:rotate(-90deg);-ms-transform:rotate(-90deg);transform:rotate(-90deg)}._7hj5V circle{fill:none;stroke-linecap:round}.NnPL8{position:absolute;text-align:center;color:#231815;z-index:1;font-size:14px;font-weight:400;line-height:23px;font-family:var(--base-font)}@media(max-width:767px){.NnPL8{font-size:16px;line-height:26px}}._14xuZ{stroke:#dcdddd}._18lQm{stroke:#0373f9}.iGZ-2{stroke:#00d8ae}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "_1Wg43",
	"graphics": "_7hj5V",
	"percentage": "NnPL8",
	"bar": "_14xuZ",
	"progress": "_18lQm",
	"completed": "iGZ-2"
};
module.exports = exports;
