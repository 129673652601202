// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".-Gx-M{overflow-x:hidden;position:relative;width:100%}.-Gx-M,._2FdI-{display:-ms-flexbox;display:flex}._2FdI-{-webkit-animation:_2HnYK var(--duration) linear infinite;animation:_2HnYK var(--duration) linear infinite;-webkit-animation-play-state:running;animation-play-state:running}.-Gx-M:hover>._2FdI-{-webkit-animation-play-state:var(--pause-on-hover);animation-play-state:var(--pause-on-hover)}@-webkit-keyframes _2HnYK{0%{-webkit-transform:translateX(0);transform:translateX(0)}to{-webkit-transform:translateX(-100%);transform:translateX(-100%)}}@keyframes _2HnYK{0%{-webkit-transform:translateX(0);transform:translateX(0)}to{-webkit-transform:translateX(-100%);transform:translateX(-100%)}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"container": "-Gx-M",
	"marquee": "_2FdI-",
	"scroll": "_2HnYK"
};
module.exports = exports;
