import React, { Fragment } from 'react';
import { func, string } from 'prop-types';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';
import TriangleWithRound from 'components/TriangleWithRound';

import styles from './CoverPhoneLayer.scss';

const CoverPhoneLayer = ({ className, onClick }) => {
  useStyles(styles);

  return (
    <Fragment>
      <div className={cx(styles.phoneGrayMask, className)} onClick={onClick} />
      <div className={cx(styles.player, className)} onClick={onClick}>
        <TriangleWithRound
          width={33}
          height={38}
          strokeWidth={6}
          color="#0373F9"
        />
      </div>
    </Fragment>
  );
};

CoverPhoneLayer.propTypes = {
  className: string.isRequired,
  onClick: func.isRequired,
};

export default CoverPhoneLayer;
