// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3S59Q{overflow:hidden}._2b4-c{margin:0 auto;width:100%}._2TCLO{padding-bottom:5rem}", ""]);
// Exports
exports.locals = {
	"container": "_3S59Q",
	"section": "_2b4-c",
	"mainOfferingContainer": "_2TCLO"
};
module.exports = exports;
