import React from 'react';
import { number, string } from 'prop-types';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';

import styles from './SquareWithRound.scss';

const SquareWithRound = ({ className, size, radius, color }) => {
  useStyles(styles);
  return (
    <svg
      className={cx(styles.root, className)}
      style={{
        '--size': `${size}px`,
      }}
    >
      <rect
        style={{
          '--radius': `${radius}px`,
          '--fill': color,
        }}
        className={styles.shape}
      />
    </svg>
  );
};

SquareWithRound.propTypes = {
  size: number.isRequired,
  radius: number.isRequired,
  color: string.isRequired,
  className: string,
};

SquareWithRound.defaultProps = {
  className: '',
};

export default SquareWithRound;
