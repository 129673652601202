import React, { useState, useRef } from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import LazyLoad from 'react-lazyload';

import useStyles from 'hooks/useStyles';
import useBaseOnWindowSize from 'hooks/useBaseOnWindowSize';
import useGetOfficialSiteURL from 'hooks/useGetOfficialSiteURL';

import Button from 'components/Button';
import LoadingSkeleton from 'components/LoadingSkeleton';
import LoadedImage from 'components/LoadedImage';

import { INSTRUCTOR_TEMPLATE } from 'constants/routingPath';
import { REGULAR_CLASS_INSTRUCTOR_CLICK } from 'constants/gtmEvent';

import { push } from 'reduxModules/navigation/action';
import { sendGTMEvent } from 'reduxModules/googleTagManager/action';

import styles from './InstructorIntroCard.scss';

function InstructorIntroCard({
  id,
  name,
  avatarUrl,
  subjectString,
  hashtagString,
  askableTutorId,
  about = [],
}) {
  useStyles(styles);
  const [transitionHeight, setTransitionHeight] = useState(0);
  const [outerLayerHeight, setOuterLayerHeight] = useState(0);
  const [titleSectionHeight, setTitleSectionHeight] = useState(0);

  const outerLayerRef = useRef();
  const innerLayerRef = useRef();
  const titleSectionRef = useRef();

  const calculateHeight = () => {
    setTransitionHeight(
      innerLayerRef.current?.offsetHeight - outerLayerRef.current?.offsetHeight,
    );
    setTitleSectionHeight(titleSectionRef.current?.offsetHeight);
    setOuterLayerHeight(outerLayerRef.current?.offsetHeight);
  };

  useBaseOnWindowSize(calculateHeight);

  const { t } = useTranslation(['landingPage']);
  const dispatch = useDispatch();
  const getOfficialSiteURL = useGetOfficialSiteURL();

  const handleLinkClick = () => {
    dispatch(push(getOfficialSiteURL(INSTRUCTOR_TEMPLATE, { id })));
    dispatch(
      sendGTMEvent({
        eventName: REGULAR_CLASS_INSTRUCTOR_CLICK,
        data: {
          instructorId: id,
          instructorName: name,
          instructorHashtag: hashtagString,
          subjects: subjectString,
          tutorService: askableTutorId !== null,
          section: 'home',
        },
      }),
    );
  };

  return (
    <div ref={outerLayerRef} className={styles.outerLayer}>
      <div className={styles.middleLayer}>
        <div
          ref={innerLayerRef}
          style={{
            '--transitionHeight': `-${transitionHeight}px`,
            '--outerLayerHeight': `${outerLayerHeight}px`,
            '--titleSectionHeight': `${titleSectionHeight}px`,
          }}
          className={styles.innerLayer}
          data-ooo-image-container
        >
          <LazyLoad
            height={220}
            offset={[-220, 0]}
            placeholder={<LoadingSkeleton className={styles.loading} />}
          >
            <div className={styles.pictureSection}>
              <LoadedImage
                className={styles.image}
                src={avatarUrl}
                alt={name}
              />
            </div>
          </LazyLoad>
          <div ref={titleSectionRef} className={styles.titleSection}>
            <h3 className={styles.title}>{name}</h3>
            <h6 className={styles.subTitle}>{subjectString}&#8203;</h6>
          </div>
          <p className={styles.description}>{about[0]?.description}</p>
          <div className={styles.buttonWrapper}>
            <Button className={styles.button} onClick={handleLinkClick}>
              {t('home.regularClassSection.instructor.instructorCardCta')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

InstructorIntroCard.propTypes = {
  id: number.isRequired,
  name: string.isRequired,
  avatarUrl: string,
  subjectString: string.isRequired,
  hashtagString: string.isRequired,
  askableTutorId: number,
  about: arrayOf(
    shape({
      description: string.isRequired,
    }).isRequired,
  ).isRequired,
};

InstructorIntroCard.defaultProps = {
  askableTutorId: null,
  avatarUrl: '',
};

export default InstructorIntroCard;
