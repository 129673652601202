import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from 'hooks/useStyles';
import { REGULAR_CLASS, COURSE } from '../../constants';
import IntroCard from '../IntroCard';
import AllSubjectsContent from './components/AllSubjectsContent';
import ChattingAnimation from './components/ChattingAnimation';
import QuizQuestionsContent from './components/QuizQuestionsContent';
import BitedCookieContent from './components/BitedCookieContent';
import styles from './LearningSection.scss';

const LearningSection = () => {
  useStyles(styles);
  const { t } = useTranslation(['landingPage']);

  return (
    <section className={styles.root}>
      <div className={styles.info}>
        <h2 className={styles.title}>
          {t('home.regularClassAndCourseSection.title')}
        </h2>
        <div className={styles.description}>
          {t('home.regularClassAndCourseSection.description')}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.rcRow}>
          <IntroCard
            classNameMap={{ root: styles.introCard }}
            name={REGULAR_CLASS}
            tag={t('home.regularClassSection.featuredClass.title')}
            title={t('home.regularClassSection.highlights.0.title')}
            description={t('home.regularClassSection.highlights.0.description')}
            content={
              <AllSubjectsContent name={REGULAR_CLASS} showEnterAnimation />
            }
          />
          <IntroCard
            classNameMap={{
              root: styles.introCard,
              content: styles.chattingAnimation,
            }}
            name={REGULAR_CLASS}
            tag={t('home.regularClassSection.featuredClass.title')}
            title={t('home.regularClassSection.highlights.1.title')}
            description={t('home.regularClassSection.highlights.1.description')}
            content={<ChattingAnimation />}
          />
        </div>
        <div className={styles.icRow}>
          <IntroCard
            classNameMap={{ root: styles.introCard }}
            name={COURSE}
            tag={t('home.courseSection.featuredCourse.title')}
            title={t('home.courseSection.highlights.0.title')}
            description={t('home.courseSection.highlights.0.description')}
            content={<BitedCookieContent />}
          />
          <IntroCard
            classNameMap={{ root: styles.introCard }}
            name={COURSE}
            tag={t('home.courseSection.featuredCourse.title')}
            title={t('home.courseSection.highlights.1.title')}
            description={t('home.courseSection.highlights.1.description')}
            content={<QuizQuestionsContent showEnterAnimation />}
          />
        </div>
      </div>
    </section>
  );
};

export default LearningSection;
