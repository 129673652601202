import React from 'react';
import {
  arrayOf,
  string,
  func,
  shape,
  oneOfType,
  bool,
  object,
  number,
  oneOf,
} from 'prop-types';
import cx from 'classnames';

// V6 Docs: https://swiper6.vercel.app/swiper-api
// Ref: https://swiperjs.com/swiper-api
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import swiperStyles from 'swiper/swiper.scss';

import useStyles from 'hooks/useStyles';
import { ChevronIcon, ChevronWhiteIcon } from 'components/Icon';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './BaseSwiper.scss';
import 'swiper/components/pagination/pagination.scss';

// install Swiper modules
SwiperCore.use([Pagination, Autoplay, Navigation]);

const BaseSwiper = ({
  id,
  swiperRef,
  onSwiper,
  classNameMap,
  slideDataList,
  renderSlide,
  spaceBetween,
  navigation,
  navigationType,
  pagination,
  ...props
}) => {
  useStyles(swiperStyles, styles);
  const navigationNameList = ['prev', 'next'];
  const navigationCSSSelectorMap = navigationNameList.reduce(
    (origin, name) => ({
      ...origin,
      [`${name}El`]: `#${id} .swiper-button-${name}`,
    }),
    {},
  );
  const navigationProps = navigation && {
    ...navigation,
    ...navigationCSSSelectorMap,
  };

  const paginationCSSSelectorMap = {
    el: `#${id} .swiper-pagination`,
  };
  const paginationProps = pagination && {
    clickable: true,
    ...pagination,
    ...paginationCSSSelectorMap,
  };

  const getControllerClassName = CSSSelector =>
    CSSSelector.split(' ')[1].substr(1);

  const renderNavigation = () =>
    navigation &&
    Object.values(navigationCSSSelectorMap).map(
      (navigationCSSSelector, index) => {
        const navigationName = navigationNameList[index];
        return (
          <div
            key={navigationCSSSelector}
            className={cx(
              styles.navigation,
              styles[navigationName],
              classNameMap.navigation,
              classNameMap[navigationName],
            )}
          >
            <button
              type="button"
              className={cx(
                getControllerClassName(navigationCSSSelector),
                classNameMap.navigationButton,
                classNameMap[`${navigationName}Button`],
              )}
            >
              {navigationType === 'normal' ? (
                <ChevronWhiteIcon />
              ) : (
                <ChevronIcon />
              )}
            </button>
          </div>
        );
      },
    );

  return (
    <div id={id} className={cx(styles.root, classNameMap.root)}>
      <div className={cx(styles.swiperWrapper, classNameMap.swiperWrapper)}>
        <Swiper
          ref={swiperRef}
          onSwiper={onSwiper}
          spaceBetween={spaceBetween}
          navigation={navigationProps}
          pagination={paginationProps}
          // use ResizeObserver (if supported by browser) instead  of window resize event
          resizeObserver
          {...props}
        >
          {slideDataList.map((slide, index) => (
            <SwiperSlide key={slide.id}>
              {renderSlide(slide, index)}
            </SwiperSlide>
          ))}
        </Swiper>
        {renderNavigation()}
      </div>
      {pagination && (
        <div
          className={cx(
            getControllerClassName(paginationCSSSelectorMap.el),
            classNameMap.pagination,
          )}
        />
      )}
    </div>
  );
};

BaseSwiper.propTypes = {
  id: string.isRequired,
  slideDataList: arrayOf(
    shape({
      id: oneOfType([number, string]).isRequired,
    }),
  ).isRequired,
  renderSlide: func.isRequired,
  onSwiper: func,
  classNameMap: shape({
    root: string,
    swiperWrapper: string,
    navigation: string,
    prev: string,
    next: string,
    navigationButton: string,
    prevButton: string,
    nextButton: string,
    pagination: string,
  }),
  spaceBetween: number,
  navigation: oneOfType([object, bool]),
  navigationType: oneOf(['normal', 'reversed']),
  pagination: oneOfType([
    shape({
      clickable: bool,
    }),
    bool,
  ]),
  swiperRef: object,
};

BaseSwiper.defaultProps = {
  classNameMap: {},
  spaceBetween: 16,
  navigation: false,
  navigationType: 'reversed',
  pagination: false,
  swiperRef: {},
  onSwiper: () => {},
};

export default BaseSwiper;
