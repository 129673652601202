// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1OYk-{display:inline-block;background-color:#dcdddd;background-image:-webkit-gradient(linear,left top,right top,from(#dcdddd),color-stop(hsla(0,0%,100%,.4)),to(#dcdddd));background-image:-o-linear-gradient(left,#dcdddd,hsla(0,0%,100%,.4),#dcdddd);background-image:linear-gradient(90deg,#dcdddd,hsla(0,0%,100%,.4),#dcdddd);background-size:150px 100%;background-repeat:no-repeat;line-height:1;-webkit-animation:_1CZ1T 1.2s ease-in-out infinite;animation:_1CZ1T 1.2s ease-in-out infinite;overflow:hidden;vertical-align:bottom}@-webkit-keyframes _1CZ1T{0%{background-position:-150px 0}to{background-position:calc(150px + 100%) 0}}@keyframes _1CZ1T{0%{background-position:-150px 0}to{background-position:calc(150px + 100%) 0}}", ""]);
// Exports
exports.locals = {
	"root": "_1OYk-",
	"loading": "_1CZ1T"
};
module.exports = exports;
