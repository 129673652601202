import React from 'react';
import { string, shape } from 'prop-types';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';

import styles from './DialogAnimation.scss';

const DialogAnimation = ({ classNameMap, keyframesTime }) => {
  useStyles(styles);

  return (
    <ul className={cx(styles.root, classNameMap.root)}>
      {[...Array(3).keys()].map((item, index) => (
        <li
          // eslint-disable-next-line react/no-array-index-key
          key={`dialog-animation-point-${index}`}
          style={{ '--keyframes-time': keyframesTime }}
          className={cx(styles.point, classNameMap.point)}
        />
      ))}
    </ul>
  );
};

DialogAnimation.propTypes = {
  classNameMap: shape({ root: string, point: string }),
  keyframesTime: string,
};

DialogAnimation.defaultProps = {
  classNameMap: {},
  keyframesTime: '0.4s',
};

export default DialogAnimation;
