import { useState, useRef, useEffect } from 'react';

export default function useElementVisibility(defaultVisible = false, settings) {
  const [visible, setVisible] = useState(defaultVisible);
  const ref = useRef();
  useEffect(() => {
    if (!ref.current) return undefined;

    const observer = new IntersectionObserver(
      entries => setVisible(entries[0].isIntersecting),
      { threshold: [0], ...settings },
    );
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref, settings]);

  return [ref, visible];
}
