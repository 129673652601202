import React from 'react';
import { bool, number, string, object } from 'prop-types';

import { useDispatch } from 'react-redux';

import BaseContentIntroCard from 'components/BaseContentIntroCard';
import useGetOfficialSiteURL from 'hooks/useGetOfficialSiteURL';

import { sendGTMEvent } from 'reduxModules/googleTagManager/action';

import { COURSE_INFORMATION_TEMPLATE } from 'constants/routingPath';

function CourseIntroCard({
  hasFreeLesson,
  id,
  title,
  displayCurrency,
  displayPrice,
  tag,
  lessonsAmount,
  totalDurationInSeconds,
  isFree,
  isPurchased,
  eventName,
  eventData,
  ...prop
}) {
  const dispatch = useDispatch();

  const getOfficialSiteURL = useGetOfficialSiteURL();

  const handleClick = () => {
    dispatch(
      sendGTMEvent({
        eventName,
        data: {
          courseId: id,
          courseName: title,
          totalLessonDuration: totalDurationInSeconds,
          totalLesson: lessonsAmount,
          tag,
          purchaseStatus: isPurchased,
          price: displayPrice?.sellingPrice,
          priceCurrency: displayCurrency,
          ...eventData,
        },
      }),
    );
  };

  return (
    <BaseContentIntroCard
      {...prop}
      pageUrl={getOfficialSiteURL(COURSE_INFORMATION_TEMPLATE, {
        id,
        freeLesson: hasFreeLesson || undefined,
      })}
      onClick={handleClick}
      title={title}
      price={displayPrice}
      isFree={isFree}
      lessonsAmount={lessonsAmount}
      showBack
      showPrice
    />
  );
}

CourseIntroCard.propTypes = {
  hasFreeLesson: bool,
  id: number.isRequired,
  title: string.isRequired,
  displayCurrency: string,
  displayPrice: object,
  tag: string.isRequired,
  lessonsAmount: number.isRequired,
  totalDurationInSeconds: number.isRequired,
  eventName: string.isRequired,
  eventData: object.isRequired,
  isFree: bool.isRequired,
  isPurchased: bool.isRequired,
  pictureUrl: string,
};

CourseIntroCard.defaultProps = {
  hasFreeLesson: false,
  displayPrice: undefined,
  displayCurrency: undefined,
  pictureUrl: '',
};

export default CourseIntroCard;
