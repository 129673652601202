// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./images/chunk.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./images/subtract.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "._3kS3a{padding-bottom:2rem}._1nKEI{grid-gap:24px;gap:24px;grid-gap:1.5rem;gap:1.5rem;padding-right:1.5rem}@media(min-width:768px)and (max-width:1023px){._1nKEI{grid-gap:2.5rem;gap:2.5rem;padding-right:1.5rem}}._1rUK6{width:176px;width:11rem;height:176px;height:11rem;background-position:50%;background-size:cover}@media(min-width:768px)and (max-width:1023px){._1rUK6{width:17.875rem;height:17.875rem}}._1KUgC{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}._36PmB{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"subSectionTitle": "_3kS3a",
	"biteSizeVideosMarquee": "_1nKEI",
	"biteSizeVideosShape": "_1rUK6",
	"chunk": "_1KUgC",
	"subtract": "_36PmB"
};
module.exports = exports;
