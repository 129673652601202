import React, { useEffect, useState, useRef } from 'react';
import { func, string, bool, oneOf } from 'prop-types';
import cx from 'classnames';
import useStyles from 'hooks/useStyles';
import useBaseOnWindowSize from 'hooks/useBaseOnWindowSize';
import { EMPTY_STRING } from 'constants/i18n';
import styles from './CaseCard.scss';

const DEVICE_IMAGE_URL =
  'https://snapask-dragonfruit.s3.ap-southeast-1.amazonaws.com/regular_class/testimonial-device.png';

const checkIsTextClamped = dom => dom.scrollHeight > dom.clientHeight;

const checkIsLineWrap = dom => dom.clientHeight > 26;

const getCardExtendedHeight = dom => dom.clientHeight + 166;

const getCardNotExtendedHeight = dom => dom.clientHeight + 165;

const CaseCard = ({
  quote,
  avatar,
  highlight,
  purchased,
  screenshot,
  schoolName,
  studentName,
  learningOutcome,
  exceedMode,
  isActive,
  setCardHeight,
}) => {
  useStyles(styles);
  const [isContentExceeded, setIsContentExceeded] = useState();
  const [isUserWrap, setIsUserWrap] = useState();
  const [isContentExtended, setIsContentExtended] = useState();
  const contentRef = useRef();
  const infoRef = useRef();
  const userRef = useRef();

  const changeCardHeight = () => {
    if (typeof setCardHeight === 'function') {
      const getActualHeight = isContentExtended
        ? getCardExtendedHeight
        : getCardNotExtendedHeight;
      setCardHeight(getActualHeight(infoRef.current));
    }
  };

  useBaseOnWindowSize(changeCardHeight);

  useEffect(() => {
    setIsContentExceeded(
      checkIsTextClamped(contentRef.current) && exceedMode === 'click',
    );

    changeCardHeight();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContentExtended, isActive]);

  useEffect(() => {
    if (!isActive) {
      setIsContentExtended(false);
    }
  }, [isActive]);

  useEffect(() => {
    setIsUserWrap(checkIsLineWrap(userRef.current));
  }, []);

  return (
    <div className={cx(styles.root, { [styles.isActive]: isActive })}>
      <div className={styles.screenshotContainer}>
        <img
          alt="screen shot device"
          className={styles.device}
          src={DEVICE_IMAGE_URL}
        />
        <img alt="quote" src={screenshot} className={styles.screenshot} />
      </div>
      <div
        ref={infoRef}
        className={cx(styles.infoContainer, {
          [styles.infoContainerScrollHeight]: exceedMode === 'scroll',
          [styles.infoContainerClickHeight]: exceedMode === 'click',
        })}
      >
        <div>
          <h3 className={styles.quote}>{quote}</h3>
          {learningOutcome !== EMPTY_STRING && (
            <div className={styles.outcome}>{learningOutcome}</div>
          )}
          <div
            className={cx(styles.content, {
              [styles.contentScrollHeight]: exceedMode === 'scroll',
            })}
          >
            <div
              ref={contentRef}
              className={cx({
                [styles.contentClamp]:
                  exceedMode === 'click' && !isContentExtended,
              })}
            >
              {highlight}
            </div>
            {isContentExceeded && (
              <button
                className={styles.moreButton}
                type="button"
                onClick={() => setIsContentExtended(true)}
              >
                更多
              </button>
            )}
          </div>
        </div>
        <div className={styles.userBlock}>
          <img className={styles.avatar} src={avatar} alt={studentName} />
          <div>
            <div className={styles.class}>{purchased}</div>
            <div className={styles.student} ref={userRef}>
              <span>{studentName}</span>
              {!isUserWrap && <span className={styles.dot} />}
              <span>{schoolName}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CaseCard.propTypes = {
  avatar: string.isRequired,
  screenshot: string.isRequired,
  quote: string.isRequired,
  highlight: string.isRequired,
  purchased: string.isRequired,
  studentName: string.isRequired,
  schoolName: string.isRequired,
  exceedMode: oneOf(['scroll', 'click']).isRequired,
  isActive: bool.isRequired,
  learningOutcome: string,
  setCardHeight: func,
};

CaseCard.defaultProps = {
  learningOutcome: '',
  setCardHeight: null,
};

export default CaseCard;
