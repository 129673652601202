// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wcpf8,._18Ox5{position:absolute;top:-8px;top:-.5rem;right:-8px;right:-.5rem;width:24px;width:1.5rem;height:24px;height:1.5rem;border-radius:50%;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;cursor:pointer}._1Gons{display:inline-block;background:#fff;-webkit-box-shadow:0 4px 32px rgba(48,140,253,.1);box-shadow:0 4px 32px rgba(48,140,253,.1);border-radius:1.5rem;padding:1rem 2rem;cursor:pointer;position:relative}._3vot3{font-size:16px;font-size:1rem;font-weight:500;line-height:20px;line-height:1.25rem;font-family:var(--heading-font);letter-spacing:var(--letter-spacing)}._12jC5{width:16px;width:1rem;height:16px;height:1rem}.wcpf8{background-color:#0373f9}._18Ox5{background-color:#f45858}", ""]);
// Exports
exports.locals = {
	"check": "wcpf8",
	"close": "_18Ox5",
	"root": "_1Gons",
	"text": "_3vot3",
	"icon": "_12jC5"
};
module.exports = exports;
