import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';

import * as upsellBannerPage from 'constants/upsellBannerPage';
import { HOME_SCROLL_DEPTH } from 'constants/gtmEvent';
import useStyles from 'hooks/useStyles';
import useUpsellBanner from 'hooks/useUpsellBanner';
import Testimonial from 'components/Testimonial';
import SnapaskHeader from 'components/SnapaskHeader';
import SnapaskFooter from 'components/SnapaskFooter';
import SnapaskLocaleSelection from 'components/SnapaskLocaleSelection';
import AlertBanner from 'components/AlertBanner';
import ApplicationContext from 'components/ApplicationContext';
import TrackVisibility from 'components/TrackVisibility';
import UpsellBanner from 'components/UpsellBanner';

import { getOfferingList } from 'reduxModules/siteInfo/selector';
import { sendGTMEvent } from 'reduxModules/googleTagManager/action';
import { convertSnakeToCamelCase } from 'utils/common';
import { fetchUpsellBanner } from 'reduxModules/upsellBanner/action';

import BannerSection from './components/BannerSection';
import MainOfferingSection from './components/MainOfferingSection';
import QASection from './components/QASection';
import AcademySection from './components/AcademySection';
import UserStateSection from './components/UserStateSection';
import CallToActionSection from './components/CallToActionSection';
import IcRcSection from './components/IcRcSection';
import ContactSection from './components/ContactSection';
import LearningSection from './components/LearningSection';

import {
  REGULAR_CLASS,
  COURSE,
  QA,
  ACADEMY,
  BANNER,
  TESTIMONIAL,
  ANCHOR_NAME_MAPPING,
} from './constants';
import useLoading from './useLoading';

import commonGapStyles from './commonGap.scss';
import styles from './HomeRevamp.scss';

function HomeRevamp() {
  const {
    query: { section },
  } = useContext(ApplicationContext);
  useStyles(commonGapStyles, styles);
  const dispatch = useDispatch();
  const [scrollToSection, setScrollToSection] = useState();
  const loading = useLoading();
  const regularClassSectionRef = useRef(null);
  const courseSectionRef = useRef(null);
  const QASectionRef = useRef(null);
  const academySectionRef = useRef(null);
  const bannerSectionRef = useRef(null);
  const testimonialSectionRef = useRef(null);
  const { upsellBannerVisible, hideUpsellBanner } = useUpsellBanner();

  const offeringList = useSelector(getOfferingList);
  const offeringMap = useMemo(
    () =>
      offeringList.reduce(
        (origin, offering) => ({
          ...origin,
          [convertSnakeToCamelCase(offering)]: true,
        }),
        {},
      ),
    [offeringList],
  );
  const hasContentOffering = Boolean(
    offeringMap.course || offeringMap.regularClass,
  );

  useEffect(() => {
    if (
      !loading &&
      section &&
      (section === BANNER || section === TESTIMONIAL || offeringMap[section])
    ) {
      setScrollToSection(section);
    }
  }, [loading, section, offeringMap]);

  useEffect(() => {
    const sectionElementTop = {
      [BANNER]: bannerSectionRef.current?.offsetTop,
      [REGULAR_CLASS]: regularClassSectionRef.current?.offsetTop,
      [COURSE]: courseSectionRef.current?.offsetTop,
      [QA]: QASectionRef.current?.offsetTop,
      [ACADEMY]: academySectionRef.current?.offsetTop,
      [TESTIMONIAL]: testimonialSectionRef.current?.offsetTop,
    };
    const headerHeight = 72;

    window.scrollTo({
      top: sectionElementTop[scrollToSection] - headerHeight,
      behavior: 'smooth',
    });
    dispatch(
      sendGTMEvent({
        eventName: `HOME_${ANCHOR_NAME_MAPPING[scrollToSection]}_ANCHOR`,
      }),
    );
  }, [scrollToSection, dispatch]);

  useEffect(() => {
    dispatch(fetchUpsellBanner({ page: upsellBannerPage.HOME }));
  }, []);

  return (
    <Fragment>
      <SnapaskHeader />
      {upsellBannerVisible && (
        <UpsellBanner page={upsellBannerPage.HOME} onClose={hideUpsellBanner} />
      )}
      <AlertBanner />
      <SnapaskLocaleSelection />
      <div
        style={{
          '--wide-desktop-up-gap': '5rem',
        }}
        className={cx(commonGapStyles.container, styles.container)}
      >
        <TrackVisibility id="banner" eventName={HOME_SCROLL_DEPTH}>
          <BannerSection ref={bannerSectionRef} className={styles.section} />
        </TrackVisibility>
        <IcRcSection
          rcRef={regularClassSectionRef}
          icRef={courseSectionRef}
          shouldShowRc={offeringMap[REGULAR_CLASS]}
          shouldShowIc={offeringMap[COURSE]}
        />
        <>
          <TrackVisibility id="contact_us" eventName={HOME_SCROLL_DEPTH}>
            <ContactSection />
          </TrackVisibility>
          <TrackVisibility id="testimonial" eventName={HOME_SCROLL_DEPTH}>
            <Testimonial
              id="home-testimonial"
              type="home"
              ref={testimonialSectionRef}
            />
          </TrackVisibility>
          <TrackVisibility id="features" eventName={HOME_SCROLL_DEPTH}>
            <LearningSection />
          </TrackVisibility>
        </>
        {offeringMap[QA] && (
          <MainOfferingSection ref={QASectionRef} name={QA}>
            <QASection />
          </MainOfferingSection>
        )}
        {offeringMap[ACADEMY] && (
          <TrackVisibility id="academy" eventName={HOME_SCROLL_DEPTH}>
            <MainOfferingSection
              ref={academySectionRef}
              name={ACADEMY}
              classNameMap={{ container: styles.mainOfferingContainer }}
            >
              <AcademySection />
            </MainOfferingSection>
          </TrackVisibility>
        )}
        <div>
          <UserStateSection />
          <CallToActionSection hasContentOffering={hasContentOffering} />
        </div>
      </div>
      <SnapaskFooter />
    </Fragment>
  );
}

export default HomeRevamp;
