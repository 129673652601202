import React, { useContext, forwardRef } from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import ApplicationContext from 'components/ApplicationContext';

import { EMPTY_STRING } from 'constants/i18n';
import useStyles from 'hooks/useStyles';

import TestimonialCommon from './components/TestimonialCommon';
import TestimonialMobile from './components/TestimonialMobile';

import styles from './Testimonial.scss';

const cx = classNames.bind(styles);

const COURSE = 'course';
const RC = 'regularClass';
const HOME = 'home';
const LANDING_PAGE = 'landingPage';

const Testimonial = forwardRef(({ id, type, className }, ref) => {
  useStyles(styles);
  const { t } = useTranslation(
    type === HOME ? [COURSE, RC, LANDING_PAGE] : [type],
  );
  const {
    screenWidth: { isMobile, isDesktop, isTablet },
  } = useContext(ApplicationContext);
  const content =
    type === HOME
      ? [
          ...t(`${RC}:testimonial.contents`, { returnObjects: true }),
          ...t(`${COURSE}:testimonial.contents`, { returnObjects: true }),
        ]
      : t(`${type}:testimonial.contents`, { returnObjects: true });

  const contentList =
    typeof content === 'string'
      ? []
      : content.filter(({ quote }) => quote !== EMPTY_STRING);

  const title =
    type === HOME
      ? t(`${LANDING_PAGE}:home.testimonial.title`)
      : t(`${type}:testimonial.title`);

  const description =
    type === HOME
      ? t(`${LANDING_PAGE}:home.testimonial.description`)
      : t(`${type}:testimonial.description`);

  let TestimonialComponent;
  if (isMobile) {
    TestimonialComponent = TestimonialMobile;
  } else {
    TestimonialComponent = TestimonialCommon;
  }

  if (contentList.length === 0) return null;

  return (
    <section className={cx(styles.root, className)} id={id} ref={ref}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.description}>{description}</div>
      <TestimonialComponent
        data={contentList}
        t={t}
        isTablet={isTablet}
        isDesktop={isDesktop}
      />
    </section>
  );
});

Testimonial.propTypes = {
  id: string,
  type: oneOf([COURSE, RC, HOME]).isRequired,
  className: string,
};

Testimonial.defaultProps = {
  id: '',
  className: '',
};

export default Testimonial;
