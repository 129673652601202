// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1gaU9{max-width:1440px;max-width:90rem;width:100%}._1gaU9,._1gaU9>div{margin:0 auto}", ""]);
// Exports
exports.locals = {
	"section": "_1gaU9"
};
module.exports = exports;
