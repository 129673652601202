import React from 'react';
import { BaseHeader } from '@snapask/common-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';

import { sendGTMEvent } from 'reduxModules/googleTagManager/action';

import useGetOfficialSiteURL from 'hooks/useGetOfficialSiteURL';
import useGetAcademyURL from 'hooks/useGetAcademyURL';

import { SNAPASK_QA_SITE_ENDPOINT } from 'config';
import {
  NAV_RC_ALL_INSTRUCTOR_CLICK,
  NAV_COURSE_ALL_INSTRUCTOR_CLICK,
  HOME_DOWNLOAD_CLICK,
} from 'constants/gtmEvent';
import {
  COURSE_INSTRUCTOR_LIST,
  REGULAR_CLASS_INSTRUCTOR_LIST,
} from 'constants/navigation';

import Link from 'components/Link';

import {
  getUserIsAuthenticated,
  getUserAvatar,
  getUsername,
  getUserRole,
  getUserRoleStatus,
} from 'reduxModules/user/selector';
import {
  getZendeskFAQLink,
  getOfferingList,
  getMultipleOfferingMode,
  getCampaignLinks,
  getShowLiveInstructorBtn,
  getShowCourseInstructorBtn,
} from 'reduxModules/siteInfo/selector';
import { userLogout } from 'reduxModules/auth/action';
import { getAppRegion } from 'reduxModules/app/selector';
import { ANDROID, IOS } from 'constants/appDownloadType';

function SnapaskHeader({ id }) {
  const { t } = useTranslation(['navigation', 'navbar']);
  const dispatch = useDispatch();
  const appRegion = useSelector(getAppRegion);
  const isAuthenticated = useSelector(getUserIsAuthenticated);
  const avatarUrl = useSelector(getUserAvatar);
  const username = useSelector(getUsername);
  const role = useSelector(getUserRole);
  const roleStatus = useSelector(getUserRoleStatus);
  const zendeskFAQLink = useSelector(getZendeskFAQLink);
  const offeringList = useSelector(getOfferingList);
  const multipleOfferingMode = useSelector(getMultipleOfferingMode);
  const campaignLinks = useSelector(getCampaignLinks);
  const showLiveInstructorBtn = useSelector(getShowLiveInstructorBtn);
  const showCourseInstructorBtn = useSelector(getShowCourseInstructorBtn);

  const getOfficialSiteURL = useGetOfficialSiteURL();
  const getAcademyURL = useGetAcademyURL();

  const handleClickLogout = () => dispatch(userLogout());

  const userInfo = {
    avatarUrl,
    username,
    role,
    roleStatus,
  };
  const onClickNavItem = key => {
    const eventNameMapping = {
      [REGULAR_CLASS_INSTRUCTOR_LIST]: NAV_RC_ALL_INSTRUCTOR_CLICK,
      [COURSE_INSTRUCTOR_LIST]: NAV_COURSE_ALL_INSTRUCTOR_CLICK,
    };

    dispatch(
      sendGTMEvent({
        eventName: eventNameMapping[key],
      }),
    );
  };

  const onIosDownloadClick = () => {
    dispatch(
      sendGTMEvent({
        eventName: HOME_DOWNLOAD_CLICK,
        data: {
          region: appRegion,
          type: IOS,
        },
      }),
    );
  };

  const onAndroidDownloadClick = () => {
    dispatch(
      sendGTMEvent({
        eventName: HOME_DOWNLOAD_CLICK,
        data: {
          region: appRegion,
          type: ANDROID,
        },
      }),
    );
  };

  return (
    <BaseHeader
      id={id}
      getAcademyURL={getAcademyURL}
      getOfficialSiteURL={getOfficialSiteURL}
      getQuestionAskURL={() => SNAPASK_QA_SITE_ENDPOINT}
      LinkComponent={Link}
      isAuthenticated={isAuthenticated}
      onClickLogout={handleClickLogout}
      userInfo={userInfo}
      zendeskFAQUrl={zendeskFAQLink}
      offerings={offeringList}
      multipleOfferingMode={multipleOfferingMode}
      campaignLinks={campaignLinks}
      showLiveInstructorLink={showLiveInstructorBtn}
      showCourseInstructorLink={showCourseInstructorBtn}
      t={t}
      onClick={onClickNavItem}
      onIosDownloadClick={onIosDownloadClick}
      onAndroidDownloadClick={onAndroidDownloadClick}
    />
  );
}

SnapaskHeader.propTypes = {
  id: string,
};

SnapaskHeader.defaultProps = {
  id: undefined,
};

export default SnapaskHeader;
