import React from 'react';
import { arrayOf, shape, func, string, number, oneOf } from 'prop-types';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';
import ContentIntroCard from 'components/ContentIntroCard';
import { REGULAR_CLASS_TYPE, COURSE_TYPE } from 'constants/routingPath';
import { HOME } from 'constants/pageTypes';

import SubSectionTitle from '../MainOfferingSection/components/SubSectionTitle';
import useGetCurrentImageType from 'hooks/useGetCurrentImageType';

import commonHorizontalPaddingStyles from '../../commonHorizontalPadding.scss';
import styles from './ContentListSection.scss';

const ContentListSection = ({
  contentType,
  contentList,
  title,
  description,
  CTAbuttonText,
  onClickCTA,
}) => {
  useStyles(commonHorizontalPaddingStyles, styles);

  const { imageType, setContainerRef } = useGetCurrentImageType();

  return (
    <div className={cx(commonHorizontalPaddingStyles.container, styles.root)}>
      <SubSectionTitle
        className={styles.titleSection}
        title={title}
        description={description}
        buttonText={CTAbuttonText}
        onClick={onClickCTA}
      />
      <div ref={setContainerRef} className={styles.contentList}>
        {contentList.slice(0, 4).map((content, index) => (
          <ContentIntroCard
            {...content}
            key={content.id}
            contentType={contentType}
            pageType={HOME}
            serialNumber={index + 1}
            pictureUrl={content.picture[imageType]}
          />
        ))}
      </div>
    </div>
  );
};

ContentListSection.propTypes = {
  contentType: oneOf([COURSE_TYPE, REGULAR_CLASS_TYPE]).isRequired,
  contentList: arrayOf(
    shape({
      id: number.isRequired,
      title: string.isRequired,
      pictureUrl: string.isRequired,
      hashtags: arrayOf(
        shape({
          key: number.isRequired,
          tag: string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
  title: string.isRequired,
  description: string.isRequired,
  CTAbuttonText: string.isRequired,
  onClickCTA: func.isRequired,
};

export default ContentListSection;
