// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Y3fh{overflow-x:hidden;position:relative;width:100%}._3Y3fh,._2xaeW{display:-ms-flexbox;display:flex}._2xaeW{-webkit-animation:_1rcTE var(--duration) linear infinite;animation:_1rcTE var(--duration) linear infinite;-webkit-animation-play-state:running;animation-play-state:running}._3Y3fh:hover>._2xaeW{-webkit-animation-play-state:var(--pause-on-hover);animation-play-state:var(--pause-on-hover)}@-webkit-keyframes _1rcTE{0%{-webkit-transform:translateX(0);transform:translateX(0)}to{-webkit-transform:translateX(-100%);transform:translateX(-100%)}}@keyframes _1rcTE{0%{-webkit-transform:translateX(0);transform:translateX(0)}to{-webkit-transform:translateX(-100%);transform:translateX(-100%)}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"container": "_3Y3fh",
	"marquee": "_2xaeW",
	"scroll": "_1rcTE"
};
module.exports = exports;
