// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1POy1{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin:.75rem 0 1rem;grid-row-gap:16px;row-gap:16px;grid-row-gap:1rem;row-gap:1rem;grid-column-gap:1.25rem;-webkit-column-gap:1.25rem;-moz-column-gap:1.25rem;column-gap:1.25rem}@media(max-width:767px){._1POy1{margin-top:0}}", ""]);
// Exports
exports.locals = {
	"root": "_1POy1"
};
module.exports = exports;
