// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".gKQJ3{position:relative;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;grid-gap:8px;gap:8px;grid-gap:.5rem;gap:.5rem}@media(max-width:767px){.gKQJ3{grid-gap:0;gap:0}}._39os8{display:inline-block}@media(max-width:767px){._39os8{line-height:0;margin-right:0;margin-bottom:.5rem;-ms-flex:0 0 100%;flex:0 0 100%}}", ""]);
// Exports
exports.locals = {
	"root": "gKQJ3",
	"select": "_39os8"
};
module.exports = exports;
