import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  Fragment,
} from 'react';
import { bool, func, string, array, number, oneOfType } from 'prop-types';
import ReactDOM from 'react-dom';
import { useTransition, animated } from '@react-spring/web';
import cx from 'classnames';
import { CheckIcon } from '@snapask/common-components';

import useBaseOnWindowSize from 'hooks/useBaseOnWindowSize';
import useStyles from 'hooks/useStyles';
import { CloseIcon } from 'components/Icon';
import styles from './SelectPullUp.scss';

const SelectPullUp = ({
  title,
  options,
  visible = false,
  onClose,
  onChange,
  value: selectedValue,
}) => {
  useStyles(styles);
  const bodyRef = useRef(document.body);
  const rootRef = useRef();
  const [fullHeightMode, setFullHeightMode] = useState(false);
  useBaseOnWindowSize(() => {
    if (rootRef.current?.offsetHeight >= window.innerHeight) {
      setFullHeightMode(true);
    }
  });
  const transitions = useTransition(visible, {
    from: { transform: `translate3d(0,50%,0)`, opacity: 0 },
    enter: { transform: `translate3d(0,0%,0)`, opacity: 1 },
    leave: { transform: `translate3d(0,50%,0)`, opacity: 0 },
  });

  useEffect(() => {
    if (visible) {
      bodyRef.current.classList.add(styles.scrollPrevent);
    } else {
      bodyRef.current.classList.remove(styles.scrollPrevent);
    }
  }, [visible]);

  useLayoutEffect(() => {
    if (rootRef.current?.offsetHeight >= window.innerHeight) {
      setFullHeightMode(true);
    }
  }, [rootRef.current?.offsetHeight]);

  return (
    <Fragment>
      {visible && <animated.div className={styles.mask} onClick={onClose} />}
      <Fragment>
        {transitions((props, item, key) =>
          item ? (
            <Fragment key={key}>
              <animated.div
                ref={rootRef}
                key={key}
                className={cx(styles.root, {
                  [styles.rootFullHeight]: fullHeightMode,
                })}
                style={{ ...props }}
              >
                <div
                  className={cx(styles.control, {
                    [styles.controlFullHeight]: fullHeightMode,
                  })}
                >
                  <h3 className={styles.title}>{title}</h3>
                  <CloseIcon className={styles.cancel} onClick={onClose} />
                </div>
                <div
                  className={cx(styles.content, {
                    [styles.contentFullHeight]: fullHeightMode,
                  })}
                >
                  {options.map(({ label, value }) => (
                    <div
                      className={cx(styles.option, {
                        [styles.optionSelected]: value === selectedValue,
                      })}
                      key={value}
                      onClick={() => onChange(value, { label, value })}
                    >
                      {label}
                      {value === selectedValue && (
                        <CheckIcon
                          className={styles.check}
                          width={24}
                          height={24}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </animated.div>
            </Fragment>
          ) : null,
        )}
      </Fragment>
    </Fragment>
  );
};

SelectPullUp.propTypes = {
  title: string.isRequired,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onChange: func.isRequired,
  options: array,
  value: oneOfType([string, number]),
};

SelectPullUp.defaultProps = {
  options: [],
  value: null,
};

const PortalSelectPullUp = ({ ...props }) => {
  return ReactDOM.createPortal(<SelectPullUp {...props} />, document.body);
};

export default PortalSelectPullUp;
