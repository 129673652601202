import React, { Fragment } from 'react';
import { string, arrayOf, shape, number, bool, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import LazyLoad from 'react-lazyload';

import useStyles from 'hooks/useStyles';

import Star from 'components/Star';
import LoadedImage from 'components/LoadedImage';
import LoadingSkeleton from 'components/LoadingSkeleton';

import styles from './Front.scss';

function Front({
  pictureUrl,
  averageRate,
  title,
  showPrice,
  price,
  isFree,
  hashtags,
}) {
  useStyles(styles);
  const { t } = useTranslation(['course']);

  const { originalPrice, sellingPrice, discount } = price;
  const priceSection = (
    <Fragment>
      {sellingPrice}
      {discount && <del className={styles.originalPrice}>{originalPrice}</del>}
    </Fragment>
  );

  return (
    <div className={styles.root}>
      <LazyLoad
        height={153}
        offset={[250, 0]}
        placeholder={<LoadingSkeleton className={styles.loading} />}
      >
        <div className={styles.picture}>
          <LoadedImage className={styles.image} src={pictureUrl} alt={title} />
        </div>
      </LazyLoad>
      <div className={styles.container}>
        <h5 className={styles.title}>{title}</h5>
        {averageRate !== 0 && (
          <div className={styles.rating}>
            <Star svgSize={16} type="GRAY_FILLED" className={styles.star} />
            {averageRate}
          </div>
        )}
        {showPrice && (
          <div className={styles.price}>
            {isFree ? t('home.promotionBanner.free') : priceSection}
          </div>
        )}
        <ul
          className={cx(styles.tags, {
            [styles.hasReservedHeight]: !showPrice,
          })}
        >
          {hashtags.map(contentHashtag => (
            <li key={contentHashtag.key} className={styles.tag}>
              {contentHashtag.tag}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

Front.propTypes = {
  pictureUrl: string.isRequired,
  title: string.isRequired,
  hashtags: arrayOf(
    shape({
      key: number.isRequired,
      tag: string.isRequired,
    }).isRequired,
  ).isRequired,
  showPrice: bool.isRequired,
  price: object,
  isFree: bool,
  averageRate: number,
};

Front.defaultProps = {
  price: {},
  isFree: false,
  averageRate: 0,
};

export default Front;
