// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cZ5S1{margin-bottom:1rem}.cZ5S1,._25HCo{margin-top:2rem}._25HCo{font-size:32px;font-size:2rem;font-weight:500;line-height:40px;line-height:2.5rem;font-family:var(--heading-font);letter-spacing:var(--letter-spacing)}@media(max-width:767px){._25HCo{font-size:1.625rem;line-height:2.03125rem;text-align:center}}.EfYQE{position:relative;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;grid-gap:16px;gap:16px;grid-gap:1rem;gap:1rem;margin-top:1rem;margin-bottom:1.5rem}._1SMEQ{width:49%}@media(max-width:1439px){._1SMEQ{width:100%}}", ""]);
// Exports
exports.locals = {
	"root": "cZ5S1",
	"title": "_25HCo",
	"cardList": "EfYQE",
	"classCard": "_1SMEQ"
};
module.exports = exports;
