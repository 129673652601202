import { EMPTY, ERROR, READY, LOADING } from 'constants/loadingState';

import { appLaunch } from 'reduxModules/auth/action';
import {
  fetchLiveTopics,
  fetchLiveUpcoming,
  fetchHomeLessonList,
  fetchSchedulePastLesson,
  fetchScheduleUpcomingLesson,
  fetchSubscribedPreOrderedTopic,
  fetchSubscribedOngoingTopic,
  fetchSubscribedCompletedTopic,
  fetchSubscribedTopic,
} from 'reduxModules/live/action';
import {
  fetchCourseAnnouncements,
  fetchLearningCourse,
  fetchUserPurchasedCourse,
} from 'reduxModules/course/action';
import { fetchInstructorList } from 'reduxModules/instructorProfile/action';
import {
  fetchMyLearningData,
  fetchMyLearningContinueWatching,
} from 'reduxModules/myLearning/action';
import { fetchSiteInfo } from 'reduxModules/siteInfo/action';
import {
  fetchBannerList,
  fetchRegularClassLessonList,
  fetchRegularClassInstructorList,
  fetchCourseList,
  fetchAcademyList,
} from 'reduxModules/home/action';
import {
  fetchBannerList as fetchHomeBannerList,
  fetchRegularClassLessonList as fetchHomeRegularClassLessonList,
  fetchCourseList as fetchHomeCourseList,
  fetchAcademyList as fetchHomeAcademyList,
} from 'reduxModules/homeRevamp/action';
import {
  updateUserAttributeData,
  sendPhoneVerificationCode,
  verifyPhone,
  resentPhoneVerificationCode,
} from 'reduxModules/account/action';

export const getLoadingStateByType = type => state => ({
  isEmpty: state.actionStatus[type].state === EMPTY,
  isLoading: state.actionStatus[type].state === LOADING,
  isReady: state.actionStatus[type].state === READY,
  hasError: state.actionStatus[type].state === ERROR,
});

export const getAppLaunchState = getLoadingStateByType(appLaunch.type);

export const getFetchLiveUpcomingLoadingState = getLoadingStateByType(
  fetchLiveUpcoming.type,
);

export const getFetchHomeLessonListLoadingState = getLoadingStateByType(
  fetchHomeLessonList.type,
);

export const getFetchLiveTopicsLoadingState = getLoadingStateByType(
  fetchLiveTopics.type,
);

export const getFetchSchedulePastLessonState = getLoadingStateByType(
  fetchSchedulePastLesson.type,
);

export const getFetchScheduleUpcomingLessonState = getLoadingStateByType(
  fetchScheduleUpcomingLesson.type,
);

export const getFetchSubscribedTopicState = getLoadingStateByType(
  fetchSubscribedTopic.type,
);

export const getFetchSubscribedPreOrderedTopicState = getLoadingStateByType(
  fetchSubscribedPreOrderedTopic.type,
);

export const getFetchSubscribedCompletedTopicState = getLoadingStateByType(
  fetchSubscribedCompletedTopic.type,
);

export const getFetchSubscribedOngoingTopicState = getLoadingStateByType(
  fetchSubscribedOngoingTopic.type,
);

export const getFetchCourseAnnouncementsState = getLoadingStateByType(
  fetchCourseAnnouncements.type,
);

export const getFetchLearningCourseState = getLoadingStateByType(
  fetchLearningCourse.type,
);
export const getFetchUserPurchasedCourseState = getLoadingStateByType(
  fetchUserPurchasedCourse.type,
);

export const getFetchInstructorListState = getLoadingStateByType(
  fetchInstructorList.type,
);

export const getFetchMyLearningDataState = getLoadingStateByType(
  fetchMyLearningData.type,
);

export const getFetchMyLearningContinueWatchingState = getLoadingStateByType(
  fetchMyLearningContinueWatching.type,
);
export const getFetchSiteInfoState = getLoadingStateByType(fetchSiteInfo.type);
export const getFetchBannerListState = getLoadingStateByType(
  fetchBannerList.type,
);
export const getFetchRegularClassLessonListState = getLoadingStateByType(
  fetchRegularClassLessonList.type,
);
export const getFetchRegularClassInstructorListState = getLoadingStateByType(
  fetchRegularClassInstructorList.type,
);
export const getFetchCourseListState = getLoadingStateByType(
  fetchCourseList.type,
);
export const getFetchAcademyListState = getLoadingStateByType(
  fetchAcademyList.type,
);
export const getFetcHomehBannerListState = getLoadingStateByType(
  fetchHomeBannerList.type,
);
export const getFetcHomehRegularClassLessonListState = getLoadingStateByType(
  fetchHomeRegularClassLessonList.type,
);
export const getFetchHomeCourseListState = getLoadingStateByType(
  fetchHomeCourseList.type,
);
export const getFetchHomeAcademyListState = getLoadingStateByType(
  fetchHomeAcademyList.type,
);
export const getUpdateUserAttributeDataState = getLoadingStateByType(
  updateUserAttributeData.type,
);
export const getSendPhoneVerificationCodeState = getLoadingStateByType(
  sendPhoneVerificationCode.type,
);
export const getVerifyPhoneState = getLoadingStateByType(verifyPhone.type);
export const getResentPhoneVerificationCodeState = getLoadingStateByType(
  resentPhoneVerificationCode.type,
);
