// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HrBHe{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;grid-gap:32px;gap:32px;grid-gap:2rem;gap:2rem}@media(max-width:767px){.HrBHe{grid-gap:1rem;gap:1rem}}._3__QA{grid-gap:32px;gap:32px;grid-gap:2rem;gap:2rem;padding-right:2rem}@media(max-width:767px){._3__QA{grid-gap:1rem;gap:1rem;padding-right:1rem}}._1C347{width:268px;width:16.75rem;-ms-flex-negative:0;flex-shrink:0}@media(max-width:767px){._1C347{width:10rem}}._7R5Iy{border-radius:.75rem;border:1px solid #dcdddd;-webkit-mask-image:-webkit-radial-gradient(#fff,#000);mask-image:-webkit-radial-gradient(#fff,#000);position:relative;padding-bottom:56.25%;overflow:hidden}._1pqBo{width:100%;height:150px;height:9.375rem}._3VvP-{-o-object-fit:cover;object-fit:cover}._2H2rt,._3VvP-{position:absolute;width:100%;height:100%}._2H2rt{background-color:rgba(35,24,21,.56);opacity:0;-webkit-transition:opacity .3s ease-out;-o-transition:opacity .3s ease-out;transition:opacity .3s ease-out}@media(max-width:767px){._2H2rt{display:none}}._1C347:hover ._2H2rt{opacity:1}._2H2rt svg{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.XKHak{width:183px;width:11.4375rem;margin:auto}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "HrBHe",
	"marquee": "_3__QA",
	"slide": "_1C347",
	"slideInner": "_7R5Iy",
	"loading": "_1pqBo",
	"image": "_3VvP-",
	"hoverMask": "_2H2rt",
	"button": "XKHak"
};
module.exports = exports;
