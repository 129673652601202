import React, { useContext } from 'react';
import { bool } from 'prop-types';
import useStyles from 'hooks/useStyles';

import ApplicationContext from 'components/ApplicationContext';
import CircleProgressBar from 'components/CircleProgressBar';
import { PlayerPlayNextIcon } from 'components/Icon';

import styles from './AutoPlayCircleProgressBar.scss';

const AutoPlayCircleProgressBar = ({ stopAutoPlay }) => {
  useStyles(styles);

  const {
    screenWidth: { isMobile },
  } = useContext(ApplicationContext);

  return (
    <CircleProgressBar
      id="AutoPlayController"
      progress={0}
      radius={isMobile ? 26 : 41}
      strokeWidth={isMobile ? 4 : 8}
      isAutoPlay
      classNameMap={{
        bar: styles.bar,
        progress: styles.progress,
        graphics: stopAutoPlay ? styles.stopAutoPlayGraphics : '',
      }}
      renderInnerCircle={props => <PlayerPlayNextIcon {...props} />}
    />
  );
};

AutoPlayCircleProgressBar.propTypes = {
  stopAutoPlay: bool.isRequired,
};

export default AutoPlayCircleProgressBar;
