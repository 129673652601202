import React, { useState } from 'react';
import { array, func, string } from 'prop-types';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';
import Select from '../Select';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './SelectGroup.scss';

const SelectGroup = ({
  options,
  value: selectedValue,
  onChange,
  mainTitle,
  subTitle,
  className,
  id,
}) => {
  useStyles(styles);
  const [currentSubOptions, setCurrentSubOptions] = useState(
    options.find(({ value }) => value === selectedValue[0])?.options,
  );

  const subOptionVisible =
    selectedValue[0] !== 0 && currentSubOptions?.length !== 0;

  const changeValue = (level, newValue, option) => {
    if (level === 0) {
      setCurrentSubOptions(
        options.find(({ value }) => value === newValue)?.options,
      );
      onChange([newValue, 0], option);
    } else {
      onChange([selectedValue[0], newValue], option);
    }
  };

  return (
    <div className={cx(styles.root, className)} id={id}>
      <Select
        title={mainTitle}
        options={options}
        value={selectedValue[0]}
        className={styles.select}
        onChange={(value, option) => changeValue(0, value, option)}
      />
      {subOptionVisible && currentSubOptions && (
        <Select
          title={subTitle}
          options={currentSubOptions}
          value={selectedValue[1]}
          className={styles.select}
          onChange={(value, option) => changeValue(1, value, option)}
        />
      )}
    </div>
  );
};

SelectGroup.propTypes = {
  value: array,
  id: string,
  className: string,
  options: array.isRequired,
  mainTitle: string.isRequired,
  subTitle: string.isRequired,
  onChange: func.isRequired,
};

SelectGroup.defaultProps = {
  value: [0, 0],
  id: '',
  className: '',
};

export default SelectGroup;
