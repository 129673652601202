import React from 'react';

import useStyles from 'hooks/useStyles';

import styles from './UniverseBackground.scss';

const UniverseBackground = () => {
  useStyles(styles);

  return (
    <div className={styles.root}>
      <div className={styles.RCImageLeft} />
      <div className={styles.RCImageRight} />
      <div className={styles.QAImageLeft} />
      <div className={styles.courseImageLeft} />
      <div className={styles.courseImageRight} />
      <div className={styles.QAImageRight} />
      <div className={styles.grayMask} />
    </div>
  );
};

export default UniverseBackground;
