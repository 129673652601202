import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';
import LazyLoad from 'react-lazyload';

import useStyles from 'hooks/useStyles';
import Link from 'components/Link';
import LoadingSkeleton from 'components/LoadingSkeleton';

import styles from './RecommendedSection.scss';

const RecommendedSection = forwardRef(({ className }, ref) => {
  useStyles(styles);
  const { t } = useTranslation(['landingPage']);
  const recommendedList = t('home.press.reports', {
    returnObjects: true,
    defaultValue: [],
  }).filter(item => item.logo !== '-');

  const renderImg = ({ text, logo }) => (
    <LazyLoad
      height={34}
      offset={[-34, 0]}
      placeholder={<LoadingSkeleton className={styles.loading} />}
    >
      <img className={styles.img} alt={text !== '-' ? text : null} src={logo} />
    </LazyLoad>
  );

  if (recommendedList.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      <div ref={ref} className={styles.container}>
        {recommendedList.map(({ text, logo, articleUrl }) =>
          articleUrl === '-' ? (
            renderImg({ text, logo })
          ) : (
            <Link key={logo} to={articleUrl}>
              {renderImg({ text, logo })}
            </Link>
          ),
        )}
      </div>
    </div>
  );
});

RecommendedSection.propTypes = {
  className: string.isRequired,
};

export default RecommendedSection;
