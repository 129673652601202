import React, { useRef, useEffect, useState } from 'react';
import { array, func } from 'prop-types';
import useStyles from 'hooks/useStyles';
// version 6 API documentation: https://swiper6.vercel.app/get-started
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import swiperStyles from 'swiper/swiper.scss';
import swiperPaginationStyles from 'swiper/components/pagination/pagination.scss';

import { ChevronIcon } from 'components/Icon';
import CaseCard from '../CaseCard';
import styles from './TestimonialMobile.scss';

SwiperCore.use([Pagination]);

const TestimonialMobile = ({ data, t }) => {
  useStyles(swiperStyles, swiperPaginationStyles, styles);
  const [cardHeight, setCardHeigh] = useState();
  const swiperRef = useRef();
  const swiperDomRef = useRef();

  const changeCartHeightList = (height, index, isActive) => {
    if (isActive) setCardHeigh(height);
  };

  useEffect(() => {
    swiperDomRef.current.style.height = `${cardHeight + 109}px`;
  }, [cardHeight]);

  return (
    <div className={styles.root}>
      <button
        className={styles.nextButton}
        type="button"
        onClick={() => swiperRef.current.slideNext()}
      >
        {t('testimonial.cta')}
        <ChevronIcon className={styles.chevron} />
      </button>
      <Swiper
        onInit={ref => {
          swiperRef.current = ref;
        }}
        slidesPerView={1}
        spaceBetween={32}
        ref={swiperDomRef}
        pagination
        loop
      >
        {data.map((content, index) => (
          <SwiperSlide key={content.studentName}>
            {({ isActive }) => (
              <CaseCard
                isActive={isActive}
                exceedMode="click"
                setCardHeight={height =>
                  changeCartHeightList(height, index, isActive)
                }
                {...content}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

TestimonialMobile.propTypes = {
  data: array.isRequired,
  t: func.isRequired,
};

export default TestimonialMobile;
