import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';
import useElementHaveSeen from 'hooks/useElementHaveSeen';
import useGetOfficialSiteURL from 'hooks/useGetOfficialSiteURL';
import { COURSE as COURSE_PATH, COURSE_TYPE } from 'constants/routingPath';
import { push } from 'reduxModules/navigation/action';
import { fetchCourseList } from 'reduxModules/home/action';
import { getHomeCourseList } from 'reduxModules/home/selector';
import { HOME_COURSEFEATURE_CTA_CLICK } from 'constants/gtmEvent';
import { sendGTMEvent } from 'reduxModules/googleTagManager/action';

import { COURSE } from '../../constants';
import ContentListSection from '../ContentListSection';
import Marquee from '../Marquee';
import IntroCard from '../IntroCard';
import SubSectionTitle from '../MainOfferingSection/components/SubSectionTitle';
import CourseMarquee from './components/CourseMarquee';
import QuizQuestionsContent from './components/QuizQuestionsContent';

import commonHorizontalPaddingStyles from '../../commonHorizontalPadding.scss';
import commonHighlightSectionStyles from '../MainOfferingSection/commonHighlightSection.scss';
import styles from './CourseSection.scss';

const CourseSection = () => {
  useStyles(
    commonHorizontalPaddingStyles,
    commonHighlightSectionStyles,
    styles,
  );
  const { t } = useTranslation(['landingPage']);
  const dispatch = useDispatch();
  const getOfficialSiteURL = useGetOfficialSiteURL();
  const courseList = useSelector(getHomeCourseList);
  const [highlightSectionRef, highlightSectionHaveSeen] = useElementHaveSeen();

  const renderBiteSizeVideos = () => (
    <Marquee
      classNameMap={{
        marquee: styles.biteSizeVideosMarquee,
      }}
      speed={100}
    >
      <div className={cx(styles.biteSizeVideosShape, styles.chunk)} />
      <div className={cx(styles.biteSizeVideosShape, styles.subtract)} />
      <div className={cx(styles.biteSizeVideosShape, styles.chunk)} />
    </Marquee>
  );

  const INTRO_CONTENT_LIST = [
    {
      key: 'biteSizeVideos',
      content: renderBiteSizeVideos(),
    },
    {
      key: 'quizQuestions',
      content: (
        <QuizQuestionsContent showEnterAnimation={highlightSectionHaveSeen} />
      ),
      hasTag: true,
    },
  ];

  useEffect(() => {
    dispatch(fetchCourseList());
  }, [dispatch]);

  const handleClickCTA = () => {
    dispatch(push(getOfficialSiteURL(COURSE_PATH)));
    dispatch(
      sendGTMEvent({
        eventName: HOME_COURSEFEATURE_CTA_CLICK,
      }),
    );
  };

  return (
    <Fragment>
      <CourseMarquee courseList={courseList} />
      <div>
        <SubSectionTitle
          className={cx(
            commonHorizontalPaddingStyles.container,
            styles.subSectionTitle,
          )}
          title={t('home.courseSection.subSection.title')}
          description={t('home.courseSection.subSection.description')}
        />
        <div
          ref={highlightSectionRef}
          className={cx(
            commonHorizontalPaddingStyles.container,
            commonHighlightSectionStyles.container,
          )}
        >
          {INTRO_CONTENT_LIST.map(({ key, content, hasTag }, index) => (
            <IntroCard
              key={key}
              name={COURSE}
              title={t(`home.courseSection.highlights.${index}.title`)}
              description={t(
                `home.courseSection.highlights.${index}.description`,
              )}
              content={content}
              tag={
                hasTag
                  ? t(`home.courseSection.highlights.${index}.status`)
                  : null
              }
            />
          ))}
        </div>
      </div>
      <ContentListSection
        contentList={courseList}
        title={t('home.courseSection.featuredCourse.title')}
        description={t('home.courseSection.featuredCourse.description')}
        CTAbuttonText={t('home.courseSection.featuredCourse.cta')}
        onClickCTA={handleClickCTA}
        contentType={COURSE_TYPE}
      />
    </Fragment>
  );
};

CourseSection.propTypes = {};

export default CourseSection;
