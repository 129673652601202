import React from 'react';
import { string } from 'prop-types';

import useStyles from 'hooks/useStyles';

import styles from './HollowCircle.scss';

const HollowCircle = ({ color }) => {
  useStyles(styles);

  return (
    <svg className={styles.root}>
      <circle
        style={{
          '--stroke': color,
        }}
        className={styles.shape}
      />
    </svg>
  );
};

HollowCircle.propTypes = {
  color: string.isRequired,
};

export default HollowCircle;
