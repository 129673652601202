// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1taNH{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}@media(min-width:1440px){._1taNH{grid-gap:var(--wide-desktop-up-gap);gap:var(--wide-desktop-up-gap)}}@media(min-width:1024px)and (max-width:1439px){._1taNH{grid-gap:5rem;gap:5rem}}@media(min-width:768px)and (max-width:1023px){._1taNH{grid-gap:3.5rem;gap:3.5rem}}@media(max-width:767px){._1taNH{grid-gap:3rem;gap:3rem}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"container": "_1taNH"
};
module.exports = exports;
