import React, { useContext } from 'react';
import { arrayOf, number, shape, string, func, object, bool } from 'prop-types';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';

import ApplicationContext from 'components/ApplicationContext';
import Link from 'components/Link';

import Front from './components/Front';
import Back from './components/Back';
import styles from './BaseContentIntroCard.scss';

function BaseContentIntroCard({
  onClick,
  pageUrl,
  pictureUrl,
  averageRate,
  title,
  price,
  isFree,
  hashtags,
  lessonsAmount,
  durationTotal,
  highlightList,
  showBack,
  showPrice,
}) {
  useStyles(styles);

  const {
    screenWidth: { isDesktop, isWideDesktop },
  } = useContext(ApplicationContext);

  return (
    <Link
      className={cx(styles.root, {
        [styles.hasReservedHeight]: showPrice,
      })}
      to={pageUrl}
      onClick={onClick}
      data-ooo-image-container
    >
      {showBack && (isDesktop || isWideDesktop) && (
        <Back
          className={styles.back}
          lessonsAmount={lessonsAmount}
          durationTotal={durationTotal}
          highlightList={highlightList}
        />
      )}
      <Front
        averageRate={averageRate}
        pictureUrl={pictureUrl}
        title={title}
        showPrice={showPrice}
        price={price}
        isFree={isFree}
        hashtags={hashtags}
      />
    </Link>
  );
}

BaseContentIntroCard.propTypes = {
  onClick: func.isRequired,
  title: string.isRequired,
  hashtags: arrayOf(
    shape({
      key: number.isRequired,
      tag: string.isRequired,
    }).isRequired,
  ).isRequired,
  lessonsAmount: number,
  durationTotal: shape({
    hours: number.isRequired,
    minutes: number.isRequired,
    seconds: number.isRequired,
  }),
  highlightList: arrayOf(
    shape({
      title: string.isRequired,
      iconUrl: string.isRequired,
      description: string,
    }).isRequired,
  ),
  price: object,
  pictureUrl: string,
  isFree: bool,
  showBack: bool,
  showPrice: bool,
  pageUrl: string,
  averageRate: number,
};

BaseContentIntroCard.defaultProps = {
  pageUrl: '',
  price: {},
  lessonsAmount: undefined,
  durationTotal: undefined,
  highlightList: undefined,
  pictureUrl: '',
  isFree: false,
  showBack: false,
  showPrice: false,
  averageRate: 0,
};

export default BaseContentIntroCard;
