// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3IGjL .swiper-container{padding:2rem 1.5rem}@media(min-width:768px)and (max-width:1023px){._3IGjL .swiper-container{padding-left:2.5rem;padding-right:2.5rem}}@media(min-width:1024px)and (max-width:1167px){._3IGjL .swiper-container{padding-left:72px;padding-right:72px}}@media(min-width:1168px)and (max-width:1439px){._3IGjL .swiper-container{padding-left:calc(50vw - 512px);padding-right:calc(50vw - 512px)}}@media(min-width:1440px){._3IGjL .swiper-container{padding-left:208px;padding-right:208px}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "_3IGjL"
};
module.exports = exports;
