// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3h2Ho{display:-ms-flexbox;display:flex;grid-gap:32px;gap:32px;grid-gap:2rem;gap:2rem;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;max-width:1000px;max-width:62.5rem;width:100%}@media(max-width:1023px){._3h2Ho{-ms-flex-direction:column;flex-direction:column}}._2xT9O{width:auto}@media(max-width:1023px){._2xT9O{text-align:center}}._23FOJ{padding:.25rem 0 1.5rem}._1Oa34{color:#231815;background-color:#f4c708}._1Oa34:active,._1Oa34:focus,._1Oa34:hover{background-color:#c3a119}._1Dar0{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}@media(min-width:768px){._1Dar0{width:25.5rem;height:14.25rem}}@media(max-width:767px){._1Dar0{width:17rem;height:9.5rem}}._1TQYY{overflow:hidden;width:408px;width:25.5rem;height:228px;height:14.25rem;border-radius:.75rem}@media(max-width:767px){._1TQYY{width:17rem;height:9.5rem}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "_3h2Ho",
	"titleSection": "_2xT9O",
	"title": "_23FOJ",
	"ctaButton": "_1Oa34",
	"content": "_1Dar0",
	"player": "_1TQYY"
};
module.exports = exports;
