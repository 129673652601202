import React from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';
import Button from 'components/Button';

import styles from './Back.scss';

function Back({ className, lessonsAmount, durationTotal, highlightList }) {
  useStyles(styles);
  const { t } = useTranslation(['course']);

  const renderHighlights = item => (
    <li className={styles.item} key={item.iconUrl}>
      <div className={styles.img}>
        <img alt="" src={item.iconUrl} />
      </div>
      <div className={styles.title}>{item.title}</div>
    </li>
  );

  return (
    <div className={cx(styles.root, className)}>
      <ul className={styles.meta}>
        <li>
          {t('course:videoplayer.lesson', {
            count: lessonsAmount,
          })}
          ・{t('course:videoplayer.time', durationTotal)}
        </li>
      </ul>
      <ul>{highlightList.map(renderHighlights)}</ul>
      <div className={styles.button}>
        <Button
          type="primary"
          block
          onClick={() => {}}
          text={t(`home.limitedOffers.viewCourseBtn`)}
        />
      </div>
    </div>
  );
}

Back.propTypes = {
  className: string.isRequired,
  lessonsAmount: number.isRequired,
  durationTotal: shape({
    hours: number.isRequired,
    minutes: number.isRequired,
    seconds: number.isRequired,
  }).isRequired,
  highlightList: arrayOf(
    shape({
      title: string.isRequired,
      iconUrl: string.isRequired,
      description: string,
    }).isRequired,
  ).isRequired,
};

export default Back;
