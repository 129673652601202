// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1YuCC{width:100%;height:404px;height:25.25rem}._3hAdb{position:relative;padding-bottom:56.25%;border-radius:.5rem;overflow:hidden;-webkit-mask-image:-webkit-radial-gradient(#fff,#000);mask-image:-webkit-radial-gradient(#fff,#000)}._3hAdb .H77xO{position:absolute;-o-object-fit:cover;object-fit:cover;width:100%;height:100%;-webkit-transition:-webkit-transform .3s cubic-bezier(.23,1,.32,1);transition:-webkit-transform .3s cubic-bezier(.23,1,.32,1);-o-transition:transform .3s cubic-bezier(.23,1,.32,1);transition:transform .3s cubic-bezier(.23,1,.32,1);transition:transform .3s cubic-bezier(.23,1,.32,1),-webkit-transform .3s cubic-bezier(.23,1,.32,1)}._35nIU:hover ._3hAdb .H77xO{-webkit-transform:scale(1.1);-ms-transform:scale(1.1);transform:scale(1.1)}.emzwE{padding:.75rem}.yOE-B{margin-bottom:.75rem;display:-webkit-box;overflow:hidden;white-space:normal;-webkit-line-clamp:2;-webkit-box-orient:vertical}._1Yx9Z{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;grid-gap:8px;gap:8px;grid-gap:.5rem;gap:.5rem}@media(min-width:768px){._3H_E5{min-height:2rem}}.cDQ76{background-color:#dcdddd;padding:.25rem .75rem;border-radius:.25rem;font-size:14px;font-weight:400;line-height:23px;font-family:var(--base-font);display:block;overflow:hidden;white-space:nowrap;-o-text-overflow:ellipsis;text-overflow:ellipsis}@media(max-width:767px){.cDQ76{font-size:16px;line-height:26px}}.dBhAe{margin-bottom:.75rem;font-size:18px;font-weight:400;line-height:29px}._2FtvS,.dBhAe{font-family:var(--base-font)}._2FtvS{color:#9fa0a0;margin-left:.5rem;font-size:12px;font-weight:300;line-height:20px}@media(max-width:767px){._2FtvS{font-size:14px;line-height:23px}}._3D9kp{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;color:#595757;margin-bottom:.75rem;font-size:16px;font-weight:400;line-height:26px;font-family:var(--base-font)}._24RwM{margin-right:.125rem}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"loading": "_1YuCC",
	"picture": "_3hAdb",
	"image": "H77xO",
	"root": "_35nIU",
	"container": "emzwE",
	"title": "yOE-B",
	"tags": "_1Yx9Z",
	"hasReservedHeight": "_3H_E5",
	"tag": "cDQ76",
	"price": "dBhAe",
	"originalPrice": "_2FtvS",
	"rating": "_3D9kp",
	"star": "_24RwM"
};
module.exports = exports;
