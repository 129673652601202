import React from 'react';
import { oneOf, number, string } from 'prop-types';
import useStyles from 'hooks/useStyles';
import cx from 'classnames';

import {
  FILLED,
  UNFILLED,
  DARK_FILLED,
  GRAY_FILLED,
  HOLLOW,
  BRIGHT_HOLLOW,
} from './constants';
import styles from './Star.scss';

const Star = ({ className, svgSize, type }) => {
  useStyles(styles);
  const isHollow = type === HOLLOW || type === BRIGHT_HOLLOW;
  const TYPE = {
    [FILLED]: styles.filled,
    [GRAY_FILLED]: styles.grayFilled,
    [DARK_FILLED]: styles.darkFilled,
    [UNFILLED]: styles.unfilled,
    [HOLLOW]: styles.hollow,
    [BRIGHT_HOLLOW]: styles.brightHollow,
  };

  return (
    <svg
      style={{
        strokeWidth: isHollow ? svgSize * 0.1 : 0,
      }}
      className={cx(TYPE[type], className)}
      width={svgSize}
      height={svgSize}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.27 12.752a1.343 1.343 0 0 0-1.166-.959l-8.294-.657-2.554-7.542c-.19-.557-.691-.927-1.256-.927-.565 0-1.068.373-1.255.93l-2.534 7.54-8.312.656c-.538.042-1 .421-1.166.959a1.434 1.434 0 0 0 .406 1.491l5.753 5.105-1.615 8.316a1.427 1.427 0 0 0 .54 1.416 1.288 1.288 0 0 0 1.457.053L16 24.867l6.728 4.266a1.289 1.289 0 0 0 1.457-.053c.432-.32.643-.873.539-1.416l-1.614-8.316 5.754-5.105c.412-.365.572-.954.406-1.491z"
        fill="none"
      />
    </svg>
  );
};

Star.propTypes = {
  className: string,
  svgSize: number,
  type: oneOf([FILLED, UNFILLED, HOLLOW]),
};

Star.defaultProps = {
  className: '',
  svgSize: 32,
  type: HOLLOW,
};

export default Star;
