import React from 'react';
import { arrayOf, string, shape, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import LazyLoad from 'react-lazyload';

import useStyles from 'hooks/useStyles';
import useGetOfficialSiteURL from 'hooks/useGetOfficialSiteURL';
import useGetCurrentImageType from 'hooks/useGetCurrentImageType';

import Link from 'components/Link';
import Button from 'components/Button';
import { ViewIcon } from 'components/Icon';
import LoadingSkeleton from 'components/LoadingSkeleton';
import LoadedImage from 'components/LoadedImage';

import { COURSE, COURSE_INFORMATION_TEMPLATE } from 'constants/routingPath';
import {
  HOME_COURSESLIDE_SLIDE_CLICK,
  HOME_COURSESLIDE_CTA_CLICK,
} from 'constants/gtmEvent';

import { push } from 'reduxModules/navigation/action';
import { sendGTMEvent } from 'reduxModules/googleTagManager/action';

import Marquee from '../../../Marquee';

import styles from './CourseMarquee.scss';

const CourseMarquee = ({ courseList }) => {
  useStyles(styles);
  const { t } = useTranslation(['landingPage']);
  const dispatch = useDispatch();
  const getOfficialSiteURL = useGetOfficialSiteURL();

  const { imageType, setContainerRef } = useGetCurrentImageType(true);

  const handleClickCourse = ({ id, name }) => {
    dispatch(
      sendGTMEvent({
        eventName: HOME_COURSESLIDE_SLIDE_CLICK,
        data: {
          courseId: id,
          courseName: name,
        },
      }),
    );
  };

  const handleButtonClick = () => {
    dispatch(push(getOfficialSiteURL(COURSE)));
    dispatch(
      sendGTMEvent({
        eventName: HOME_COURSESLIDE_CTA_CLICK,
      }),
    );
  };

  const halfCourseListLength = Math.ceil(courseList.length / 2);
  const marqueeInfoList = [
    {
      speed: 60,
      startIndex: 0,
      endIndex: halfCourseListLength,
    },
    {
      speed: 20,
      startIndex: halfCourseListLength,
    },
  ];

  if (courseList.length < 10) {
    return null;
  }

  return (
    <div ref={setContainerRef} className={styles.root}>
      {marqueeInfoList.map(({ speed, startIndex, endIndex }) => (
        <Marquee
          key={startIndex}
          pauseOnHover
          speed={speed}
          classNameMap={{
            marquee: styles.marquee,
          }}
        >
          {courseList
            .slice(startIndex, endIndex)
            .map(({ id, title, picture }) => (
              <Link
                key={id}
                data-ooo-image-container
                className={styles.slide}
                onClick={() => handleClickCourse({ id, name: title })}
                to={getOfficialSiteURL(COURSE_INFORMATION_TEMPLATE, {
                  id,
                })}
              >
                <div className={styles.slideInner}>
                  <LazyLoad
                    height={90}
                    offset={[-90, 0]}
                    placeholder={<LoadingSkeleton className={styles.loading} />}
                  >
                    <LoadedImage
                      className={styles.image}
                      src={picture[imageType]}
                      alt={title}
                    />
                  </LazyLoad>
                  <div className={styles.hoverMask}>
                    <ViewIcon />
                  </div>
                </div>
              </Link>
            ))}
        </Marquee>
      ))}
      <Button
        className={styles.button}
        type="primary"
        onClick={handleButtonClick}
        text={t('home.courseSection.cta')}
      />
    </div>
  );
};

CourseMarquee.propTypes = {
  courseList: arrayOf(
    shape({
      id: number.isRequired,
      title: string.isRequired,
      pictureUrl: string.isRequired,
    }),
  ).isRequired,
};

export default CourseMarquee;
