// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./images/chatBubbleDeco.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./images/chatBubbleTipLeft.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./images/chatBubbleTipRight.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "._9USTB{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;width:394px;width:24.625rem;height:186px;height:11.625rem}._1tVc0{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}._1GEaD{display:-ms-flexbox;display:flex;grid-gap:16px;gap:16px;grid-gap:1rem;gap:1rem;-ms-flex-pack:center;justify-content:center;width:100%}.zwz8K{position:relative}.zwz8K:before{content:\"\";position:absolute;bottom:-16px;bottom:-1rem;width:42px;height:26px}.zwz8K:first-child{margin-top:3.125rem;background-color:#62a8fd}.zwz8K:first-child:before{content:\"\";right:24px;right:1.5rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.zwz8K:last-child:before{left:32px;left:2rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"outerLayer": "_9USTB",
	"showBackground": "_1tVc0",
	"innerLayer": "_1GEaD",
	"dialog": "zwz8K"
};
module.exports = exports;
