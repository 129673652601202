import { useContext } from 'react';

import ApplicationContext from 'components/ApplicationContext';

const useCurrentTriangleWithRoundInfo = () => {
  const {
    screenWidth: { isTablet, isDesktop, isWideDesktop },
  } = useContext(ApplicationContext);

  if (isWideDesktop) {
    return {
      width: 826,
      height: 962,
      strokeWidth: 120,
    };
  }

  if (isDesktop) {
    return {
      width: 496,
      height: 578,
      strokeWidth: 100,
    };
  }

  if (isTablet) {
    return {
      width: 670,
      height: 780,
      strokeWidth: 120,
    };
  }

  return {
    width: 296,
    height: 344,
    strokeWidth: 60,
  };
};

export default useCurrentTriangleWithRoundInfo;
