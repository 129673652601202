// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Ke0w{margin:0 auto}@media(min-width:1440px){._3Ke0w{max-width:79rem;width:100%;padding-left:7.5rem;padding-right:7.5rem}}@media(min-width:1024px)and (max-width:1439px){._3Ke0w{max-width:73rem;width:100%;padding-left:4.5rem;padding-right:4.5rem}}@media(min-width:768px)and (max-width:1023px){._3Ke0w{max-width:69rem;width:100%;padding-left:2.5rem;padding-right:2.5rem}}@media(max-width:767px){._3Ke0w{max-width:67rem;width:100%;padding-left:1.5rem;padding-right:1.5rem}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"container": "_3Ke0w"
};
module.exports = exports;
