// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1j_CU{width:100%;padding:1.5rem 2rem;border-radius:1.5rem;display:-ms-flexbox;display:flex;-ms-flex-pack:end;justify-content:flex-end;cursor:pointer}._3Y8jq{background:-webkit-gradient(linear,right top,left top,from(#e0eeff),color-stop(65.22%,rgba(224,238,255,0)));background:-o-linear-gradient(right,#e0eeff 0,rgba(224,238,255,0) 65.22%);background:linear-gradient(270deg,#e0eeff,rgba(224,238,255,0) 65.22%)}._3AGAx{background:-webkit-gradient(linear,right top,left top,from(#ebebfe),color-stop(65.22%,rgba(235,235,254,0)));background:-o-linear-gradient(right,#ebebfe 0,rgba(235,235,254,0) 65.22%);background:linear-gradient(270deg,#ebebfe,rgba(235,235,254,0) 65.22%)}._26UoP{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._1BQUF{margin-right:.5rem;font-size:24px;font-size:1.5rem;font-weight:500;line-height:30px;line-height:1.875rem;font-family:var(--heading-font);letter-spacing:var(--letter-spacing)}@media(max-width:767px){._1BQUF{font-size:1.25rem;line-height:1.5625rem}}._39wbo{width:32px;width:2rem;height:32px;height:2rem;-webkit-transform:rotate(90deg);-ms-transform:rotate(90deg);transform:rotate(90deg)}._39wbo path{fill:#000}", ""]);
// Exports
exports.locals = {
	"root": "_1j_CU",
	"blue": "_3Y8jq",
	"purple": "_3AGAx",
	"link": "_26UoP",
	"text": "_1BQUF",
	"arrow": "_39wbo"
};
module.exports = exports;
