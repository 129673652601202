import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from 'hooks/useStyles';
import { fetchAcademyList } from 'reduxModules/home/action';
import { getAcademyPostList } from 'reduxModules/home/selector';

import RecommendedSection from './components/RecommendedSection';
import PostIntroCard from './components/PostIntroCard';
import styles from './AcademySection.scss';

const AcademySection = () => {
  useStyles(styles);
  const { t } = useTranslation(['landingPage']);
  const dispatch = useDispatch();
  const postList = useSelector(getAcademyPostList);

  useEffect(() => {
    dispatch(fetchAcademyList());
  }, [dispatch]);

  if (postList.length === 0) {
    return null;
  }

  return (
    <div className={styles.root}>
      <h6 className={styles.sectionName}>
        {t('home.academySection.subtitle')}
      </h6>
      <h3 className={styles.title}>{t('home.academySection.title')}</h3>
      <div className={styles.postList}>
        {postList.map(
          ({
            id,
            mediaImageUrl,
            title,
            introText,
            author,
            publishAt,
            type,
          }) => (
            <PostIntroCard
              key={id}
              id={id}
              imageUrl={mediaImageUrl}
              title={title}
              description={introText}
              author={author.userName}
              publishAt={publishAt}
              isVideo={type === 'video'}
            />
          ),
        )}
      </div>
      <RecommendedSection />
    </div>
  );
};

export default AcademySection;
