import React, { useEffect, useState, useMemo } from 'react';
import { bool, shape, func } from 'prop-types';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';
import useElementVisibility from 'hooks/useElementVisibility';
import { COVER, REGULAR_CLASS, COURSE, QA } from '../../constants';

import { CoverTopSection, CoverPhoneLayer } from './components/CoverSection';
import SlidesSection, { SlidesBackground } from './components/SlidesSection';

import coverSectionStyles from './components/CoverSection/CoverSection.scss';
import styles from './HeroSection.scss';

const HERO_OFFERING_SECTION_LIST = [REGULAR_CLASS, COURSE, QA];

const HeroSection = ({ offeringMap, onAutoPlayEnd }) => {
  useStyles(styles, coverSectionStyles);
  const [rootRef, rootVisible] = useElementVisibility(true);
  const [currentSection, setCurrentSection] = useState(COVER);

  const isCover = currentSection === COVER;
  const sectionRotate = {
    [COVER]: 0,
    [REGULAR_CLASS]: '-90deg',
    [COURSE]: '-180deg',
    [QA]: '-270deg',
  };

  const sectionBackgroundColor = {
    [COVER]: '#195BDB',
    [REGULAR_CLASS]: '#195BDB',
    [COURSE]: '#3C3CAA',
    [QA]: '#19A3A3',
  };

  // to ensure ordering by HERO_OFFERING_SECTION_LIST
  const hasOfferingSection = useMemo(
    () => HERO_OFFERING_SECTION_LIST.filter(offering => offeringMap[offering]),
    [offeringMap],
  );

  const sectionList = [COVER, ...hasOfferingSection];

  useEffect(() => {
    const currentSectionIndex = hasOfferingSection.indexOf(currentSection);

    if (currentSectionIndex > -1) {
      const timer = setTimeout(() => {
        const nextSectionIndex = hasOfferingSection[currentSectionIndex + 1];
        if (!rootVisible) {
          return;
        }

        if (nextSectionIndex) {
          setCurrentSection(nextSectionIndex);
          return;
        }

        onAutoPlayEnd();
      }, 5000);

      return () => clearTimeout(timer);
    }

    return () => {};
  }, [hasOfferingSection, currentSection, onAutoPlayEnd, rootVisible]);

  const handleClickPhone = () =>
    hasOfferingSection.length > 0 && setCurrentSection(hasOfferingSection[0]);

  return (
    <div ref={rootRef} className={styles.root}>
      <CoverTopSection
        titleSectionClassName={cx({
          [coverSectionStyles.hideTitleSection]: !isCover,
        })}
      />
      <div
        style={{
          '--background-color': sectionBackgroundColor[currentSection],
        }}
        className={cx(styles.current, {
          [coverSectionStyles.phoneMask]: isCover,
        })}
      >
        <SlidesBackground
          className={cx({ [styles.hide]: isCover })}
          sectionList={sectionList}
          currentSection={currentSection}
        />
        <div
          style={{
            '--rotate': sectionRotate[currentSection],
          }}
          className={cx(styles.earth, {
            [coverSectionStyles.earth]: isCover,
          })}
        />
        {!isCover && (
          <SlidesSection
            sectionList={sectionList}
            currentSection={currentSection}
            onClickSlidesController={setCurrentSection}
          />
        )}
        <CoverPhoneLayer
          className={cx({ [styles.hide]: !isCover })}
          onClick={handleClickPhone}
        />
      </div>
    </div>
  );
};

HeroSection.propTypes = {
  offeringMap: shape({
    [REGULAR_CLASS]: bool,
    [COURSE]: bool,
    [QA]: bool,
  }).isRequired,
  onAutoPlayEnd: func.isRequired,
};

export default HeroSection;
