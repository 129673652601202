import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';
import useElementHaveSeen from 'hooks/useElementHaveSeen';
import BaseSwiper from 'components/BaseSwiper';

import commonHorizontalPaddingStyles from '../../../../commonHorizontalPadding.scss';
import ChattingAnimation from '../../../LearningSection/components/ChattingAnimation';

import styles from './QASwiper.scss';

const QASwiper = () => {
  useStyles(commonHorizontalPaddingStyles, styles);
  const { t } = useTranslation(['landingPage']);
  const [swiperRef, swiperHaveSeen] = useElementHaveSeen(false);
  const i18nKey = 'home.qaSection.featureExplanation';
  const slideContentName = [
    { id: 'snapPhoto' },
    { id: 'chatting' },
    { id: 'problemSolved' },
  ];

  useEffect(() => {
    if (swiperHaveSeen) {
      swiperRef.current.swiper.autoplay.start();
      return;
    }
    swiperRef.current.swiper.autoplay.stop();
  }, [swiperRef, swiperHaveSeen]);

  const renderSlide = ({ id }, index) => (
    <div className={styles.slide}>
      <div className={styles.titleSection}>
        <h3 className="section-index">{index + 1}</h3>
        <h2
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: t(`${i18nKey}.${index}.title`),
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: t(`${i18nKey}.${index}.description`),
          }}
        />
      </div>
      <div className={cx(styles.content, styles[id])}>
        {id === 'chatting' && <ChattingAnimation />}
      </div>
    </div>
  );

  // Ref: https://swiperjs.com/demos#pagination-custom
  const renderPaginationBullet = (index, className) => {
    return `<div class="${className}"><h3 class='section-index'>${index +
      1}</h3><h4 class='section-title'>${t(
      `${i18nKey}.${index}.title`,
    )}</h4></div>`;
  };

  return (
    <BaseSwiper
      id="banner-section-swiper"
      swiperRef={swiperRef}
      classNameMap={{
        root: cx(commonHorizontalPaddingStyles.container, styles.root),
      }}
      slideDataList={slideContentName}
      renderSlide={renderSlide}
      pagination={{
        renderBullet: renderPaginationBullet,
      }}
      autoplay={{
        delay: 5000,
        stopOnLastSlide: true,
      }}
    />
  );
};

export default QASwiper;
