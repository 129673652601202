// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3qFxh{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:justify;justify-content:space-between;border-radius:3rem;overflow:hidden;-webkit-mask-image:-webkit-radial-gradient(#fff,#000);mask-image:-webkit-radial-gradient(#fff,#000);min-height:400px;min-height:25rem}@media(min-width:1024px){._3qFxh{grid-gap:2.25rem;gap:2.25rem}}@media(max-width:1023px){._3qFxh{grid-gap:2rem;gap:2rem}}._3eVLP{background-color:#e0eeff}._3VbYs{background-color:#ebebfe}._1B_6a{background-color:#d9fcfe}._2ErfZ{padding-top:.75rem;font-size:18px;font-weight:400;line-height:29px;font-family:var(--base-font)}._1euHf{padding:2rem 2rem 0}._3qC-V{max-width:100%;height:200px;height:12.5rem;overflow:hidden}.MIy9b{padding-bottom:.75rem;color:#595757}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "_3qFxh",
	"regularClass": "_3eVLP",
	"course": "_3VbYs",
	"qa": "_1B_6a",
	"description": "_2ErfZ",
	"titleSection": "_1euHf",
	"content": "_3qC-V",
	"tag": "MIy9b"
};
module.exports = exports;
