// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2qZR0{position:relative}._4h1NS{position:absolute;z-index:1;cursor:pointer;width:100%;height:100%}.UjVdX{display:block;position:relative;padding-bottom:53%;border-radius:.5rem;overflow:hidden;margin-bottom:1rem}._1ldgS{width:100%;height:400px;height:25rem}._ubTY{-o-object-fit:cover;object-fit:cover;width:100%;height:100%}._ubTY,._1nGvD{position:absolute}._1nGvD{bottom:0;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;width:56px;width:3.5rem;height:56px;height:3.5rem;background-color:rgba(35,24,21,.8)}._2PWyV{margin-bottom:.5rem;-webkit-line-clamp:2}.JBc_v,._2PWyV{display:-webkit-box;overflow:hidden;white-space:normal;-webkit-box-orient:vertical}.JBc_v{margin-bottom:.75rem;-webkit-line-clamp:3}._3cpdG{font-size:12px;font-weight:300;line-height:20px;font-family:var(--base-font)}@media(max-width:767px){._3cpdG{font-size:14px;line-height:23px}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "_2qZR0",
	"link": "_4h1NS",
	"imgMask": "UjVdX",
	"loading": "_1ldgS",
	"img": "_ubTY",
	"playerIcon": "_1nGvD",
	"title": "_2PWyV",
	"description": "JBc_v",
	"authorAndPublishAt": "_3cpdG"
};
module.exports = exports;
