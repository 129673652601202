import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { bool, node, string } from 'prop-types';

import useStyles from 'hooks/useStyles';

import styles from './Cover.scss';

const Cover = ({ nodeSelector, children, unstyled }) => {
  const [haveWindow, setHaveWindow] = useState(false);

  useStyles(styles);

  useEffect(() => {
    setHaveWindow(true);
  }, []);

  if (!haveWindow) {
    return null;
  }

  return createPortal(
    unstyled ? children : <div className={styles.root}>{children}</div>,
    document.querySelector(nodeSelector),
  );
};

Cover.propTypes = {
  nodeSelector: string.isRequired,
  children: node.isRequired,
  unstyled: bool,
};

Cover.defaultProps = {
  unstyled: false,
};

export default Cover;
