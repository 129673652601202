import React, { useContext } from 'react';

import { BaseLink } from '@snapask/common-components';

import ApplicationContext from 'components/ApplicationContext';
import history from '../../history';
import { bool } from 'prop-types';

const Link = ({ show, ...props }) => {
  const { pathname } = useContext(ApplicationContext);

  if (!show) return null;

  return <BaseLink pathname={pathname} history={history} {...props} />;
};

Link.defaultProps = {
  show: true,
};

Link.propTypes = {
  show: bool,
};

export default Link;
