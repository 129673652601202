// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1otEU{background-color:#5656e2}.CGKUv{-ms-flex-positive:1;flex-grow:1}._1a5qI{display:-ms-flexbox;display:flex;grid-gap:32px;gap:32px;grid-gap:2rem;gap:2rem;max-width:1024px;max-width:64rem;margin:0 auto;color:#fff;-ms-flex-align:center;align-items:center}@media(max-width:1439px){._1a5qI{-ms-flex-direction:column;flex-direction:column}}@media(max-width:767px){._1a5qI{-ms-flex-align:start;align-items:start;padding:0 1.5rem}}._3Gfr3{display:-ms-flexbox;display:flex;grid-gap:56px;gap:56px;grid-gap:3.5rem;gap:3.5rem}@media(min-width:768px){._3Gfr3{text-align:center}}@media(max-width:767px){._3Gfr3{-ms-flex-direction:column;flex-direction:column;grid-gap:1.5rem;gap:1.5rem}}._2H9GX{margin-bottom:.5rem;font-size:56px;font-weight:500;line-height:70px;font-family:var(--heading-font);letter-spacing:.5px}@media(max-width:767px){._2H9GX{font-size:40px;line-height:50px}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "_1otEU",
	"title": "CGKUv",
	"container": "_1a5qI",
	"state": "_3Gfr3",
	"stateValue": "_2H9GX"
};
module.exports = exports;
