// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2jYr6{padding:1rem;border:1px solid #dcdddd;border-radius:.5rem;background-color:#fff;font-size:14px;font-weight:400;line-height:23px;font-family:var(--base-font)}@media(max-width:767px){._2jYr6{font-size:16px;line-height:26px}}._2jYr6:after{content:\"\";background-image:-webkit-gradient(linear,left bottom,left top,from(#f7f8f8),to(rgba(0,0,0,0)));background-image:-o-linear-gradient(bottom,#f7f8f8 0,rgba(0,0,0,0) 100%);background-image:linear-gradient(0deg,#f7f8f8 0,rgba(0,0,0,0));position:absolute;bottom:0;right:0;width:100%;height:82px;height:5.125rem}._2jYr6 li+li,._2jYr6 ul+ul{padding-top:1rem}._1RJXl li+li{padding-top:.5rem}._1RJXl>li>svg{margin:0 .25rem}._2RmEe{display:-ms-flexbox;display:flex;grid-gap:12px;gap:12px;grid-gap:.75rem;gap:.75rem;font-size:14px;font-weight:300;line-height:23px;font-family:var(--base-font)}@media(max-width:767px){._2RmEe{font-size:16px;line-height:26px}}._18FWv{width:24px;width:1.5rem}.pZts5{width:calc(100% - 2.25rem)}.wUr_Y{z-index:1;padding:1rem 1.5rem;background:#fff;-webkit-box-shadow:0 0 1.5rem .75rem #fff;box-shadow:0 0 1.5rem .75rem #fff;position:absolute;bottom:0;right:0;width:100%;height:82px;height:5.125rem}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "_2jYr6",
	"meta": "_1RJXl",
	"item": "_2RmEe",
	"img": "_18FWv",
	"title": "pZts5",
	"button": "wUr_Y"
};
module.exports = exports;
