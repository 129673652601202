import React, { useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import cx from 'classnames';
import LazyLoad from 'react-lazyload';
import useStyles from 'hooks/useStyles';
import useGetCurrentImageType from 'hooks/useGetCurrentImageType';
import useGetCurrentDevice from 'hooks/useGetCurrentDevice';

import { fetchBannerList } from 'reduxModules/homeRevamp/action';
import { getBannerList } from 'reduxModules/homeRevamp/selector';
import { sendGTMEvent } from 'reduxModules/googleTagManager/action';

import BaseSwiper from 'components/BaseSwiper';
import Link from 'components/Link';
import Button from 'components/Button';
import LoadingSkeleton from 'components/LoadingSkeleton';
import LoadedImage from 'components/LoadedImage';

import { HOME_BANNER_CLICK } from 'constants/gtmEvent';

import { TABLET_WIDTH } from 'styles/variables.scss';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './BannerSection.scss';

const BannerSection = forwardRef(({ className }, ref) => {
  useStyles(styles);
  const { isMobile } = useGetCurrentDevice();
  const dispatch = useDispatch();
  const bannerList = useSelector(getBannerList);

  const { imageType, setContainerRef } = useGetCurrentImageType(true);

  useEffect(() => {
    dispatch(fetchBannerList());
  }, [dispatch]);

  const breakpoints = {
    [TABLET_WIDTH]: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
  };

  const handleClick = ({ id, name }) => {
    dispatch(
      sendGTMEvent({
        eventName: HOME_BANNER_CLICK,
        data: {
          bannerId: id,
          bannerTitle: name,
        },
      }),
    );
  };

  const renderSlide = ({
    id,
    ctaLink,
    ctaText,
    title,
    name,
    description,
    mobileImage,
    image,
  }) =>
    ctaLink ? (
      <Link
        newTab
        key={id}
        to={ctaLink}
        className={styles.slide}
        data-ooo-image-container
        onClick={() => handleClick({ id, name })}
      >
        <div className={styles.mask} />
        <div className={styles.info}>
          <h2 className={styles.title}>{title}</h2>
          <span className={styles.description}>{description}</span>
          {ctaText && <Button className={styles.button} text={ctaText} />}
        </div>
        <LazyLoad
          height={118}
          offset={[-118, 0]}
          placeholder={<LoadingSkeleton className={styles.loading} />}
        >
          <LoadedImage
            className={styles.img}
            alt={title || null}
            src={isMobile ? mobileImage[imageType] : image[imageType]}
          />
        </LazyLoad>
      </Link>
    ) : (
      <div className={styles.slide} data-ooo-image-container>
        <div className={styles.mask} />
        <div className={styles.info}>
          <h2 className={styles.title}>{title}</h2>
          <span className={styles.description}>{description}</span>
          {ctaText && <Button className={styles.button} text={ctaText} />}
        </div>
        <LazyLoad
          height={118}
          offset={[-118, 0]}
          placeholder={<LoadingSkeleton className={styles.loading} />}
        >
          <LoadedImage
            className={styles.img}
            alt={title || null}
            src={isMobile ? mobileImage[imageType] : image[imageType]}
          />
        </LazyLoad>
      </div>
    );

  const setRef = element => {
    // eslint-disable-next-line no-param-reassign
    ref.current = element;
    setContainerRef(element);
  };

  if (bannerList.length === 0) {
    return null;
  }

  return (
    <div ref={setRef} className={cx(styles.root, className)}>
      {bannerList.length === 1 ? (
        <div className={styles.singleSlideWrapper}>
          {bannerList.map(renderSlide)}
        </div>
      ) : (
        <BaseSwiper
          id="home-section-swiper"
          classNameMap={{
            navigation: styles.navigation,
            navigationButton: styles.navigationButton,
            pagination: styles.pagination,
          }}
          navigationType="normal"
          spaceBetween={0}
          slideDataList={bannerList}
          renderSlide={renderSlide}
          breakpoints={breakpoints}
          pagination={{
            type: 'bullets',
            bulletClass: styles.bullet,
            bulletActiveClass: styles.bulletActive,
          }}
          autoplay={{
            delay: 5000,
          }}
          navigation
        />
      )}
    </div>
  );
});

BannerSection.propTypes = {
  className: string.isRequired,
};

BannerSection.displayName = 'BannerSection';

export default BannerSection;
