import React, { useState, useEffect, useRef, Fragment } from 'react';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import useStyles from 'hooks/useStyles';

import AutoPlayCircleProgressBar from './components/AutoPlayCircleProgressBar';

import styles from './AutoPlayController.scss';

const AutoPlayController = ({ nextVideoTitle, playNextVideo }) => {
  const [toPlayNextVideo, setToPlayNextVideo] = useState(false);
  const [stopAutoPlay, setStopAutoPlay] = useState(false);
  const timerRef = useRef();

  useStyles(styles);
  const { t } = useTranslation(['common']);

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      setToPlayNextVideo(true);
    }, 10000);

    return () => clearTimeout(timerRef.current);
  }, []);

  useEffect(() => {
    if (toPlayNextVideo) {
      playNextVideo();
    }
  }, [toPlayNextVideo, playNextVideo]);

  useEffect(() => {
    if (stopAutoPlay) {
      clearTimeout(timerRef.current);
    }
  }, [stopAutoPlay]);

  const handlePlayNextClick = () => {
    setToPlayNextVideo(true);
  };

  const handleCancel = () => {
    setStopAutoPlay(true);
  };

  return (
    <Fragment>
      <div className={styles.caption}>
        {t('common.videoPlayer.nextVideo.next')}
      </div>
      <h6 className={styles.title}>{nextVideoTitle}</h6>
      <div className={styles.progressBarWrapper} onClick={handlePlayNextClick}>
        <AutoPlayCircleProgressBar stopAutoPlay={stopAutoPlay} />
      </div>
      {!stopAutoPlay && (
        <div className={styles.cancel} onClick={handleCancel}>
          {t('common.videoPlayer.nextVideo.cancel')}
        </div>
      )}
    </Fragment>
  );
};

AutoPlayController.propTypes = {
  nextVideoTitle: string.isRequired,
  playNextVideo: func.isRequired,
};

export default AutoPlayController;
