import React from 'react';
import { string } from 'prop-types';

import useStyles from 'hooks/useStyles';

import { ReactComponent as QuestionMarkIcon } from './questionMark.svg';
import styles from './QuestionMark.scss';

const QuestionMark = ({ color }) => {
  useStyles(styles);

  return <QuestionMarkIcon className={styles.root} fill={color} />;
};

QuestionMark.propTypes = {
  color: string.isRequired,
};

export default QuestionMark;
