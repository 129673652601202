// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._31d8X{position:relative;display:-ms-flexbox;display:flex;grid-gap:12px;gap:12px;grid-gap:.75rem;gap:.75rem;width:676px;width:42.25rem;-webkit-transform:translateX(100%);-ms-transform:translateX(100%);transform:translateX(100%);-webkit-transition:-webkit-transform .7s ease-in;transition:-webkit-transform .7s ease-in;-o-transition:transform .7s ease-in;transition:transform .7s ease-in;transition:transform .7s ease-in,-webkit-transform .7s ease-in}@media(min-width:768px)and (max-width:1023px){._31d8X{margin:0 auto}}._3NRso{width:160px;width:10rem;height:160px;height:10rem;font-size:80px!important;font-size:5rem!important;line-height:160px!important;line-height:10rem!important;text-align:center;border-radius:50%;color:#fff;background-color:#c0c0f7;font-size:80px;font-size:5rem;font-weight:500;line-height:92px;line-height:5.75rem;font-family:var(--heading-font);letter-spacing:.5px}@media(max-width:1023px){._3NRso{font-size:3.5rem;line-height:3.75rem}}._3NRso:nth-child(3){background-color:#5656e2}._144VI ._3NRso:nth-child(3){-webkit-animation:_15Yjh .75s ease-in;animation:_15Yjh .75s ease-in}@media(min-width:1024px){._144VI{-webkit-transform:translateX(-5rem);-ms-transform:translateX(-5rem);transform:translateX(-5rem)}}@media(min-width:768px)and (max-width:1023px){._144VI{-webkit-transform:translateX(calc(95vw - 42.25rem));-ms-transform:translateX(calc(95vw - 42.25rem));transform:translateX(calc(95vw - 42.25rem))}}@media(max-width:767px){._144VI{-webkit-transform:translateX(calc(95vw - 32.25rem));-ms-transform:translateX(calc(95vw - 32.25rem));transform:translateX(calc(95vw - 32.25rem))}}@-webkit-keyframes _15Yjh{0%{background-color:#c0c0f7}90%{background-color:#c0c0f7}to{background-color:#5656e2}}@keyframes _15Yjh{0%{background-color:#c0c0f7}90%{background-color:#c0c0f7}to{background-color:#5656e2}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "_31d8X",
	"item": "_3NRso",
	"moveIn": "_144VI",
	"changeColor": "_15Yjh"
};
module.exports = exports;
