import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bool, object } from 'prop-types';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';

import {
  HOME_GRADE_LEVEL_FILTER_CLICK,
  HOME_COURSE_GROUP_CLICK,
  HOME_SUBJECT_CLICK,
  HOME_SCROLL_DEPTH,
} from 'constants/gtmEvent';
import { sendGTMEvent } from 'reduxModules/googleTagManager/action';
import {
  clearRegularClassLessonList,
  fetchRegularClassLessonList,
  fetchGroupedCourseList,
  fetchCourseList,
  setFilterInfo,
  clearFilterInfo,
} from 'reduxModules/homeRevamp/action';
import {
  getFilterInfo,
  getRegularClassLesson,
  getCourse,
} from 'reduxModules/homeRevamp/selector';

import { buildQuery, getQueryiesObject, clearQuery } from 'utils/query';
import TrackVisibility from 'components/TrackVisibility';
import LevelSelector from 'components/LevelSelector';
import SquareWithRound from '../SquareWithRound';
import HollowCircle from './components/HollowCircle';
import RegularClassList from './components/RegularClassList';
import CourseList from './components/CourseList';
import CourseGroup from './components/CourseGroup';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './IcRcSection.scss';

const IcRcSection = ({ shouldShowRc, shouldShowIc, rcRef, icRef }) => {
  useStyles(styles);
  const dispatch = useDispatch();
  const {
    gradeLevels,
    courseGroups,
    selectedGradeLevel,
    selectedSubject,
    selectedCourseGroup,
  } = useSelector(getFilterInfo);
  const {
    lessonList: regularClassList,
    totalPages: regularClassTotalPages,
  } = useSelector(getRegularClassLesson);
  const { courseList, totalPages: courseTotalPages } = useSelector(getCourse);

  const getCourseList = () => {
    if (shouldShowIc) dispatch(fetchCourseList());
  };

  const getGroupedCourseList = () => {
    if (shouldShowIc) dispatch(fetchGroupedCourseList());
  };

  const getRegularClassList = () => {
    if (shouldShowRc) dispatch(fetchRegularClassLessonList());
  };

  const handleOnLevelChange = (value, option) => {
    if (selectedGradeLevel.id !== value[0]) {
      dispatch(
        setFilterInfo({
          selectedGradeLevel: { id: value[0], name: option.label },
          selectedSubject: { id: 0, name: '' },
          selectedCourseGroup: { id: 0, name: '' },
        }),
      );
      getRegularClassList();

      if (value[0] !== 0) {
        dispatch(
          sendGTMEvent({
            eventName: HOME_GRADE_LEVEL_FILTER_CLICK,
            data: {
              gradeLevelId: value[0],
              gradeLevelName: option.label,
            },
          }),
        );
      }
    } else if (selectedSubject.id !== value[1]) {
      dispatch(
        setFilterInfo({
          selectedSubject: { id: value[1], name: option.label },
          selectedCourseGroup: { id: 0, name: '' },
        }),
      );

      if (value[1] !== 0) {
        dispatch(
          sendGTMEvent({
            eventName: HOME_SUBJECT_CLICK,
            data: {
              subjectId: value[1],
              subjectName: option.label,
            },
          }),
        );
      }
    }
    getRegularClassList();
    getCourseList();
  };

  const handleOnCourseGroupChange = (value, option) => {
    if (selectedCourseGroup !== value) {
      dispatch(
        setFilterInfo({
          selectedCourseGroup: {
            id: value,
            name: option?.label || '',
          },
        }),
      );
      if (value === 0) {
        getRegularClassList();
        getCourseList();
      } else {
        dispatch(
          sendGTMEvent({
            eventName: HOME_COURSE_GROUP_CLICK,
            data: {
              courseGroupId: value,
              courseGroupName: option.label,
            },
          }),
        );
        dispatch(clearRegularClassLessonList());
        getGroupedCourseList();
      }
    }
  };

  useEffect(() => {
    const queryiesObject = getQueryiesObject();
    const groupIdString = queryiesObject.groupId;
    const subjectIdString = queryiesObject.subjectId;
    const gradeLevelIdString = queryiesObject.gradeLevelId;
    const groupId = parseInt(groupIdString, 10);
    const subjectId = parseInt(subjectIdString, 10);
    const gradeLevelId = parseInt(gradeLevelIdString, 10);
    const filterInfo = {};

    if (groupId) {
      filterInfo.selectedCourseGroup = {
        id: groupId,
        name: courseGroups.find(({ id }) => id === groupId)?.name || '',
      };
    }
    if (gradeLevelId) {
      filterInfo.selectedGradeLevel = {
        id: gradeLevelId,
        name: gradeLevels.find(({ id }) => id === gradeLevelId)?.name || '',
      };
    }
    if (subjectId) {
      filterInfo.selectedSubject = {
        id: subjectId,
        name:
          gradeLevels.find(({ subjects }) =>
            subjects.find(({ id }) => id === subjectId),
          )?.name || '',
      };
    }

    dispatch(setFilterInfo(filterInfo));
  }, []);

  useEffect(() => {
    clearQuery(['groupId', 'subjectId', 'gradeLevelId']);

    if (selectedCourseGroup.id !== 0) {
      buildQuery('groupId', selectedCourseGroup.id);
    }
    if (selectedSubject.id !== 0) {
      buildQuery('subjectId', selectedSubject.id);
    }
    if (selectedGradeLevel.id !== 0) {
      buildQuery('gradeLevelId', selectedGradeLevel.id);
    }
  }, [selectedCourseGroup, selectedSubject, selectedGradeLevel]);

  useEffect(() => {
    return () => {
      dispatch(clearFilterInfo());
    };
  }, []);

  return (
    <section className={styles.root}>
      <HollowCircle color="#E0EEFF" />
      <SquareWithRound
        className={styles.squareWithRound}
        color="#EBEBFE"
        size={341}
        radius={80}
      />
      <TrackVisibility id="filter" eventName={HOME_SCROLL_DEPTH}>
        <LevelSelector
          onChange={handleOnLevelChange}
          className={{
            selectGroup: styles.levelSelectorSelect,
            filterCard: styles.levelSelectorFilter,
          }}
          shrinkWhenMaxCount={false}
          customShrinkWidthLimit={1024}
          gradeLevelList={gradeLevels}
          value={[selectedGradeLevel.id, selectedSubject.id]}
        />
      </TrackVisibility>
      <CourseGroup
        onChange={handleOnCourseGroupChange}
        onCancel={() => handleOnCourseGroupChange(0)}
        className={cx(styles.courseGroup, {
          [styles.shiftCourseGroup]: selectedGradeLevel.id !== 0,
        })}
        value={selectedCourseGroup.id}
        groups={courseGroups}
      />

      {shouldShowRc && regularClassList.length > 0 && (
        <TrackVisibility id="rc_listing" eventName={HOME_SCROLL_DEPTH}>
          <div ref={rcRef}>
            <RegularClassList
              selectedSubject={selectedSubject}
              regularClassList={regularClassList}
              hasMore={regularClassTotalPages > 1}
            />
          </div>
        </TrackVisibility>
      )}

      {shouldShowIc && courseList.length > 0 && (
        <TrackVisibility id="ic_listing" eventName={HOME_SCROLL_DEPTH}>
          <div ref={icRef}>
            <CourseList
              courseList={courseList}
              hasMore={courseTotalPages > 1}
            />
          </div>
        </TrackVisibility>
      )}
    </section>
  );
};

IcRcSection.propTypes = {
  shouldShowRc: bool.isRequired,
  shouldShowIc: bool.isRequired,
  rcRef: object.isRequired,
  icRef: object.isRequired,
};

export default IcRcSection;
