import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  getFetchSiteInfoState,
  getFetcHomehBannerListState,
  getFetcHomehRegularClassLessonListState,
  getFetchHomeCourseListState,
  getFetchHomeAcademyListState,
} from 'reduxModules/actionStatus/selector';

const useLoading = () => {
  const [loading, setLoading] = useState(true);

  const fetchSiteInfoState = useSelector(getFetchSiteInfoState);
  const fetchBannerListState = useSelector(getFetcHomehBannerListState);
  const fetchRegularClassLessonListState = useSelector(
    getFetcHomehRegularClassLessonListState,
  );
  const fetchCourseListState = useSelector(getFetchHomeCourseListState);
  const fetchAcademyListState = useSelector(getFetchHomeAcademyListState);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (
      fetchSiteInfoState.isReady &&
      !fetchBannerListState.isLoading &&
      !fetchRegularClassLessonListState.isLoading &&
      !fetchCourseListState.isLoading &&
      !fetchAcademyListState.isLoading
    ) {
      // Wait for DOM render
      const timeout = setTimeout(() => setLoading(false), 1500);

      return () => clearTimeout(timeout);
    }
  }, [
    fetchSiteInfoState,
    fetchBannerListState,
    fetchRegularClassLessonListState,
    fetchCourseListState,
    fetchAcademyListState,
  ]);

  return loading;
};

export default useLoading;
