import React, {
  forwardRef,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useImperativeHandle,
} from 'react';
import { bool, string, func, number } from 'prop-types';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { useSelector } from 'react-redux';

import config from 'config';
import useStyles from 'hooks/useStyles';
import { getUserExternalId } from 'reduxModules/user/selector';

import Cover from '../Cover';
import PlayButton from './components/PlayButton';
import Controller from './components/Controller';
import ShortcutTooltip from './components/ShortcutTooltip';
import AutoPlayController from './components/AutoPlayController';

import {
  PLAYER_RATE_MAP,
  PLAYER_RATE_LIST,
  REWIND,
  FORWARD,
  SHORTCUT,
  CAPTION,
  SHORTCUT_MENU_LIST,
  CONTROLLER_SHORTCUT_INFO_MAP,
} from './constants';
import styles from './BrightcovePlayer.scss';

const { BRIGHTCOVE_ACCOUNT_ID, BRIGHTCOVE_PLAYER_ID } = config;

const hideUnusedRate = playbackRateMenuButtonRef => {
  playbackRateMenuButtonRef.items.forEach(playerRateRef => {
    if (PLAYER_RATE_MAP[playerRateRef.rate] === undefined) {
      playerRateRef.addClass(styles.hide);
    }
  });
};

const BrightcovePlayer = forwardRef(
  (
    {
      className,
      videoId,
      caption,
      muted,
      autoplay,
      onReady,
      onPlay,
      onPause,
      onEnd,
      onSeek,
      onLeave,
      onFullscreenchange,
      onLoaded,
      onPlaying,
      onVolumechange,
      onProgressDragStart,
      onProgressDragEnd,
      onForward,
      onBackward,
      initialSeconds,
      showAutoPlayController,
      nextVideoTitle,
      playNextVideo,
      autoFocus,
      isSimpleVersion,
      shouldHidePlayButton,
    },
    externalRef,
  ) => {
    useStyles(styles);
    const playerRef = useRef(null);
    const userExternalId = useSelector(getUserExternalId);
    const seekType = useRef('');
    const [hover, setHover] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [isPause, setIsPause] = useState(false);
    const [currentRate, setCurrentRate] = useState(PLAYER_RATE_MAP[1]);
    const [captionMenuOpen, setCaptionMenuOpen] = useState(false);
    const [
      triggerCustomizedController,
      setTriggerCustomizedController,
    ] = useState({ trigger: false, name: '' });

    const showCover = showAutoPlayController || isPause;

    const handlePlay = () => playerRef.current.play();
    const handlePause = () => playerRef.current.pause();
    const handleSeek = seconds => playerRef.current.currentTime(seconds);
    const handleMute = () => playerRef.current.muted(true);
    const handleUnmute = () => playerRef.current.muted(false);
    const handleRequestFullscreen = () => playerRef.current.requestFullscreen();

    useImperativeHandle(externalRef, () => ({
      handlePlay,
      handlePause,
      handleSeek,
      handleMute,
      handleUnmute,
      handleRequestFullscreen,
      playerRef,
    }));

    // [condition, renderComponent]
    const coverChildrenInfoList = [
      [
        showAutoPlayController,
        () => (
          <AutoPlayController
            nextVideoTitle={nextVideoTitle}
            playNextVideo={playNextVideo}
          />
        ),
      ],
      [isPause, () => <PlayButton onClick={handlePlay} />],
    ];

    const seekForward = () => {
      if (typeof onForward === 'function')
        onForward({ currentTime: playerRef.current.currentTime() });

      handleSeek(playerRef.current.currentTime() + 15);
      seekType.current = FORWARD;
    };

    const seekBackward = () => {
      if (typeof onBackward === 'function')
        onBackward({ currentTime: playerRef.current.currentTime() });

      handleSeek(playerRef.current.currentTime() - 15);
      seekType.current = REWIND;
    };

    const customizedControllerInfoMap = {
      [REWIND]: {
        onClick: seekBackward,
        tooltipPlacement: 'left',
        shortcutList: [{ text: '←' }],
      },
      [FORWARD]: {
        onClick: seekForward,
        shortcutList: [{ text: '→' }],
      },
      [SHORTCUT]: {
        list: SHORTCUT_MENU_LIST,
        shortcutList: [{ text: '/' }],
        hideInMobile: true,
      },
      [CAPTION]: {
        content: caption,
      },
    };

    const speedUpPlayback = isLoop => {
      setCurrentRate(prevCurrentRate => {
        const nextRateIndex = PLAYER_RATE_MAP[prevCurrentRate] + 1;
        if (nextRateIndex >= PLAYER_RATE_LIST.length) {
          if (isLoop) {
            return PLAYER_RATE_LIST[0];
          }
          return prevCurrentRate;
        }
        return PLAYER_RATE_LIST[nextRateIndex];
      });
    };

    const hotkeyMap = useMemo(
      () => ({
        ArrowUp: () =>
          playerRef.current.controlBar.volumePanel.volumeControl.volumeBar.stepForward(),
        ArrowDown: () =>
          playerRef.current.controlBar.volumePanel.volumeControl.volumeBar.stepBack(),
        ArrowLeft: seekBackward,
        ArrowRight: seekForward,
        '+': speedUpPlayback,
        '=': speedUpPlayback,
        '-': () => {
          setCurrentRate(prevCurrentRate => {
            const nextRateIndex = PLAYER_RATE_MAP[prevCurrentRate] - 1;
            if (nextRateIndex < 0) {
              return prevCurrentRate;
            }
            return PLAYER_RATE_LIST[nextRateIndex];
          });
        },
        '/': () => {
          setTriggerCustomizedController({
            trigger: true,
            name: SHORTCUT,
          });
        },
      }),
      [],
    );

    const onKeyDown = useCallback(
      event => {
        if (hotkeyMap[event.key]) {
          event.preventDefault();
          event.stopPropagation();
          hotkeyMap[event.key]();
        }
      },
      [hotkeyMap],
    );

    const playerEventListenerMap = useMemo(
      () => ({
        loadeddata: () => {
          // index 0 is the caption setting, but we don't need it so hide.
          // index 1 is caption off option
          if (playerRef.current.controlBar.subsCapsButton?.items[2]) {
            playerRef.current.controlBar.subsCapsButton.items[2].track.mode =
              'showing';
          }

          if (initialSeconds && initialSeconds !== 0) {
            handleSeek(initialSeconds);
          }

          if (autoplay) {
            handlePlay();
          }

          if (typeof onLoaded === 'function')
            onLoaded({
              currentTime: initialSeconds,
            });
        },
        play: () => {
          onPlay({ currentTime: playerRef.current.currentTime() });
          setIsPause(false);
        },
        pause: () => {
          onPause({ currentTime: playerRef.current.currentTime() });
          setIsPause(true);
        },
        ended: () => {
          onEnd({ currentTime: playerRef.current.currentTime() });
        },
        volumechange: onVolumechange,
        fullscreenchange: onFullscreenchange,
        seeked: () => {
          if (typeof onSeek === 'function')
            onSeek({
              currentTime: playerRef.current.currentTime(),
              seekType: seekType.current,
            });

          seekType.current = '';
        },
        timeupdate: () =>
          typeof onPlaying === 'function' &&
          onPlaying({ currentTime: playerRef.current.currentTime() }),
        keydown: onKeyDown,
      }),
      [
        onPlay,
        onPause,
        onEnd,
        onSeek,
        onVolumechange,
        onFullscreenchange,
        onLoaded,
        onPlaying,
        onKeyDown,
        initialSeconds,
        autoplay,
      ],
    );

    const controlPlayerEventListener = useCallback(
      eventListenerAction => {
        Object.keys(playerEventListenerMap).forEach(actionName => {
          if (
            playerRef.current &&
            typeof playerEventListenerMap[actionName] === 'function'
          ) {
            playerRef.current[eventListenerAction](
              actionName,
              playerEventListenerMap[actionName],
            );
          }
        });
      },
      [playerEventListenerMap],
    );

    const toggleCaptionMenu = () => {
      setCaptionMenuOpen(prevCaptionMenuOpen => {
        document[
          prevCaptionMenuOpen ? 'removeEventListener' : 'addEventListener'
          // eslint-disable-next-line no-use-before-define
        ]('click', handleOutsideClick);

        return !prevCaptionMenuOpen;
      });
    };

    let handleOutsideClick = e => {
      // eslint-disable-next-line no-underscore-dangle
      if (!playerRef.current.controlBar.subsCapsButton.el_.contains(e.target)) {
        toggleCaptionMenu();
      }
    };

    const resetTriggerCustomizedController = () =>
      setTriggerCustomizedController({ trigger: false, name: '' });

    const onPlaybackRateButtonClick = () => speedUpPlayback(true);

    const handleProgressDragStart = useCallback(() => {
      if (typeof onProgressDragStart === 'function')
        onProgressDragStart({ currentTime: playerRef.current.currentTime() });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleProgressDragEnd = useCallback(() => {
      if (typeof onProgressDragEnd === 'function')
        onProgressDragEnd({ currentTime: playerRef.current.currentTime() });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSuccess = ({ ref }) => {
      playerRef.current = ref;

      ref.controlBar.progressControl.seekBar.on(
        'slideractive',
        handleProgressDragStart,
      );

      ref.controlBar.progressControl.seekBar.on(
        'sliderinactive',
        handleProgressDragEnd,
      );

      // menu of mouse right key
      ref.contextmenuUI({
        content: [],
      });

      controlPlayerEventListener('on');
      playerRef.current.bcAnalytics.client.setUser(userExternalId);
      playerRef.current.addClass('brightcovePlayerRoot');
      if (typeof onReady === 'function') {
        onReady(ref);
      }

      playerRef.current.controlBar.volumePanel.volumeControl.removeClass(
        'vjs-volume-vertical',
      );
      playerRef.current.controlBar.playbackRateMenuButton.on(
        'click',
        onPlaybackRateButtonClick,
      );
      if (playerRef.current.controlBar.subsCapsButton) {
        playerRef.current.controlBar.subsCapsButton.on(
          'click',
          toggleCaptionMenu,
        );
      }

      hideUnusedRate(playerRef.current.controlBar.playbackRateMenuButton);
      setLoaded(true);
      if (autoFocus) {
        playerRef.current.focus();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
      () => () => {
        controlPlayerEventListener('off');

        playerRef.current.controlBar.playbackRateMenuButton.off(
          'click',
          onPlaybackRateButtonClick,
        );

        playerRef.current.controlBar.progressControl.seekBar.off(
          'slideractive',
          handleProgressDragStart,
        );

        playerRef.current.controlBar.progressControl.seekBar.off(
          'sliderinactive',
          handleProgressDragEnd,
        );

        if (playerRef.current.controlBar.subsCapsButton) {
          playerRef.current.controlBar.subsCapsButton.off(
            'click',
            toggleCaptionMenu,
          );
        }
      },
      [],
    );

    useEffect(() => {
      if (playerRef.current) {
        playerRef.current.playbackRate(currentRate);
      }
    }, [currentRate]);

    useEffect(() => {
      return () => {
        if (typeof onLeave === 'function' && playerRef.current) {
          onLeave({ currentTime: playerRef.current.currentTime() });
        }
      };
    }, [onLeave]);

    useEffect(() => {
      if (playerRef.current) {
        playerRef.current.textTrackDisplay[
          showAutoPlayController ? 'addClass' : 'removeClass'
        ](styles.textTrackDisplay);
      }
    }, [showAutoPlayController]);

    useEffect(() => {
      if (!hover) {
        setCaptionMenuOpen(false);
      }
    }, [hover]);

    useEffect(() => {
      if (playerRef.current?.controlBar.subsCapsButton && !captionMenuOpen) {
        playerRef.current.controlBar.subsCapsButton.unpressButton();
      }
    }, [captionMenuOpen]);

    return (
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <ReactPlayerLoader
          attrs={{ className }}
          accountId={BRIGHTCOVE_ACCOUNT_ID}
          videoId={videoId}
          playerId={BRIGHTCOVE_PLAYER_ID}
          onSuccess={onSuccess}
          options={{
            muted,
            autoplay,
            aspectRatio: '16:9',
            controlBar: {
              pictureInPictureToggle: false,
            },
          }}
        />
        {showCover && (
          <Cover nodeSelector=".brightcovePlayerRoot">
            {coverChildrenInfoList.find(([condition]) => condition)[1]()}
          </Cover>
        )}
        {loaded && !shouldHidePlayButton && (
          <Cover nodeSelector=".vjs-big-play-button">
            <PlayButton onClick={handlePlay} />
          </Cover>
        )}
        {!isSimpleVersion &&
          Object.entries(customizedControllerInfoMap).map(
            ([name, props]) =>
              loaded && (
                <Controller
                  key={name}
                  name={name}
                  hoverInPlayer={hover}
                  triggerCustomizedController={triggerCustomizedController}
                  resetTriggerCustomizedController={
                    resetTriggerCustomizedController
                  }
                  {...props}
                />
              ),
          )}
        {!isSimpleVersion &&
          Object.entries(CONTROLLER_SHORTCUT_INFO_MAP).map(
            ([controllerClassName, props]) =>
              loaded && (
                <Cover
                  key={controllerClassName}
                  unstyled
                  nodeSelector={`.${controllerClassName}`}
                >
                  <ShortcutTooltip
                    key={controllerClassName}
                    controllerClassName={controllerClassName}
                    {...props}
                  />
                </Cover>
              ),
          )}
      </div>
    );
  },
);

BrightcovePlayer.propTypes = {
  videoId: string.isRequired,
  className: string,
  caption: string,
  muted: bool,
  autoplay: bool,
  onPlay: func,
  onPause: func,
  onEnd: func,
  onLeave: func,
  onReady: func,
  onSeek: func,
  onVolumechange: func,
  onFullscreenchange: func,
  onLoaded: func,
  onPlaying: func,
  onProgressDragStart: func,
  onProgressDragEnd: func,
  initialSeconds: number,
  showAutoPlayController: bool,
  nextVideoTitle: string,
  playNextVideo: func,
  autoFocus: bool,
  isSimpleVersion: bool,
  shouldHidePlayButton: bool,
  onForward: func,
  onBackward: func,
};

BrightcovePlayer.defaultProps = {
  className: '',
  muted: false,
  caption: '',
  autoplay: true,
  onPlay: null,
  onPause: null,
  onEnd: null,
  onLeave: null,
  onReady: null,
  onSeek: null,
  onVolumechange: null,
  onFullscreenchange: null,
  onLoaded: null,
  onPlaying: null,
  initialSeconds: 0,
  showAutoPlayController: false,
  nextVideoTitle: '',
  playNextVideo: null,
  autoFocus: true,
  isSimpleVersion: false,
  shouldHidePlayButton: false,
  onProgressDragStart: () => {},
  onProgressDragEnd: () => {},
  onForward: () => {},
  onBackward: () => {},
};

export default BrightcovePlayer;
