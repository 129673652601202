// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3dm1p{position:absolute;-webkit-transform:rotate(30deg);-ms-transform:rotate(30deg);transform:rotate(30deg)}._3dm1p,._1esr7{width:var(--size);height:var(--size)}._1esr7{x:0;y:0;rx:var(--radius);ry:var(--radius);fill:var(--fill)}", ""]);
// Exports
exports.locals = {
	"root": "_3dm1p",
	"shape": "_1esr7"
};
module.exports = exports;
