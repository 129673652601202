// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1_F-o{-webkit-box-shadow:0 4px 32px rgba(98,168,253,.08);box-shadow:0 4px 32px rgba(98,168,253,.08);border-radius:24px;min-height:108px;min-height:6.75rem;height:auto;background:#fff;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-line-pack:center;align-content:center;padding:1.5rem}._23TRm,._1_F-o{width:100%;display:-ms-flexbox;display:flex}._23TRm{-ms-flex-align:baseline;align-items:baseline}.rBJnn{position:relative;display:inline-block;font-weight:400;font-size:16px;font-size:1rem;line-height:26px;line-height:1.625rem;color:#595757;padding-right:.75rem}.rBJnn:after{content:\"\";position:absolute;top:50%;right:0;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);display:block;width:1px;height:16px;background:#dcdddd}._2y_ii,._3uIV4{display:inline-block;padding:0 0 0 .75rem;font-weight:600;font-size:16px;font-size:1rem;font-weight:500;line-height:20px;line-height:1.25rem;font-family:var(--heading-font);letter-spacing:var(--letter-spacing)}._3uIV4{line-height:33px;line-height:2.0625rem}._2-mdm{cursor:pointer}._2-mdm:not(:last-child){margin-right:.75rem}.KpAGJ{color:#0373f9}", ""]);
// Exports
exports.locals = {
	"root": "_1_F-o",
	"filter": "_23TRm",
	"title": "rBJnn",
	"options": "_2y_ii",
	"subOptions": "_3uIV4",
	"option": "_2-mdm",
	"optionSelected": "KpAGJ"
};
module.exports = exports;
