// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".XKAgi{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;grid-gap:10px;gap:10px;grid-gap:.625rem;gap:.625rem;width:136px;width:8.5rem;height:82px;height:5.125rem;border-radius:3rem;background-color:#0373f9}._3y5IE{width:20px;width:1.25rem;height:20px;height:1.25rem;border-radius:50%;background-color:#fff;-webkit-animation:MV2uJ var(--keyframes-time) ease-in-out infinite alternate;animation:MV2uJ var(--keyframes-time) ease-in-out infinite alternate}._3y5IE:first-child{-webkit-animation-delay:calc(var(--keyframes-time)/3);animation-delay:calc(var(--keyframes-time)/3)}._3y5IE:nth-child(2){-webkit-animation-delay:calc(var(--keyframes-time)/1.5);animation-delay:calc(var(--keyframes-time)/1.5)}@-webkit-keyframes MV2uJ{0%{-webkit-transform:translateY(0);transform:translateY(0)}to{-webkit-transform:translateY(-70%);transform:translateY(-70%)}}@keyframes MV2uJ{0%{-webkit-transform:translateY(0);transform:translateY(0)}to{-webkit-transform:translateY(-70%);transform:translateY(-70%)}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "XKAgi",
	"point": "_3y5IE",
	"wave": "MV2uJ"
};
module.exports = exports;
