// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".YdDac{display:-ms-flexbox;display:flex;grid-gap:16px;gap:16px;grid-gap:1rem;gap:1rem;opacity:.5}._204XB{opacity:1}._38YsV{position:relative;width:270px;width:16.875rem;max-height:537px;max-height:33.5625rem;-ms-flex-negative:0;flex-shrink:0}._3KpAj{position:absolute;width:100%;z-index:1}._1cWm9{position:absolute;width:calc(100% - 1.875rem);left:15px;left:.9375rem;top:8px;top:.5rem;border-radius:20px;z-index:0}.A05a7{position:relative;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-positive:1;flex-grow:1;max-height:537px;max-height:33.5625rem;background:hsla(0,0%,100%,.8);-webkit-box-shadow:0 .25rem 2rem rgba(98,168,253,.078);box-shadow:0 .25rem 2rem rgba(98,168,253,.078);border-radius:24px;padding:1.5rem}._28hkX{margin-bottom:.5rem;font-size:24px;font-size:1.5rem;font-weight:500;line-height:30px;line-height:1.875rem;font-family:var(--heading-font);letter-spacing:var(--letter-spacing)}@media(max-width:767px){._28hkX{font-size:1.25rem;line-height:1.5625rem}}._6hAcD{color:#595757;font-weight:400}._3Mjbz,._6hAcD{font-size:16px;font-size:1rem;line-height:26px;line-height:1.625rem;font-family:var(--base-font)}._3Mjbz{-ms-flex-positive:1;flex-grow:1;max-height:350px;max-height:21.875rem;overflow-y:scroll;margin-top:.5rem;padding-bottom:2rem;font-weight:300}._1WPGT{padding-bottom:0}._1tuTa{display:-ms-flexbox;display:flex;grid-gap:16px;gap:16px;grid-gap:1rem;gap:1rem;border-top:1px solid #dcdddd;padding-top:.75rem}.YyBvx{width:54px;width:3.375rem;height:54px;height:3.375rem;border-radius:16px;-ms-flex:0 0;flex:0 0}._3Mqb4{font-weight:400}._3Mqb4,.Wx41y{font-size:16px;font-size:1rem;line-height:26px;line-height:1.625rem;font-family:var(--base-font)}.Wx41y{color:#595757;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-wrap:wrap;flex-wrap:wrap;font-weight:300}._2Lkhn{width:4px;width:.25rem;height:4px;height:.25rem;background:#595757;border-radius:50%;margin:0 .5rem}", ""]);
// Exports
exports.locals = {
	"root": "YdDac",
	"isActive": "_204XB",
	"screenshotContainer": "_38YsV",
	"device": "_3KpAj",
	"screenshot": "_1cWm9",
	"infoContainer": "A05a7",
	"quote": "_28hkX",
	"outcome": "_6hAcD",
	"content": "_3Mjbz",
	"contentExceeded": "_1WPGT",
	"userBlock": "_1tuTa",
	"avatar": "YyBvx",
	"class": "_3Mqb4",
	"student": "Wx41y",
	"dot": "_2Lkhn"
};
module.exports = exports;
