// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EC5Qv{position:relative;background-color:#fff;border-radius:5rem;overflow:hidden;max-width:1440px;max-width:90rem;width:100%;margin:0 auto}@media(max-width:767px){.EC5Qv{border-radius:1.5rem}}._2VCWL{position:relative}._2LhsJ{position:absolute}@media(min-width:1440px){._2LhsJ{top:-3.5rem}}@media(min-width:1024px)and (max-width:1439px){._2LhsJ{top:-3rem}}@media(min-width:768px)and (max-width:1023px){._2LhsJ{top:-3.5625rem}}@media(max-width:767px){._2LhsJ{top:-3rem}}.oSJ7w.vp7ra{top:960px;top:60rem;left:-240px;left:-15rem}@media(max-width:767px){.oSJ7w.vp7ra{display:none}}@media(min-width:1440px){.oSJ7w.Iy38z{top:41rem;right:-5rem}}@media(max-width:1439px){.oSJ7w.Iy38z{display:none}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "EC5Qv",
	"content": "_2VCWL",
	"triangleWithRound": "_2LhsJ",
	"SquareWithRound": "oSJ7w",
	"regularClass": "vp7ra",
	"course": "Iy38z"
};
module.exports = exports;
