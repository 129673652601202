import React from 'react';
import { bool, func, oneOf, string, node } from 'prop-types';
import cx from 'classnames';
import useStyles from 'hooks/useStyles';

/* eslint-disable css-modules/no-undef-class */
import styles from './Button.scss';

function Button({
  className,
  disabled,
  onClick,
  onMouseEnter,
  onMouseLeave,
  nativeType,
  text,
  outline,
  ghost,
  block,
  type,
  small,
  icon,
  link,
  reversed,
  children,
  show,
}) {
  useStyles(styles);

  if (!show) return null;

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={nativeType}
      className={cx(
        styles.root,
        {
          [styles.small]: small,
          [styles.outline]: outline,
          [styles.reversed]: reversed,
          [styles.link]: link,
          [styles.ghost]: ghost,
          [styles.block]: block,
          [styles.disabled]: disabled,
        },
        styles[type],
        className,
      )}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children ?? (
        <>
          {icon && <div className={styles.icon}>{icon}</div>}
          {text}
        </>
      )}
    </button>
  );
}

Button.propTypes = {
  show: bool,
  onClick: func,
  onMouseEnter: func,
  onMouseLeave: func,
  block: bool,
  children: node,
  icon: node,
  className: string,
  disabled: bool,
  small: bool,
  text: string,
  nativeType: oneOf(['button', 'submit', 'reset']),
  outline: bool,
  ghost: bool,
  link: bool,
  reversed: bool,
  type: oneOf(['primary', 'danger', 'dark']),
};

Button.defaultProps = {
  show: true,
  onClick: undefined,
  icon: undefined,
  className: '',
  block: false,
  small: false,
  disabled: false,
  text: '',
  nativeType: 'button',
  reversed: false,
  outline: false,
  ghost: false,
  link: false,
  type: 'primary',
  children: undefined,
  onMouseEnter: undefined,
  onMouseLeave: undefined,
};

export default Button;
