// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ErXF_{padding-bottom:.75rem}.V6DzY{font-size:80px;font-size:5rem;font-weight:500;line-height:92px;line-height:5.75rem;font-family:var(--heading-font);letter-spacing:.5px}@media(max-width:1023px){.V6DzY{font-size:3.5rem;line-height:3.75rem}}@media(min-width:768px){.V6DzY{padding-bottom:1.5rem}}@media(max-width:767px){.V6DzY{padding-bottom:1rem}}._1Gatx{color:#595757;font-size:18px;font-weight:400;line-height:29px;font-family:var(--base-font)}._1fbVD .ErXF_{color:#0373f9}._1XB7U .ErXF_{color:#5656e2}.wLlnw{text-align:center}.wLlnw .ErXF_{color:#03d8d8}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"subtitle": "ErXF_",
	"title": "V6DzY",
	"description": "_1Gatx",
	"regularClass": "_1fbVD",
	"course": "_1XB7U",
	"qa": "wLlnw"
};
module.exports = exports;
