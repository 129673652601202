import React, { useState } from 'react';
import { string, array, func } from 'prop-types';
import cx from 'classnames';
import { useTransition, animated, config } from '@react-spring/web';

import useStyles from 'hooks/useStyles';
import styles from './FilterCard.scss';

const FilterCard = ({
  options,
  mainTitle,
  subTitle,
  value: selectedValue,
  className,
  id,
  onChange,
}) => {
  useStyles(styles);
  const [currentSubOptions, setCurrentSubOptions] = useState(
    options.find(({ value }) => value === selectedValue[0])?.options,
  );

  const subOptionVisible =
    selectedValue[0] !== 0 && currentSubOptions?.length !== 0;

  const transitions = useTransition(subOptionVisible, {
    from: {
      opacity: 0,
      marginTop: '0rem',
    },
    enter: {
      opacity: 1,
      marginTop: '0.5rem',
    },
    leave: {
      opacity: 0,
      marginTop: '0rem',
    },
    config: config.stiff,
    immediate: !subOptionVisible,
  });

  const changeValue = (level, newValue, option) => {
    if (level === 0) {
      const subOptions = options.find(({ value }) => value === newValue)
        ?.options;
      setTimeout(
        () => {
          setCurrentSubOptions(subOptions);
        },
        subOptions ? 0 : 300,
      );
      onChange([newValue, 0], option);
    } else {
      onChange([selectedValue[0], newValue], option);
    }
  };

  return (
    <div className={cx(styles.root, className)} id={id}>
      <div className={styles.filter}>
        <h3 className={styles.title}>{mainTitle}</h3>
        <div className={styles.options}>
          {options.map(({ label, value }) => (
            <span
              key={value}
              className={cx(styles.option, {
                [styles.optionSelected]: value === selectedValue[0],
              })}
              onClick={() => changeValue(0, value, { label, value })}
            >
              {label}
            </span>
          ))}
        </div>
      </div>
      {transitions(
        (props, item) =>
          item && (
            <animated.div className={styles.filter} style={{ ...props }}>
              <h3 className={styles.title}>{subTitle}</h3>
              <div className={styles.subOptions}>
                {currentSubOptions?.map(({ label, value }) => (
                  <span
                    key={value}
                    className={cx(styles.option, {
                      [styles.optionSelected]: value === selectedValue[1],
                    })}
                    onClick={() => changeValue(1, value, { label, value })}
                  >
                    {label}
                  </span>
                ))}
              </div>
            </animated.div>
          ),
      )}
    </div>
  );
};

FilterCard.propTypes = {
  value: array,
  id: string,
  className: string,
  options: array.isRequired,
  mainTitle: string.isRequired,
  subTitle: string.isRequired,
  onChange: func.isRequired,
};

FilterCard.defaultProps = {
  value: [0, 0],
  id: '',
  className: '',
};

export default FilterCard;
