import React from 'react';
import Layout from 'components/Layout';
import { SNAPASK_SITE_ENDPOINT } from 'config';
import { getAppRegion } from 'reduxModules/app/selector';
import { getOfferingList } from 'reduxModules/siteInfo/selector';
import {
  fetchRegularClassLessonList,
  fetchGradeLevelFilterList,
  fetchGroupedCourseList,
  fetchCourseList,
  fetchBannerList,
} from 'reduxModules/homeRevamp/action';
import {
  fetchRegularClassLessonListFlow,
  fetchGradeLevelFilterListFlow,
  fetchCourseListFlow,
  fetchBannerListFlow,
  fetchGroupedCourseListFlow,
} from 'reduxModules/homeRevamp/saga';
import { getQueryiesObject } from 'utils/query';
import { convertSnakeToCamelCase } from 'utils/common';
import { HOME_REVAMP_LOCAL_LIST } from 'constants/locales';
import { REGULAR_CLASS, COURSE } from '../components/HomeRevamp/constants';
import HomeRevamp from '../components/HomeRevamp';
import HomeOriginal from '../components/HomeOriginal';

async function action(context) {
  const { store, i18n, pathname } = context;
  let groupId = null;
  let subjectId = null;
  let gradeLevelId = null;

  if (typeof window !== 'undefined') {
    const queryiesObject = getQueryiesObject();
    groupId = queryiesObject.groupId;
    subjectId = queryiesObject.subjectId;
    gradeLevelId = queryiesObject.gradeLevelId;
  }

  const { title, description, image } = i18n.t('seo.landing', {
    returnObjects: true,
  });
  const appRegion = getAppRegion(store.getState());
  const offeringList = getOfferingList(store.getState());
  const offeringMap = offeringList.reduce(
    (origin, offering) => ({
      ...origin,
      [convertSnakeToCamelCase(offering)]: true,
    }),
    {},
  );
  const isHomeRevamp = HOME_REVAMP_LOCAL_LIST.includes(appRegion);

  if (isHomeRevamp) {
    await store.runSagas(fetchBannerListFlow, fetchBannerList()).toPromise();
    const tempGroupId = parseInt(groupId, 10);
    const tempSubjectId = parseInt(subjectId, 10);
    const tempGradeLevelId = parseInt(gradeLevelId, 10);

    if (offeringMap[REGULAR_CLASS] || offeringMap[COURSE]) {
      store
        .runSagas(fetchGradeLevelFilterListFlow, fetchGradeLevelFilterList())
        .toPromise();
    }
    if (offeringMap[REGULAR_CLASS] && !tempGroupId) {
      store
        .runSagas(
          fetchRegularClassLessonListFlow,
          fetchRegularClassLessonList({
            tempGradeLevelId,
            tempSubjectId,
          }),
        )
        .toPromise();
    }

    if (offeringMap[COURSE]) {
      if (tempGroupId) {
        // to get the group list
        await store
          .runSagas(fetchCourseListFlow, fetchCourseList({}))
          .toPromise();
        // to get the course lists
        store
          .runSagas(
            fetchGroupedCourseListFlow,
            fetchGroupedCourseList({
              tempGradeLevelId,
              tempSubjectId,
              tempGroupId,
            }),
          )
          .toPromise();
      } else {
        store
          .runSagas(
            fetchCourseListFlow,
            fetchCourseList({
              tempGradeLevelId,
              tempSubjectId,
            }),
          )
          .toPromise();
      }
    }
  }

  return {
    title,
    description,
    ogTags: {
      title,
      description,
      image,
      type: 'website',
      url: `${SNAPASK_SITE_ENDPOINT}${pathname}`,
    },
    jsonLDList: [
      {
        '@context': 'https://schema.org',
        '@type': 'instructor',
        image,
        name: title,
        description,
        publisher: {
          '@type': 'Organization',
          name: 'Snapask',
        },
      },
    ],
    chunks: ['home'],
    component: (
      <Layout>{isHomeRevamp ? <HomeRevamp /> : <HomeOriginal />}</Layout>
    ),
  };
}

export default action;
