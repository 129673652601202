import React from 'react';
import cx from 'classnames';
import { string } from 'prop-types';

import useStyles from 'hooks/useStyles';

import styles from './LoadingSkeleton.scss';

export default function LoadingSkeleton({ className }) {
  useStyles(styles);
  return <span className={cx(styles.root, className)}>&#8203;</span>;
}

LoadingSkeleton.propTypes = {
  className: string,
};

LoadingSkeleton.defaultProps = {
  className: '',
};
