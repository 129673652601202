// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".hIc-e{position:absolute}@media(min-width:1440px){.hIc-e{top:-37.5rem;right:-37.5rem;width:75rem;height:75rem}.hIc-e ._1ZXAp{cx:37.5rem;cy:37.5rem;r:31.25rem;stroke-width:12.5rem;stroke:var(--stroke);fill:none}}@media(min-width:1024px)and (max-width:1439px){.hIc-e{top:-25rem;right:-25rem;width:50rem;height:50rem}.hIc-e ._1ZXAp{cx:25rem;cy:25rem;r:21.25rem;stroke-width:7.5rem;stroke:var(--stroke);fill:none}}@media(min-width:768px)and (max-width:1023px){.hIc-e{top:-17.5rem;right:-17.5rem;width:35rem;height:35rem}.hIc-e ._1ZXAp{cx:17.5rem;cy:17.5rem;r:13.75rem;stroke-width:7.5rem;stroke:var(--stroke);fill:none}}@media(max-width:767px){.hIc-e{top:-15rem;right:-15rem;width:30rem;height:30rem}.hIc-e ._1ZXAp{cx:15rem;cy:15rem;r:13.125rem;stroke-width:3.75rem;stroke:var(--stroke);fill:none}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "hIc-e",
	"shape": "_1ZXAp"
};
module.exports = exports;
