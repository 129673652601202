import React from 'react';
import { number, oneOf, string, shape } from 'prop-types';

import { useDispatch } from 'react-redux';

import BaseContentIntroCard from 'components/BaseContentIntroCard';
import useGetOfficialSiteURL from 'hooks/useGetOfficialSiteURL';

import { sendGTMEvent } from 'reduxModules/googleTagManager/action';

import {
  COURSE_INFORMATION_TEMPLATE,
  LIVE_TOPIC,
  REGULAR_CLASS_TYPE,
  COURSE_TYPE,
} from 'constants/routingPath';
import { HOME, INSTRUCTOR } from 'constants/pageTypes';
import {
  INSTRUCTOR_COURSE_CLICK,
  REGULAR_CLASS_TOPIC_CLICK,
  HOME_COURSEFEATURE_COURSE_CLICK,
} from 'constants/gtmEvent';

function ContentIntroCard({
  contentType,
  pageType,
  id,
  section,
  serialNumber,
  instructor,
  ...prop
}) {
  const dispatch = useDispatch();

  const getOfficialSiteURL = useGetOfficialSiteURL();
  const CONTENT = {
    PATH: {
      [COURSE_TYPE]: COURSE_INFORMATION_TEMPLATE,
      [REGULAR_CLASS_TYPE]: LIVE_TOPIC,
    },
    EVENT_NAME: {
      [COURSE_TYPE]: {
        [INSTRUCTOR]: INSTRUCTOR_COURSE_CLICK,
        [HOME]: HOME_COURSEFEATURE_COURSE_CLICK,
      },
      [REGULAR_CLASS_TYPE]: {
        [INSTRUCTOR]: REGULAR_CLASS_TOPIC_CLICK,
        [HOME]: REGULAR_CLASS_TOPIC_CLICK,
      },
    },
    GET_EVENT_DATA: {
      [COURSE_TYPE]: course => ({
        courseId: id,
        courseName: course.title,
        totalLessonDuration: course.totalLessonsDuration,
        totalLesson: course.lessonsAmount,
        tag: course.tag,
        section,
      }),
      [REGULAR_CLASS_TYPE]: topic => {
        return {
          topicId: id,
          topicName: topic.title,
          instructorId: instructor.id || topic.instructorIdList,
          instructorName: instructor.name || topic.instructorNameList,
          itemIndex: serialNumber,
          section: section || pageType,
        };
      },
    },
  };

  const handleClick = () => {
    dispatch(
      sendGTMEvent({
        eventName: CONTENT.EVENT_NAME[contentType][pageType],
        data: CONTENT.GET_EVENT_DATA[contentType](prop),
      }),
    );
  };

  return (
    <BaseContentIntroCard
      pageUrl={getOfficialSiteURL(CONTENT.PATH[contentType], {
        id,
      })}
      onClick={handleClick}
      {...prop}
    />
  );
}

ContentIntroCard.propTypes = {
  contentType: oneOf([COURSE_TYPE, REGULAR_CLASS_TYPE]).isRequired,
  pageType: oneOf([HOME, INSTRUCTOR]).isRequired,
  serialNumber: number.isRequired,
  id: number.isRequired,
  section: string,
  instructor: shape({
    id: number,
    name: string,
  }),
};

ContentIntroCard.defaultProps = {
  section: '',
  instructor: {},
};

export default ContentIntroCard;
