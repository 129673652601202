import React from 'react';
import { bool } from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { BaseFooter } from '@snapask/common-components';
import { USER_LNG } from '@snapask/common-components/dist/constants/cookieNames';

import Link from 'components/Link';
import { HOME_DOWNLOAD_CLICK } from 'constants/gtmEvent';
import { ANDROID, IOS } from 'constants/appDownloadType';
import Cookie from 'utils/cookie';

import useGetOfficialSiteURL from 'hooks/useGetOfficialSiteURL';
import useGetAcademyURL from 'hooks/useGetAcademyURL';

import { push } from 'reduxModules/navigation/action';
import { fetchSiteInfo } from 'reduxModules/siteInfo/action';
import { changeLocale } from 'reduxModules/app/action';

import { getAppLocale, getAppRegion } from 'reduxModules/app/selector';
import { getUserRegion } from 'reduxModules/user/selector';
import {
  getZendeskFAQLink,
  getOfferingList,
  getMultipleOfferingMode,
  getShowLiveInstructorBtn,
} from 'reduxModules/siteInfo/selector';
import { sendGTMEvent } from 'reduxModules/googleTagManager/action';

function SnapaskFooter({ showSnapaskInfo }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['navigation', 'footer']);
  const getOfficialSiteURL = useGetOfficialSiteURL();
  const getAcademyURL = useGetAcademyURL();

  const currentLocale = useSelector(getAppLocale);
  const userRegion = useSelector(getUserRegion);
  const appRegion = useSelector(getAppRegion);

  const zendeskFAQLink = useSelector(getZendeskFAQLink);
  const offeringList = useSelector(getOfferingList);
  const multipleOfferingMode = useSelector(getMultipleOfferingMode);
  const showInstructorBtn = useSelector(getShowLiveInstructorBtn);

  const onSelectedLocale = selectedLocale => {
    const newPath = window.location.pathname.replace(
      currentLocale,
      selectedLocale,
    );
    dispatch(push(newPath));
    dispatch(changeLocale(selectedLocale));
    dispatch(fetchSiteInfo());
    i18n.changeLanguage(selectedLocale);
    Cookie.set(USER_LNG, selectedLocale);
  };

  const onIosDownloadClick = () => {
    dispatch(
      sendGTMEvent({
        eventName: HOME_DOWNLOAD_CLICK,
        data: {
          region: appRegion,
          type: IOS,
        },
      }),
    );
  };

  const onAndroidDownloadClick = () => {
    dispatch(
      sendGTMEvent({
        eventName: HOME_DOWNLOAD_CLICK,
        data: {
          region: appRegion,
          type: ANDROID,
        },
      }),
    );
  };

  return (
    <BaseFooter
      currentLocale={currentLocale}
      userRegion={userRegion}
      getAcademyURL={getAcademyURL}
      getOfficialSiteURL={getOfficialSiteURL}
      LinkComponent={Link}
      onIosDownloadClick={onIosDownloadClick}
      onAndroidDownloadClick={onAndroidDownloadClick}
      onSelectLocale={onSelectedLocale}
      zendeskFAQUrl={zendeskFAQLink}
      offerings={offeringList}
      multipleOfferingMode={multipleOfferingMode}
      showInstructorLink={showInstructorBtn}
      t={t}
      showSnapaskInfo={showSnapaskInfo}
    />
  );
}

SnapaskFooter.propTypes = {
  showSnapaskInfo: bool,
};

SnapaskFooter.defaultProps = {
  showSnapaskInfo: true,
};

export default SnapaskFooter;
