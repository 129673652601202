import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';
import { ACADEMY } from '../../../../constants';

import commonHorizontalPaddingStyles from '../../../../commonHorizontalPadding.scss';
import styles from './TitleSection.scss';

const TitleSection = ({ name }) => {
  useStyles(styles);
  const { t } = useTranslation(['landingPage']);
  const currentSectionI18nKey = `home.${name}Section`;

  if (name === ACADEMY) {
    return null;
  }

  return (
    <div className={cx(commonHorizontalPaddingStyles.container, styles[name])}>
      <h4 className={styles.subtitle}>
        {t(`${currentSectionI18nKey}.subtitle`)}
      </h4>
      <h2
        className={styles.title}
        dangerouslySetInnerHTML={{
          __html: t(`${currentSectionI18nKey}.title`),
        }}
      />
      <p
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: t(`${currentSectionI18nKey}.description`),
        }}
      />
    </div>
  );
};

TitleSection.propTypes = {
  name: string.isRequired,
};

export default TitleSection;
