// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Ic7B{position:absolute;top:-48px;top:-3rem;left:-145px;left:-9.0625rem}@media(max-width:767px){._3Ic7B{width:20.5rem;height:17.375rem;left:-5.5rem}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "_3Ic7B"
};
module.exports = exports;
