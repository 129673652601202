// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._37VEP{padding-top:5rem;padding-bottom:5rem}@media(min-width:1440px){._37VEP{padding-top:7.5rem;padding-bottom:7.5rem}}@media(max-width:767px){._37VEP{padding-top:3rem;padding-bottom:3rem}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"container": "_37VEP"
};
module.exports = exports;
