import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import LazyLoad from 'react-lazyload';

import ApplicationContext from 'components/ApplicationContext';
import LoadingSkeleton from 'components/LoadingSkeleton';

import useStyles from 'hooks/useStyles';
import { getAppRegion } from 'reduxModules/app/selector';
import { AU, NZ, SG } from 'constants/locales';

import Marquee from '../../../Marquee';

import styles from './TutorListContent.scss';

const TutorListContent = () => {
  useStyles(styles);
  const currentRegion = useSelector(getAppRegion);
  const tutorRegion =
    currentRegion === AU || currentRegion === NZ ? SG : currentRegion;
  const {
    screenWidth: { isMobile, isTablet },
  } = useContext(ApplicationContext);

  const renderTutorList = () =>
    new Array(9).fill('').map((item, index) => (
      <LazyLoad
        // eslint-disable-next-line react/no-array-index-key
        key={`tutor-list-${index}`}
        height={120}
        offset={[-120, 0]}
        className={styles.picture}
        placeholder={<LoadingSkeleton className={styles.loading} />}
      >
        <img
          src={`https://cdn-snapask-misc.snapask.com/home/tutor/${tutorRegion}/tutor${index}.jpg`}
          alt=""
        />
      </LazyLoad>
    ));

  return isMobile || isTablet ? (
    <Marquee
      classNameMap={{
        container: styles.root,
        marquee: cx(styles.root, styles.marquee),
      }}
      speed={60}
    >
      {renderTutorList()}
    </Marquee>
  ) : (
    <div className={styles.root}>{renderTutorList()}</div>
  );
};

export default TutorListContent;
