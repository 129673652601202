// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3usfD{position:relative}._3usfD .swiper-button-disabled{visibility:hidden}._3usfD .swiper-pagination{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;margin-top:2rem;grid-gap:2px;gap:2px;grid-gap:.125rem;gap:.125rem}._3usfD .swiper-pagination-bullet{cursor:pointer;width:20px;width:1.25rem;height:4px;height:.25rem;background-color:#dcdddd;border-radius:.25rem;-webkit-transition:all .3s cubic-bezier(.645,.045,.355,1);-o-transition:all .3s cubic-bezier(.645,.045,.355,1);transition:all .3s cubic-bezier(.645,.045,.355,1)}._3usfD .swiper-pagination-bullet-active{background-color:#9fa0a0}._1ISdm{position:relative}._1ISdm .swiper-button-prev{-webkit-transform:rotate(-180deg);-ms-transform:rotate(-180deg);transform:rotate(-180deg)}._3pCZ_{display:none;position:absolute;top:0;z-index:1;-ms-flex-align:center;align-items:center;height:100%;padding-right:1.5rem;background-image:-webkit-gradient(linear,right top,left top,color-stop(0,#f7f8f8),to(hsla(0,0%,100%,0)));background-image:-o-linear-gradient(right,#f7f8f8 0,hsla(0,0%,100%,0));background-image:linear-gradient(270deg,#f7f8f8 0,hsla(0,0%,100%,0))}@media(min-width:768px){._3pCZ_{display:-ms-flexbox;display:flex}}._3xZBZ{left:0}._14UtB{right:0}._1ISdm .swiper-button-next,._1ISdm .swiper-button-prev{background-color:#fff;-webkit-transition:all .3s cubic-bezier(.645,.045,.355,1);-o-transition:all .3s cubic-bezier(.645,.045,.355,1);transition:all .3s cubic-bezier(.645,.045,.355,1);border-width:0;-webkit-box-shadow:0 10px 20px 0 rgba(35,24,21,.2);box-shadow:0 10px 20px 0 rgba(35,24,21,.2);opacity:0;border-radius:50%;width:48px;width:3rem;height:48px;height:3rem}._1ISdm:hover .swiper-button-next,._1ISdm:hover .swiper-button-prev{opacity:1}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "_3usfD",
	"swiperWrapper": "_1ISdm",
	"navigation": "_3pCZ_",
	"prev": "_3xZBZ",
	"next": "_14UtB"
};
module.exports = exports;
