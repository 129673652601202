// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./images/PhoneNotch.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._2kZwF{width:100%;height:100%;background:no-repeat rgba(35,24,21,.56) url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% top}._2kZwF,.TDHzE{cursor:pointer;position:absolute;visibility:visible;opacity:1;-webkit-transition:visibility .5s,opacity .5s ease-in;-o-transition:visibility .5s,opacity .5s ease-in;transition:visibility .5s,opacity .5s ease-in}.TDHzE{top:56px;top:3.5rem;left:calc(50% - 3.75rem);display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;width:120px;width:7.5rem;height:120px;height:7.5rem;padding-left:.5rem;background-color:#fff;border-radius:50%}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"phoneGrayMask": "_2kZwF",
	"player": "TDHzE"
};
module.exports = exports;
