// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./images/helpOnDemand.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._1HTfs{min-height:1000px;min-height:62.5rem}@media(min-width:1024px){._1HTfs{grid-template-areas:\"allSubjects helpOnDemand\" \"tutors tutors\"}._1HTfs ._1J3bh{grid-area:allSubjects}._1HTfs ._2MSfX{grid-area:helpOnDemand}._1HTfs ._3_s5t{grid-area:tutors}}@media(min-width:1440px){._2cMPf{grid-template-columns:minmax(0,1fr) 32rem}}@media(min-width:1024px){._2cMPf{display:grid;-ms-flex-align:center;align-items:center;height:29.5rem;padding:2rem 0 2rem 5rem}}@media(min-width:1024px)and (max-width:1439px){._2cMPf{grid-template-columns:minmax(0,1fr) 27.5rem}}@media(min-width:1024px){.gD0or{padding:0}}@media(min-width:1024px){._3pFV1{height:100%}}@media(max-width:1023px){._3pFV1{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}}._6Ck0P{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;padding-top:1rem;text-transform:uppercase}._2mnLT{padding-top:.125rem}._2mnLT path[fill]{fill:#0373f9}.s5G8E{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat bottom}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"highlightSection": "_1HTfs",
	"allSubjects": "_1J3bh",
	"helpOnDemand": "_2MSfX",
	"tutors": "_3_s5t",
	"tutorsHighlight": "_2cMPf",
	"tutorsTitleSection": "gD0or",
	"tutorsContent": "_3pFV1",
	"link": "_6Ck0P",
	"icon": "_2mnLT",
	"helpOnDemandContent": "s5G8E"
};
module.exports = exports;
