import React, { useMemo, useContext } from 'react';
import { func, string, array } from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import useStyles from 'hooks/useStyles';
import ApplicationContext from 'components/ApplicationContext';
import Select from 'components/LevelSelector/components/Select';
import Pill from '../Pill';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './CourseGroup.scss';

const CourseGroup = ({
  groups,
  value: currentValue,
  className,
  onChange,
  onCancel,
}) => {
  useStyles(styles);
  const { t } = useTranslation(['landingPage']);
  const {
    screenWidth: { isMobile, isTablet },
  } = useContext(ApplicationContext);
  const ALL_GROUP_OPTION = { label: t('home.filter.courseGroupAll'), value: 0 };

  const groupOptions = useMemo(
    () =>
      groups.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [groups],
  );

  const handleOnClick = (value, option) => {
    onChange(value, option);
  };

  const handleOnCancel = () => {
    onCancel();
  };

  return (
    <div className={cx(styles.root, className)}>
      {isMobile || isTablet ? (
        <Select
          title={t('home.filter.courseGroup')}
          options={[ALL_GROUP_OPTION, ...groupOptions]}
          value={currentValue}
          onChange={(value, option) => handleOnClick(value, option)}
        />
      ) : (
        groupOptions.map(({ value, label }) => (
          <Pill
            key={value}
            text={label}
            value={value}
            isActive={currentValue === value}
            onClick={() => handleOnClick(value, { value, label })}
            onCancel={handleOnCancel}
          />
        ))
      )}
    </div>
  );
};

CourseGroup.propTypes = {
  groups: array.isRequired,
  value: string.isRequired,
  onChange: func.isRequired,
  onCancel: func.isRequired,
  className: string,
};

CourseGroup.defaultProps = {
  className: '',
};

export default CourseGroup;
