import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { BaseLocaleSelection } from '@snapask/common-components';
import {
  USER_LNG,
  HAS_CHOOSE_LOCALE,
} from '@snapask/common-components/dist/constants/cookieNames';

import Cookie from 'utils/cookie';

import { push } from 'reduxModules/navigation/action';
import { fetchSiteInfo } from 'reduxModules/siteInfo/action';
import { changeLocale } from 'reduxModules/app/action';

import { getAppLocale } from 'reduxModules/app/selector';
import { getUserIsAuthenticated } from 'reduxModules/user/selector';

function SnapaskLocaleSelection() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['navigation', 'onboarding']);
  const [open, setOpen] = useState(false);

  const currentLocale = useSelector(getAppLocale);
  const isLoggedIn = useSelector(getUserIsAuthenticated);

  useEffect(() => {
    const hasChooseLocale = Cookie.get(HAS_CHOOSE_LOCALE);
    if (!isLoggedIn && !hasChooseLocale) {
      setOpen(true);
    }
  }, [isLoggedIn]);

  const handleChangeLocale = selectedLocale => {
    const newPath = window.location.pathname.replace(
      currentLocale,
      selectedLocale,
    );
    dispatch(push(newPath));
    dispatch(changeLocale(selectedLocale));
    dispatch(fetchSiteInfo());
    i18n.changeLanguage(selectedLocale);
    Cookie.set(USER_LNG, selectedLocale);
    Cookie.set(HAS_CHOOSE_LOCALE, true);
    setOpen(false);
  };

  return (
    <BaseLocaleSelection
      open={open}
      currentLocale={currentLocale}
      onSelectLocale={handleChangeLocale}
      t={t}
    />
  );
}

export default SnapaskLocaleSelection;
