export const PLAYER_RATE_MAP = {
  0.5: 0,
  1: 1,
  1.25: 2,
  1.5: 3,
  2: 4,
};

export const PLAYER_RATE_LIST = [0.5, 1, 1.25, 1.5, 2];

export const REWIND = 'rewind';
export const FORWARD = 'forward';
export const SHORTCUT = 'shortcut';
export const CAPTION = 'caption';

export const SHORTCUT_MENU_LIST = [
  {
    text: [{ i18nKey: 'shortcutList.playPause' }, { i18nKey: 'playPauseHint' }],
  },
  { text: [{ i18nKey: 'shortcutList.volumeUp' }, { text: '↑' }] },
  {
    text: [{ i18nKey: 'shortcutList.volumeDown' }, { text: '↓' }],
  },
  { text: [{ i18nKey: `shortcutList.${FORWARD}` }, { text: '→' }] },
  {
    text: [{ i18nKey: `shortcutList.${REWIND}` }, { text: '←' }],
  },
  { text: [{ i18nKey: 'shortcutList.faster' }, { text: '+' }] },
  {
    text: [{ i18nKey: 'shortcutList.slower' }, { text: '-' }],
  },
];

export const CONTROLLER_SHORTCUT_INFO_MAP = {
  'vjs-play-control': {
    name: 'playPause',
    shortcutList: [{ i18nKey: 'playPauseHint' }],
  },
  'vjs-mute-control': {
    name: 'volume',
    shortcutList: [{ text: '↑' }, { text: '↓' }],
  },
  'vjs-fullscreen-control': {
    placement: 'right',
    name: 'fullscreen',
    shortcutList: [{ text: 'F' }],
  },
};
