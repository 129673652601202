/* eslint-disable no-unused-vars */
// TODO: wait backend picture ready
import { useCallback, useEffect, useRef, useState } from 'react';
import useGetCurrentDevice from 'hooks/useGetCurrentDevice';

const ORIGINAL = 'originalUrl';
const LARGE = 'largeUrl';
const MEDIUM = 'mediumUrl';
const THUMBNAIL = 'thumbUrl';
const NONE = 'none';

const ORIGINAL_LIMIT = 2880;
const LARGE_LIMIT = 1001;
const MEDIUM_LIMIT = 601;

const getImageType = width => {
  if (width > ORIGINAL_LIMIT) return ORIGINAL;
  if (width < ORIGINAL_LIMIT && width > LARGE_LIMIT) return LARGE;
  if (width < LARGE_LIMIT - 1 && width > MEDIUM_LIMIT) return MEDIUM;
  return THUMBNAIL;
};

const IMAGE_CONTAINER_SELECTOR = '[data-ooo-image-container]';

function useGetCurrentImageType(isSync = false) {
  const currentDevice = useGetCurrentDevice();
  const observerRef = useRef();
  const [containerNode, setContainerElement] = useState();
  const [currentImageType, setCurrentImageType] = useState(ORIGINAL);

  const setContainerRef = useCallback(node => {
    if (!!containerNode || !node) return;

    setContainerElement(node);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function changeImageType(targetElement) {
  //   if (!targetElement) return;
  //   const nextImageType = getImageType(targetElement.offsetWidth);
  //   setCurrentImageType(() => nextImageType);
  // }

  // useEffect(() => {
  //   (() => {
  //     if (observerRef.current || !containerNode || isSync) return;

  //     observerRef.current = new MutationObserver(eventList => {
  //       const event = eventList[0];

  //       const targetElement = event.target.querySelector(
  //         IMAGE_CONTAINER_SELECTOR,
  //       );

  //       changeImageType(targetElement);
  //     });

  //     observerRef.current.observe(containerNode, {
  //       childList: true,
  //       attributes: true,
  //       characterData: true,
  //     });
  //   })();

  //   return () => {
  //     if (observerRef.current) {
  //       observerRef.current.disconnect();
  //       observerRef.current = null;
  //     }
  //   };

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [containerNode]);

  // useEffect(() => {
  //   (() => {
  //     if ((!observerRef.current && !isSync) || !containerNode) return;

  //     const targetElement = containerNode.querySelector(
  //       IMAGE_CONTAINER_SELECTOR,
  //     );

  //     changeImageType(targetElement);
  //   })();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [...Object.values(currentDevice), containerNode]);

  return {
    imageType: currentImageType,
    setContainerRef,
  };
}

export default useGetCurrentImageType;
