// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1-Xze{position:absolute;-webkit-transform:rotate(30deg);-ms-transform:rotate(30deg);transform:rotate(30deg)}._1-Xze,._2gk3_{width:var(--size);height:var(--size)}._2gk3_{x:0;y:0;rx:var(--radius);ry:var(--radius);fill:var(--fill)}", ""]);
// Exports
exports.locals = {
	"root": "_1-Xze",
	"shape": "_2gk3_"
};
module.exports = exports;
