// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1b9MJ{display:none;position:absolute;bottom:48px;bottom:3rem;-ms-flex-direction:column;flex-direction:column;grid-gap:4px;gap:4px;grid-gap:.25rem;gap:.25rem;border-radius:.5rem;background-color:#231815;padding:.5rem .75rem;font-size:16px;font-size:1rem;font-weight:400;line-height:26px;line-height:1.625rem;font-family:var(--base-font)}._3JaKW{left:50%;-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%)}.nhqOL{right:0}._289K5{white-space:nowrap}.tDNQh{display:-ms-flexbox;display:flex;grid-gap:4px;gap:4px;grid-gap:.25rem;gap:.25rem;-ms-flex-pack:center;justify-content:center}._RD5n{padding:.25rem .5rem;background-color:#9fa0a0;border-radius:.25rem}", ""]);
// Exports
exports.locals = {
	"root": "_1b9MJ",
	"center": "_3JaKW",
	"right": "nhqOL",
	"text": "_289K5",
	"shortcutList": "tDNQh",
	"shortcut": "_RD5n"
};
module.exports = exports;
