export const REGULAR_CLASS = 'regularClass';
export const COURSE = 'course';
export const QA = 'qa';
export const ACADEMY = 'academy';
export const BANNER = 'banner';
export const TESTIMONIAL = 'testimonial';

export const ANCHOR_NAME_MAPPING = {
  [REGULAR_CLASS]: 'RC',
  [COURSE]: 'COURSE',
  [QA]: 'QA',
  [ACADEMY]: 'ACADEMY',
  [BANNER]: 'BANNER',
  [TESTIMONIAL]: 'TESTIMONIAL',
};

// for HeroSection
export const COVER = 'cover';
