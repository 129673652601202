// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1oFie{position:relative;width:calc(100% - 6rem);height:216px;height:13.5rem;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;background:-webkit-gradient(linear,right top,left top,color-stop(48.76%,#d9fcfe),to(rgba(217,252,254,0)));background:-o-linear-gradient(right,#d9fcfe 48.76%,rgba(217,252,254,0) 100%);background:linear-gradient(270deg,#d9fcfe 48.76%,rgba(217,252,254,0));border-radius:24px;margin:0 auto;padding:4.875rem 4.5rem 4.875rem 2rem}@media(max-width:1439px){._1oFie{padding:4.875rem 2rem}}@media(max-width:767px){._1oFie{-ms-flex-direction:column;flex-direction:column;width:calc(100% - 2rem);padding:2rem;text-align:center;-ms-flex-align:center;align-items:center;grid-gap:1.5rem;gap:1.5rem}}@media(min-width:1440px){._1oFie{max-width:78rem}}@media(max-width:1439px){._3Zzpb{width:20.1875rem}}@media(max-width:767px){._3Zzpb{width:100%}}.SfB98,._1y7Kg{font-size:24px;font-size:1.5rem;font-weight:500;line-height:30px;line-height:1.875rem;font-family:var(--heading-font);letter-spacing:var(--letter-spacing)}@media(max-width:767px){.SfB98,._1y7Kg{font-size:1.25rem;line-height:1.5625rem}}._2Fg1w{position:absolute;bottom:0;right:26.3%}@media(max-width:1023px){._2Fg1w{display:none}}._1cVxF{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;background-color:#03d8d8;padding:1rem 2rem;color:#fff;border-radius:24px;border:0;-ms-flex-negative:0;flex-shrink:0;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}._2QL3H{margin-right:.5rem;font-size:24px;font-size:1.5rem;font-weight:500;line-height:30px;line-height:1.875rem;font-family:var(--heading-font);letter-spacing:var(--letter-spacing)}@media(max-width:767px){._2QL3H{font-size:1.25rem;line-height:1.5625rem}}", ""]);
// Exports
exports.locals = {
	"root": "_1oFie",
	"info": "_3Zzpb",
	"description": "SfB98",
	"title": "_1y7Kg",
	"img": "_2Fg1w",
	"button": "_1cVxF",
	"buttonText": "_2QL3H"
};
module.exports = exports;
