// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".YNZlu{background-color:#5656e2}._39axL{-ms-flex-positive:1;flex-grow:1}._21w9R{display:-ms-flexbox;display:flex;grid-gap:32px;gap:32px;grid-gap:2rem;gap:2rem;max-width:1024px;max-width:64rem;margin:0 auto;color:#fff;-ms-flex-align:center;align-items:center}@media(max-width:1439px){._21w9R{-ms-flex-direction:column;flex-direction:column}}@media(max-width:767px){._21w9R{-ms-flex-align:start;align-items:start;padding:0 1.5rem}}._1uK7D{display:-ms-flexbox;display:flex;grid-gap:56px;gap:56px;grid-gap:3.5rem;gap:3.5rem}@media(min-width:768px){._1uK7D{text-align:center}}@media(max-width:767px){._1uK7D{-ms-flex-direction:column;flex-direction:column;grid-gap:1.5rem;gap:1.5rem}}._2brQz{margin-bottom:.5rem;font-size:56px;font-weight:500;line-height:70px;font-family:var(--heading-font);letter-spacing:.5px}@media(max-width:767px){._2brQz{font-size:40px;line-height:50px}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "YNZlu",
	"title": "_39axL",
	"container": "_21w9R",
	"state": "_1uK7D",
	"stateValue": "_2brQz"
};
module.exports = exports;
