import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import useStyles from 'hooks/useStyles';
import { CloseWhiteIcon, CheckWhiteIcon } from 'components/Icon';

import styles from './Pill.scss';

const Pill = ({ text, isActive, onClick, onCancel }) => {
  useStyles(styles);
  const [isHover, setIsHover] = useState(false);

  const handleOnCancel = e => {
    e.stopPropagation();
    onCancel();
  };

  const toggleHover = () => {
    setIsHover(!isHover);
  };

  return (
    <div
      className={styles.root}
      onClick={onClick}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
    >
      {(isActive || (isHover && !isActive)) && (
        <div className={styles.check}>
          <CheckWhiteIcon className={styles.icon} />
        </div>
      )}
      {isHover && isActive && (
        <div className={styles.close} onClick={handleOnCancel}>
          <CloseWhiteIcon className={styles.icon} />
        </div>
      )}
      <span className={styles.text}>{text}</span>
    </div>
  );
};

Pill.propTypes = {
  text: string.isRequired,
  isActive: bool.isRequired,
  onClick: func.isRequired,
  onCancel: func.isRequired,
};

export default Pill;
