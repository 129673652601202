import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from 'hooks/useStyles';
import useGetCurrentImageType from 'hooks/useGetCurrentImageType';
import { fetchRegularClassInstructorList } from 'reduxModules/home/action';
import { getHomeRegularClassInstructorList } from 'reduxModules/home/selector';
import BaseSwiper from 'components/BaseSwiper';
import {
  TABLET_WIDTH,
  DESKTOP_WIDTH,
  WIDE_DESKTOP_WIDTH,
} from 'styles/variables.scss';

import InstructorIntroCard from './components/InstructorIntroCard';

import commonSwiperStyles from '../../../commonSwiper.scss';
import styles from './InstructorsSection.scss';

const InstructorsSection = () => {
  useStyles(commonSwiperStyles, styles);
  const dispatch = useDispatch();
  const instructorList = useSelector(getHomeRegularClassInstructorList);
  const { imageType, setContainerRef } = useGetCurrentImageType(true);

  useEffect(() => {
    dispatch(fetchRegularClassInstructorList());
  }, [dispatch]);

  const breakpoints = {
    [WIDE_DESKTOP_WIDTH]: {
      slidesPerView: 3,
      slidesPerGroup: 1,
      spaceBetween: 32,
    },
    [DESKTOP_WIDTH]: {
      slidesPerView: 2.3,
      slidesPerGroup: 1,
      spaceBetween: 32,
    },
    [TABLET_WIDTH]: {
      slidesPerView: 2,
      slidesPerGroup: 1,
      spaceBetween: 32,
    },
    0: {
      slidesPerView: 1.2,
      slidesPerGroup: 1,
      spaceBetween: 24,
    },
  };

  const renderSlide = instructorInfo => {
    return (
      <InstructorIntroCard
        key={instructorInfo.id}
        {...instructorInfo}
        avatarUrl={instructorInfo.avatar[imageType]}
      />
    );
  };

  if (instructorList.length === 0) {
    return null;
  }

  return (
    <section ref={setContainerRef}>
      <BaseSwiper
        id="instructors-section-swiper"
        classNameMap={{
          root: commonSwiperStyles.root,
          navigation: styles.navigation,
        }}
        slideDataList={instructorList}
        renderSlide={renderSlide}
        breakpoints={breakpoints}
        navigation
      />
    </section>
  );
};

export default InstructorsSection;
