import React, { useState, useEffect, useMemo } from 'react';
import { string, bool, array, object, func, number } from 'prop-types';

import useBaseOnWindowSize from 'hooks/useBaseOnWindowSize';

import SelectGroup from './components/SelectGroup';
import FilterCard from './components/FilterCard';
import { useTranslation } from 'react-i18next';

const SHRINK_WIDTH_LIMIT = 768;
const FIRST_LEVEL_LIMIT = 9;
const SECOND_LEVEL_LIMIT = 15;

const addDefaultOptionList = (options, value, label) => [
  { value, label },
  ...options,
];

const transferGradeLevelToOptionList = (
  gradeLevelList,
  defaultValue,
  defaultLabel,
  subDefaultValue,
  subDefaultLabel,
) => {
  const createOptionList = (list, value, label) => {
    if (!list || list?.length === 0) return [];
    const options = list.map(({ id, name, subjects }) => {
      const subOptions = createOptionList(
        subjects,
        subDefaultValue,
        subDefaultLabel,
      );

      return {
        value: id,
        label: name,
        options: subOptions,
      };
    });

    return addDefaultOptionList(options, value, label);
  };

  return createOptionList(gradeLevelList, defaultValue, defaultLabel);
};

const checkIsShrinkMode = (
  gradeOptions,
  customShrinkWidthLimit,
  shrinkWhenMaxCount,
) => {
  if (gradeOptions.length === 0) return null;
  if (
    window.innerWidth < SHRINK_WIDTH_LIMIT ||
    (customShrinkWidthLimit && window.innerWidth < customShrinkWidthLimit) ||
    (shrinkWhenMaxCount && gradeOptions.length > FIRST_LEVEL_LIMIT)
  )
    return true;

  return (
    shrinkWhenMaxCount &&
    gradeOptions.some(({ options }) => {
      return options?.length > SECOND_LEVEL_LIMIT;
    })
  );
};

const LevelSelector = ({
  gradeLevelList,
  customShrinkWidthLimit,
  shrinkWhenMaxCount,
  className,
  ...props
}) => {
  const [isShrinkMode, setIsShrinkMode] = useState(null);
  const { t } = useTranslation(['common']);

  const gradeOptions = useMemo(() => {
    const options = transferGradeLevelToOptionList(
      gradeLevelList,
      0,
      t('common.filter.gradeAll'),
      0,
      t('common.filter.subjectAll'),
    );

    return options;
  }, [t, gradeLevelList]);

  useBaseOnWindowSize(() => {
    setIsShrinkMode(
      checkIsShrinkMode(
        gradeOptions,
        customShrinkWidthLimit,
        shrinkWhenMaxCount,
      ),
    );
  });

  useEffect(() => {
    setIsShrinkMode(
      checkIsShrinkMode(
        gradeOptions,
        customShrinkWidthLimit,
        shrinkWhenMaxCount,
      ),
    );
  }, [gradeOptions, customShrinkWidthLimit, shrinkWhenMaxCount]);

  if (isShrinkMode === null) {
    return null;
  }

  const childProps = {
    options: gradeOptions,
    mainTitle: t('common.filter.grade'),
    subTitle: t('common.filter.subject'),
  };

  return isShrinkMode ? (
    <SelectGroup className={className.selectGroup} {...childProps} {...props} />
  ) : (
    <FilterCard className={className.filterCard} {...childProps} {...props} />
  );
};

LevelSelector.propTypes = {
  gradeLevelList: array.isRequired,
  onChange: func.isRequired,
  customShrinkWidthLimit: number,
  shrinkWhenMaxCount: bool,
  className: object,
  value: array,
  id: string,
};

LevelSelector.defaultProps = {
  value: [0, 0],
  id: '',
  customShrinkWidthLimit: null,
  className: {},
  shrinkWhenMaxCount: true,
};

export default LevelSelector;
