// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#video-root{width:100%!important;height:100%!important;position:absolute!important;top:0;background-color:rgba(35,24,21,.9)}#video-root.stopAutoPlay{z-index:-1!important}.vc-container .ytp-spinner,.vc-container .ytp-thumbnail-overlay{display:none}._2JOfT{font-size:12px;font-size:.75rem;line-height:28px;line-height:1.75rem}@media(max-width:767px){._2JOfT{font-size:.875;line-height:1.4375rem}}._3oVGT{line-height:20px;line-height:1.25rem;display:block;overflow:hidden;white-space:nowrap;-o-text-overflow:ellipsis;text-overflow:ellipsis;padding:0 2rem}._O-e8{cursor:pointer;margin:1.25rem 1rem 1rem}._2WVBM{cursor:pointer;font-size:14px;font-size:.875rem;line-height:18px;line-height:1.125rem;text-transform:uppercase}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"caption": "_2JOfT",
	"title": "_3oVGT",
	"progressBarWrapper": "_O-e8",
	"cancel": "_2WVBM"
};
module.exports = exports;
