import React, { useState, useEffect, useRef } from 'react';
import { string, bool } from 'prop-types';
import cx from 'classnames';
import useStyles from 'hooks/useStyles';
import { EMPTY_STRING } from 'constants/i18n';

import styles from './CaseBlock.scss';

const checkIsLineWrap = dom => dom.clientHeight > 26;

const deviceImgUrl =
  'https://snapask-dragonfruit.s3.ap-southeast-1.amazonaws.com/regular_class/testimonial-device.png';

const CaseBlock = ({
  quote,
  avatar,
  highlight,
  purchased,
  screenshot,
  schoolName,
  studentName,
  learningOutcome,
  isActive,
}) => {
  useStyles(styles);
  const [isUserWrap, setIsUserWrap] = useState();
  const userRef = useRef();

  useEffect(() => {
    if (isActive) {
      setIsUserWrap(checkIsLineWrap(userRef.current));
    }
  }, [isActive]);

  return (
    <div className={cx(styles.root, { [styles.isActive]: isActive })}>
      <div className={styles.screenshotContainer}>
        <img
          alt="screen shot device"
          className={styles.device}
          src={deviceImgUrl}
        />
        <img alt="quote" src={screenshot} className={styles.screenshot} />
      </div>
      <div className={styles.infoContainer}>
        <h3 className={styles.quote}>{quote}</h3>
        {learningOutcome !== EMPTY_STRING && (
          <div className={styles.outcome}>{learningOutcome}</div>
        )}
        <div className={cx(styles.content)}>{highlight}</div>
        <div className={styles.userBlock}>
          <img className={styles.avatar} src={avatar} alt={studentName} />
          <div>
            <div className={styles.class}>{purchased}</div>
            <div className={styles.student} ref={userRef}>
              <span>{studentName}</span>
              {!isUserWrap && <span className={styles.dot} />}
              <span>{schoolName}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CaseBlock.propTypes = {
  avatar: string.isRequired,
  screenshot: string.isRequired,
  quote: string.isRequired,
  highlight: string.isRequired,
  purchased: string.isRequired,
  studentName: string.isRequired,
  schoolName: string.isRequired,
  isActive: bool.isRequired,
  learningOutcome: string,
};

CaseBlock.defaultProps = {
  learningOutcome: '',
};

export default CaseBlock;
