// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".n3FYo{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;-ms-flex-pack:center;justify-content:center}._1Y175{width:192px;width:12rem}@media(max-width:767px){._1Y175{width:8.5rem}}@media(max-width:1439px){._1Q8cc{-ms-flex:33% 1;flex:33% 1}._1Q8cc:nth-child(-n+3){margin-bottom:1.5rem}}._3YZIs{width:192px;width:12rem;height:48px;height:3rem}@media(max-width:767px){._3YZIs{width:8.5rem;height:2.125rem}}", ""]);
// Exports
exports.locals = {
	"container": "n3FYo",
	"img": "_1Y175",
	"imgContainer": "_1Q8cc",
	"loading": "_3YZIs"
};
module.exports = exports;
