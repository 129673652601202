// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2DkUr{max-width:545px;max-width:34.0625rem}.jPr8n{display:block;position:relative;padding-bottom:52.47%;border-radius:.5rem;overflow:hidden;-webkit-box-shadow:0 .5rem 1.5rem 0 rgba(159,160,160,.4);box-shadow:0 .5rem 1.5rem 0 rgba(159,160,160,.4)}._5dGJa{position:absolute;-o-object-fit:cover;object-fit:cover;width:100%;height:100%}.GNYoZ{width:100%;height:312px;height:19.5rem}@media(max-width:767px){.GNYoZ{height:19.5rem}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"singleSlideWrapper": "_2DkUr",
	"slide": "jPr8n",
	"img": "_5dGJa",
	"loading": "GNYoZ"
};
module.exports = exports;
