import React from 'react';
import { oneOf, func, string } from 'prop-types';
import useStyles from 'hooks/useStyles';
import cx from 'classnames';

import { ArrowIcon } from 'components/Icon';
import styles from './GradientButton.scss';

const GradientButton = ({ text, onClick, color }) => {
  useStyles(styles);

  return (
    <div
      className={cx(styles.root, { [styles[color]]: true })}
      onClick={onClick}
    >
      <div className={styles.link}>
        <span className={styles.text}>{text}</span>
        <ArrowIcon className={styles.arrow} />
      </div>
    </div>
  );
};

GradientButton.propTypes = {
  text: string.isRequired,
  onClick: func.isRequired,
  color: oneOf(['blue', 'purple']).isRequired,
};

export default GradientButton;
