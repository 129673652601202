import React from 'react';
import { bool, oneOf } from 'prop-types';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';

import { REGULAR_CLASS, QA } from '../../constants';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './AllSubjectsContent.scss';

const SUBJECT_NAME_LIST = [
  'english',
  'economic',
  'accounting',
  'chineseHistory',
  'math',
  'liberalEducation',
  'history',
  'chemistry',
  'chinese',
];

const AllSubjectsContent = ({ name, showEnterAnimation }) => {
  useStyles(styles);

  return (
    <div className={cx(styles.root, styles[name])}>
      {SUBJECT_NAME_LIST.map(subjectName => (
        <span
          key={subjectName}
          className={cx(styles.subject, styles[subjectName], {
            [styles.moveIn]: showEnterAnimation,
          })}
        />
      ))}
    </div>
  );
};

AllSubjectsContent.propTypes = {
  name: oneOf([REGULAR_CLASS, QA]).isRequired,
  showEnterAnimation: bool.isRequired,
};

export default AllSubjectsContent;
