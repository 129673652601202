// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./images/stars.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._5Ex1m{z-index:0}._386Pt{left:calc(50% - 9.2rem);width:294.4px;width:18.4rem;padding:0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position-y:32px;background-position-y:2rem}@media(min-width:768px){._386Pt{top:calc(100% - 23.875rem);height:23.875rem}}@media(max-width:767px){._386Pt{top:calc(100% - 18.875rem);height:18.875rem}}._1dNwO{width:384px;width:24rem;height:384px;height:24rem;top:112px;top:7rem;left:calc(50% - 12rem);-webkit-animation:OSSw1 5s linear infinite;animation:OSSw1 5s linear infinite}@-webkit-keyframes OSSw1{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}@keyframes OSSw1{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"hideTitleSection": "_5Ex1m",
	"phoneMask": "_386Pt",
	"earth": "_1dNwO",
	"earthRotate": "OSSw1"
};
module.exports = exports;
