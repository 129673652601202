// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1J76U{position:relative}._3hO1R{position:absolute;z-index:1;cursor:pointer;width:100%;height:100%}.HGuuG{display:block;position:relative;padding-bottom:53%;border-radius:.5rem;overflow:hidden;margin-bottom:1rem}._29wbC{width:100%;height:400px;height:25rem}._3bf3k{-o-object-fit:cover;object-fit:cover;width:100%;height:100%}._3bf3k,.-s-cu{position:absolute}.-s-cu{bottom:0;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;width:56px;width:3.5rem;height:56px;height:3.5rem;background-color:rgba(35,24,21,.8)}._1KFuc{margin-bottom:.5rem;-webkit-line-clamp:2}._2TGbb,._1KFuc{display:-webkit-box;overflow:hidden;white-space:normal;-webkit-box-orient:vertical}._2TGbb{margin-bottom:.75rem;-webkit-line-clamp:3}.RHPFV{font-size:12px;font-weight:300;line-height:20px;font-family:var(--base-font)}@media(max-width:767px){.RHPFV{font-size:14px;line-height:23px}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "_1J76U",
	"link": "_3hO1R",
	"imgMask": "HGuuG",
	"loading": "_29wbC",
	"img": "_3bf3k",
	"playerIcon": "-s-cu",
	"title": "_1KFuc",
	"description": "_2TGbb",
	"authorAndPublishAt": "RHPFV"
};
module.exports = exports;
