import React from 'react';
import { string, oneOf, func, arrayOf } from 'prop-types';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';
import { REGULAR_CLASS, COURSE, QA } from '../../../../constants';
import commonHorizontalPaddingStyles from '../../../../commonHorizontalPadding.scss';

import Container from './components/Container';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './SlidesSection.scss';

const SlidesSection = ({
  sectionList,
  currentSection,
  onClickSlidesController,
}) => {
  useStyles(styles, commonHorizontalPaddingStyles);

  return (
    <div className={cx(styles.root, commonHorizontalPaddingStyles.container)}>
      <Container currentSection={currentSection} />
      <div className={styles.controllerList}>
        {sectionList.map(sectionName => (
          <div
            key={`${sectionName}-hero-slide-controller`}
            className={cx(styles.controller, styles[sectionName], {
              [styles.active]: currentSection === sectionName,
            })}
            onClick={() => onClickSlidesController(sectionName)}
          />
        ))}
      </div>
    </div>
  );
};

SlidesSection.propTypes = {
  sectionList: arrayOf(string).isRequired,
  currentSection: oneOf([REGULAR_CLASS, COURSE, QA]).isRequired,
  onClickSlidesController: func.isRequired,
};

export default SlidesSection;
