import React from 'react';
import { node, oneOf, shape, string } from 'prop-types';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';

import { REGULAR_CLASS, COURSE, QA } from '../../constants';

import styles from './IntroCard.scss';

const IntroCard = ({
  name,
  classNameMap,
  title,
  description,
  titleSectionAdditional,
  content,
  tag,
}) => {
  useStyles(styles);

  return (
    <div className={cx(styles.root, styles[name], classNameMap.root)}>
      <div className={cx(styles.titleSection, classNameMap.titleSection)}>
        {tag && <h6 className={styles.tag}>{tag}</h6>}
        <h2
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <p className={styles.description}>{description}</p>
        {titleSectionAdditional}
      </div>
      <div className={cx(styles.content, classNameMap.content)}>{content}</div>
    </div>
  );
};

IntroCard.propTypes = {
  name: oneOf([REGULAR_CLASS, COURSE, QA]).isRequired,
  title: string.isRequired,
  description: string.isRequired,
  content: node,
  titleSectionAdditional: node,
  classNameMap: shape({
    root: string,
    titleSection: string,
    content: string,
  }),
  tag: string,
};

IntroCard.defaultProps = {
  classNameMap: {},
  content: null,
  titleSectionAdditional: null,
  tag: '',
};

export default IntroCard;
