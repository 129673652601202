// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./images/chunk.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./images/subtract.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "._1qaWA{grid-gap:24px;gap:24px;grid-gap:1.5rem;gap:1.5rem;padding-right:1.5rem}@media(min-width:768px)and (max-width:1023px)and (orientation:portrait){._1qaWA{grid-gap:2.5rem;gap:2.5rem;padding-right:1.5rem}}._3bZd-{width:176px;width:11rem;height:176px;height:11rem;background-position:50%;background-size:cover}@media(min-width:768px)and (max-width:1023px)and (orientation:portrait){._3bZd-{width:17.875rem;height:17.875rem}}._1tjF8{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}._3XAIm{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}", ""]);
// Exports
exports.locals = {
	"biteSizeVideosMarquee": "_1qaWA",
	"biteSizeVideosShape": "_3bZd-",
	"chunk": "_1tjF8",
	"subtract": "_3XAIm"
};
module.exports = exports;
