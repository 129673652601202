import React, { forwardRef } from 'react';
import { node, object, oneOf } from 'prop-types';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';

import { REGULAR_CLASS, COURSE, QA, ACADEMY } from '../../constants';
import commonGapStyles from '../../commonGap.scss';
import commonVerticalPaddingStyles from '../../commonVerticalPadding.scss';
import SquareWithRound from '../SquareWithRound';
import TriangleWithRound from '../../../../../../components/TriangleWithRound';
import HollowCircle from './components/HollowCircle';
import QuestionMark from './components/QuestionMark';
import TitleSection from './components/TitleSection';

import useCurrentTriangleWithRoundInfo from './useCurrentTriangleWithRoundInfo';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './MainOfferingSection.scss';

const MainOfferingSection = forwardRef(
  ({ name, children, classNameMap }, ref) => {
    useStyles(commonGapStyles, commonVerticalPaddingStyles, styles);
    const currentTriangleWithRoundInfo = useCurrentTriangleWithRoundInfo();
    const showBackgroundIcon = !(name === ACADEMY);

    const sectionColor = {
      [REGULAR_CLASS]: '#E0EEFF',
      [COURSE]: '#EBEBFE',
      [QA]: '#D9FCFE',
    };

    const sectionBackgroundShapeList = {
      [REGULAR_CLASS]: [
        props => <HollowCircle {...props} />,
        props => (
          <SquareWithRound
            className={cx(styles.SquareWithRound, styles[REGULAR_CLASS])}
            size={415}
            radius={80}
            name={name}
            {...props}
          />
        ),
      ],
      [COURSE]: [
        props => (
          <TriangleWithRound
            className={styles.triangleWithRound}
            width={currentTriangleWithRoundInfo.width}
            height={currentTriangleWithRoundInfo.height}
            strokeWidth={currentTriangleWithRoundInfo.strokeWidth}
            {...props}
          />
        ),
        props => (
          <SquareWithRound
            className={cx(styles.SquareWithRound, styles[COURSE])}
            size={340}
            radius={80}
            name={name}
            {...props}
          />
        ),
      ],
      [QA]: [props => <QuestionMark {...props} />],
    };

    return (
      <div
        ref={ref}
        style={{
          '--wide-desktop-up-gap': '7.5rem',
        }}
        className={styles.root}
      >
        {showBackgroundIcon &&
          sectionBackgroundShapeList[name].map((renderIcon, index) =>
            renderIcon({
              key: index,
              color: sectionColor[name],
            }),
          )}
        <div
          className={cx(
            commonGapStyles.container,
            commonVerticalPaddingStyles.container,
            styles.content,
            classNameMap.container,
          )}
        >
          <TitleSection name={name} />
          {children}
        </div>
      </div>
    );
  },
);

MainOfferingSection.propTypes = {
  name: oneOf([REGULAR_CLASS, COURSE, QA, ACADEMY]).isRequired,
  children: node.isRequired,
  classNameMap: object,
};

MainOfferingSection.defaultProps = {
  classNameMap: {},
};

export default MainOfferingSection;
