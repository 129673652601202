import React from 'react';
import { string, shape, bool } from 'prop-types';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';

import DialogAnimation from './components/DialogAnimation';

import styles from './ChattingAnimation.scss';

const ChattingAnimation = ({ classNameMap, keyframesTime, showBackground }) => {
  useStyles(styles);
  const dialogList = new Array(2).fill('');

  return (
    <div
      className={cx(styles.outerLayer, {
        [styles.showBackground]: showBackground,
      })}
    >
      <div className={cx(styles.innerLayer, classNameMap.innerLayer)}>
        {dialogList.map((item, index) => (
          <DialogAnimation
            // eslint-disable-next-line react/no-array-index-key
            key={`dialog-animation-${index}`}
            classNameMap={{
              root: cx(styles.dialog, classNameMap.dialog),
              point: classNameMap.point,
            }}
            keyframesTime={keyframesTime}
          />
        ))}
      </div>
    </div>
  );
};

ChattingAnimation.propTypes = {
  classNameMap: shape({
    innerLayer: string,
    dialog: string,
    point: string,
  }),
  keyframesTime: string,
  showBackground: bool,
};

ChattingAnimation.defaultProps = {
  classNameMap: {},
  keyframesTime: '0.4s',
  showBackground: true,
};

export default ChattingAnimation;
