// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2vQ25{position:relative;width:86px;width:5.375rem;height:86px;height:5.375rem;padding:1rem;border-radius:24px;cursor:pointer;background:#fff;-webkit-box-shadow:0 .25rem 2rem rgba(98,168,253,.08);box-shadow:0 .25rem 2rem rgba(98,168,253,.08)}._16Y2h{background:#e0eeff}._2h7MV{width:100%;height:100%;border-radius:16px;opacity:.6}._2nPjR{opacity:1}", ""]);
// Exports
exports.locals = {
	"root": "_2vQ25",
	"rootIsActive": "_16Y2h",
	"img": "_2h7MV",
	"imgIsActive": "_2nPjR"
};
module.exports = exports;
