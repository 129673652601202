import React from 'react';
import cx from 'classnames';
import useStyles from 'hooks/useStyles';
import Marquee from '../../../Marquee';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './BitedCookieContent.scss';

const BitedCookieContent = () => {
  useStyles(styles);

  return (
    <Marquee
      classNameMap={{
        marquee: styles.biteSizeVideosMarquee,
      }}
      speed={100}
    >
      <div className={cx(styles.biteSizeVideosShape, styles.chunk)} />
      <div className={cx(styles.biteSizeVideosShape, styles.subtract)} />
      <div className={cx(styles.biteSizeVideosShape, styles.chunk)} />
    </Marquee>
  );
};

export default BitedCookieContent;
