import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useUpsellBanner from 'hooks/useUpsellBanner';
import useStyles from 'hooks/useStyles';
import * as upsellBannerPage from 'constants/upsellBannerPage';
import SnapaskHeader from 'components/SnapaskHeader';
import SnapaskFooter from 'components/SnapaskFooter';
import SnapaskLocaleSelection from 'components/SnapaskLocaleSelection';
import AlertBanner from 'components/AlertBanner';
import UpsellBanner from 'components/UpsellBanner';
import ApplicationContext from 'components/ApplicationContext';
import { getOfferingList } from 'reduxModules/siteInfo/selector';
import { sendGTMEvent } from 'reduxModules/googleTagManager/action';
import { fetchUpsellBanner } from 'reduxModules/upsellBanner/action';
import {
  HOME_BANNER_VIEW,
  HOME_RC_VIEW,
  HOME_COURSE_VIEW,
  HOME_QA_VIEW,
  HOME_ACADEMY_VIEW,
} from 'constants/gtmEvent';
import { convertSnakeToCamelCase } from 'utils/common';
import ContextStore from 'utils/createContext';

import HeroSection from './components/HeroSection';
import RecommendedSection from './components/RecommendedSection';
import BannerSection from './components/BannerSection';
import MainOfferingSection from './components/MainOfferingSection';
import RegularClassSection from './components/RegularClassSection';
import CourseSection from './components/CourseSection';
import QASection from './components/QASection';
import AcademySection from './components/AcademySection';
import UserStateSection from './components/UserStateSection';
import CallToActionSection from './components/CallToActionSection';

import {
  REGULAR_CLASS,
  COURSE,
  QA,
  ACADEMY,
  RECOMMENDED,
  BANNER,
} from './constants';
import useLoading from './useLoading';

import commonGapStyles from './commonGap.scss';
import styles from './HomeOriginal.scss';

function HomeOriginal() {
  const {
    query: { section },
  } = useContext(ApplicationContext);
  useStyles(styles);
  const dispatch = useDispatch();
  const [scrollToSection, setScrollToSection] = useState();
  const [scrollByHeroSection, setScrollByHeroSection] = useState(false);
  const loading = useLoading();
  const { upsellBannerVisible, hideUpsellBanner } = useUpsellBanner();

  const regularClassSectionRef = useRef(null);
  const courseSectionRef = useRef(null);
  const QASectionRef = useRef(null);
  const academySectionRef = useRef(null);
  const recommendedSectionRef = useRef(null);
  const bannerSectionRef = useRef(null);

  const offeringList = useSelector(getOfferingList);
  const offeringMap = useMemo(
    () =>
      offeringList.reduce(
        (origin, offering) => ({
          ...origin,
          [convertSnakeToCamelCase(offering)]: true,
        }),
        {},
      ),
    [offeringList],
  );
  const hasContentOffering = Boolean(
    offeringMap.course || offeringMap.regularClass,
  );

  const handleClickSlideCTA = name => {
    setScrollToSection(name);
    setScrollByHeroSection(true);
  };

  useEffect(() => {
    if (
      !loading &&
      section &&
      (section === RECOMMENDED || section === BANNER || offeringMap[section])
    ) {
      setScrollToSection(section);
    }
  }, [loading, section, offeringMap]);

  useEffect(() => {
    const sectionElementTop = {
      [REGULAR_CLASS]: regularClassSectionRef.current?.offsetTop,
      [COURSE]: courseSectionRef.current?.offsetTop,
      [QA]: QASectionRef.current?.offsetTop,
      [ACADEMY]: academySectionRef.current?.offsetTop,
      [RECOMMENDED]: recommendedSectionRef.current?.offsetTop,
      [BANNER]: bannerSectionRef.current?.offsetTop,
    };
    const headerHeight = 72;

    const sectionEventName = {
      [REGULAR_CLASS]: HOME_RC_VIEW,
      [COURSE]: HOME_COURSE_VIEW,
      [QA]: HOME_QA_VIEW,
      [ACADEMY]: HOME_ACADEMY_VIEW,
      [BANNER]: HOME_BANNER_VIEW,
    };

    window.scrollTo({
      top: sectionElementTop[scrollToSection] - headerHeight,
      behavior: 'smooth',
    });

    if (sectionEventName[scrollToSection]) {
      dispatch(
        sendGTMEvent({
          eventName: sectionEventName[scrollToSection],
          data: {
            section: !scrollByHeroSection,
          },
        }),
      );
    }
  }, [scrollToSection, dispatch, scrollByHeroSection]);

  useEffect(() => {
    dispatch(fetchUpsellBanner({ page: upsellBannerPage.HOME }));
  }, []);

  return (
    <Fragment>
      <SnapaskHeader />
      <AlertBanner />
      <SnapaskLocaleSelection />
      {upsellBannerVisible && (
        <UpsellBanner page={upsellBannerPage.HOME} onClose={hideUpsellBanner} />
      )}
      <div
        style={{
          '--wide-desktop-up-gap': '5rem',
        }}
        className={commonGapStyles.container}
      >
        <ContextStore.Provider value={{ onSlideCTAClick: handleClickSlideCTA }}>
          <HeroSection
            offeringMap={offeringMap}
            onAutoPlayEnd={() => setScrollToSection(RECOMMENDED)}
          />
        </ContextStore.Provider>
        <RecommendedSection
          ref={recommendedSectionRef}
          className={styles.section}
        />
        <BannerSection ref={bannerSectionRef} className={styles.section} />
        {offeringMap[REGULAR_CLASS] && (
          <MainOfferingSection
            ref={regularClassSectionRef}
            name={REGULAR_CLASS}
          >
            <RegularClassSection />
          </MainOfferingSection>
        )}
        {offeringMap[COURSE] && (
          <MainOfferingSection ref={courseSectionRef} name={COURSE}>
            <CourseSection />
          </MainOfferingSection>
        )}
        {offeringMap[QA] && (
          <MainOfferingSection ref={QASectionRef} name={QA}>
            <QASection />
          </MainOfferingSection>
        )}
        {offeringMap[ACADEMY] && (
          <MainOfferingSection ref={academySectionRef} name={ACADEMY}>
            <AcademySection />
          </MainOfferingSection>
        )}
        <div>
          <UserStateSection />
          <CallToActionSection hasContentOffering={hasContentOffering} />
        </div>
      </div>
      <SnapaskFooter />
    </Fragment>
  );
}
export default HomeOriginal;
