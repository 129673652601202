import React, { useEffect, useRef, useState } from 'react';
import { number, string } from 'prop-types';
import LoadingSkeleton from 'components/LoadingSkeleton';

const preCacheImage = url => {
  return new Promise((resolve, reject) => {
    let cacheImage = new Image();

    cacheImage.onload = () => {
      cacheImage = null;
      resolve(true);
    };

    cacheImage.onerror = () => {
      cacheImage = null;
      reject(new Error("image can't loaded"));
    };

    cacheImage.src = url;
  });
};

function LoadedImage({ src, alt, delayTime, ...props }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const timeIdRef = useRef(null);

  const delayToggleLoadState = (isLoading, delayMS) => {
    timeIdRef.current = window.setTimeout(() => {
      setIsLoaded(isLoading);
    }, delayMS);
  };

  useEffect(() => {
    if (isLoaded) {
      setIsLoaded(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  useEffect(() => {
    (async () => {
      try {
        const res = await preCacheImage(src);

        delayToggleLoadState(res, delayTime);
      } catch (error) {
        delayToggleLoadState(true, 3000);
      }
    })();

    return () => {
      if (timeIdRef.current) {
        window.clearTimeout(timeIdRef.current);
        timeIdRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  if (!isLoaded) return <LoadingSkeleton {...props} />;

  return <img src={src} alt={alt} {...props} />;
}

LoadedImage.propTypes = {
  src: string,
  alt: string,
  className: string,
  delayTime: number,
};

LoadedImage.defaultProps = {
  src: '',
  alt: '',
  className: '',
  delayTime: 0,
};

export default LoadedImage;
