// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._30xBc{position:relative;background-color:#fff;border-radius:5rem;overflow:hidden;max-width:1440px;max-width:90rem;width:100%;margin:0 auto}@media(max-width:767px){._30xBc{border-radius:1.5rem}}.dD-CM{position:relative}._3zPti{position:absolute}@media(min-width:1440px){._3zPti{top:-3.5rem}}@media(min-width:1024px)and (max-width:1439px){._3zPti{top:-3rem}}@media(min-width:768px)and (max-width:1023px){._3zPti{top:-3.5625rem}}@media(max-width:767px){._3zPti{top:-3rem}}._2mES2._2zBzy{top:960px;top:60rem;left:-240px;left:-15rem}@media(max-width:767px){._2mES2._2zBzy{display:none}}@media(min-width:1440px){._2mES2._2TLyd{top:41rem;right:-5rem}}@media(max-width:1439px){._2mES2._2TLyd{display:none}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "_30xBc",
	"content": "dD-CM",
	"triangleWithRound": "_3zPti",
	"SquareWithRound": "_2mES2",
	"regularClass": "_2zBzy",
	"course": "_2TLyd"
};
module.exports = exports;
