import React from 'react';
import { number, string } from 'prop-types';

const TriangleWithRound = ({
  className,
  width,
  height,
  strokeWidth,
  color,
}) => {
  const pointInitial = strokeWidth / 2;
  const pointWidth = width - pointInitial;
  const pointHeight = height - pointInitial;

  return (
    <svg className={className} width={width} height={height}>
      <polygon
        strokeLinejoin="round"
        points={`${pointInitial},${pointInitial} ${pointInitial},${pointHeight} ${pointWidth},${pointHeight /
          2}`}
        strokeWidth={strokeWidth}
        stroke={color}
        fill={color}
      />
    </svg>
  );
};

TriangleWithRound.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  color: string.isRequired,
  className: string,
  strokeWidth: number,
};

TriangleWithRound.defaultProps = {
  className: '',
  strokeWidth: 0,
};

export default TriangleWithRound;
