// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._22Aj_{position:relative;margin:0 auto;padding:0 2rem}@media(min-width:768px)and (max-width:1023px)and (orientation:portrait){._22Aj_{padding:0 2.5rem}}@media(min-width:1024px)and (max-width:1439px){._22Aj_{padding:0 4rem}}@media(min-width:1440px){._22Aj_{padding:0;max-width:78rem}}._3U2yI{padding-top:3rem;font-size:48px;font-size:3rem;font-weight:500;line-height:60px;line-height:3.75rem;font-family:var(--heading-font);letter-spacing:var(--letter-spacing)}@media(max-width:767px){._3U2yI{font-size:2rem;line-height:2.5rem}}._3q4S5{margin:1.5rem 0 2rem;max-width:513px;max-width:32.0625rem;font-size:18px;font-size:1.125rem;font-weight:400;line-height:29px;line-height:1.8125rem;font-family:var(--base-font)}", ""]);
// Exports
exports.locals = {
	"root": "_22Aj_",
	"title": "_3U2yI",
	"description": "_3q4S5"
};
module.exports = exports;
