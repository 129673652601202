// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3eKXW{padding:0 6rem;margin:0 auto;width:100%}@media(max-width:1023px){._3eKXW{padding:0 2.5rem}}@media(max-width:767px){._3eKXW{padding:0}}@media(min-width:1440px){._3eKXW{max-width:78rem;padding:0}}._3MVry{width:560px;width:35rem}@media(max-width:1023px){._3MVry{width:100%;padding:0 1rem}}._2kZZo{margin-bottom:1.5rem;font-size:48px;font-size:3rem;font-weight:500;line-height:60px;line-height:3.75rem;font-family:var(--heading-font);letter-spacing:var(--letter-spacing)}@media(max-width:767px){._2kZZo{font-size:2rem;line-height:2.5rem}}._1AZcs{color:#595757;font-size:16px;font-size:1rem;font-weight:400;line-height:26px;line-height:1.625rem;font-family:var(--base-font)}.JkIu_{display:-ms-flexbox;display:flex;grid-gap:48px;gap:48px;grid-gap:3rem;gap:3rem;margin-top:1.5rem;margin-bottom:4rem}@media(max-width:1023px){.JkIu_{grid-gap:1.5rem;gap:1.5rem;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-bottom:1.5rem}}@media(max-width:767px){.JkIu_{grid-gap:2rem;gap:2rem;margin-bottom:2rem}}._1nY6r{display:-ms-flexbox;display:flex;-ms-flex-pack:end;justify-content:flex-end;grid-gap:48px;gap:48px;grid-gap:3rem;gap:3rem;margin-top:2rem}@media(max-width:1023px){._1nY6r{grid-gap:1.5rem;gap:1.5rem;margin-top:0;-ms-flex-wrap:wrap;flex-wrap:wrap}}@media(max-width:767px){._1nY6r{grid-gap:2rem;gap:2rem}}.qP_Cm{width:488px;width:30.5rem;height:504px;height:31.5rem}@media(max-width:1023px){.qP_Cm{width:100%}}._3HExQ{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}", ""]);
// Exports
exports.locals = {
	"root": "_3eKXW",
	"info": "_3MVry",
	"title": "_2kZZo",
	"description": "_1AZcs",
	"rcRow": "JkIu_",
	"icRow": "_1nY6r",
	"introCard": "qP_Cm",
	"chattingAnimation": "_3HExQ"
};
module.exports = exports;
