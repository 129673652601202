import React, { useState, useCallback, useRef } from 'react';
import { func, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useStyles from 'hooks/useStyles';
import useBaseOnWindowSize from 'hooks/useBaseOnWindowSize';

import { getUpsellBannerByPage } from 'reduxModules/upsellBanner/selector';
import { COPY } from 'constants/upsellBannerClickType';
import Button from 'components/Button';
import { CloseIcon } from 'components/Icon';

import eve from './Promotion_banner_Eve.svg';
import styles from './UpsellBanner.scss';
import { setSocialWidgetProps } from 'reduxModules/socialWidget/action';

const SHOW_COPIED_TEXT_MS = 1500;

export default function UpsellBanner({ page, onClose }) {
  useStyles(styles);
  const { t } = useTranslation('premium');
  const upsellBanner = useSelector(getUpsellBannerByPage(page));
  const [showingCopiedText, showCopiedText] = useState(false);
  const inputRef = useRef();
  const upsellBannerRef = useRef();
  const bannerHeightRef = useRef();
  const dispatch = useDispatch();
  const { desc, link, linkText, clickType } = upsellBanner || {};

  const changeSocialWidgetStyle = useCallback(() => {
    const height = upsellBannerRef.current?.offsetHeight;
    dispatch(
      setSocialWidgetProps({
        bottomDistance: upsellBannerRef.current?.offsetHeight,
      }),
    );
    bannerHeightRef.current = height;
  }, [upsellBannerRef.current?.offsetHeight]);

  useBaseOnWindowSize(changeSocialWidgetStyle);

  const resetSocialWidgetStyle = () => {
    dispatch(
      setSocialWidgetProps({
        bottomDistance: null,
      }),
    );
  };

  const handleClick = () => {
    if (clickType !== COPY) {
      window.open(link, '_blank');
      return;
    }

    const inputElm = inputRef.current;
    inputElm.focus();
    inputElm.setSelectionRange(0, link.length);
    document.execCommand('copy');
    inputElm.blur();
    showCopiedText(true);
    setTimeout(() => showCopiedText(false), SHOW_COPIED_TEXT_MS);
  };

  const handleClose = () => {
    resetSocialWidgetStyle();
    onClose();
  };

  const getUpsellBannerRef = ref => {
    if (upsellBannerRef.current) return;
    upsellBannerRef.current = ref;
    changeSocialWidgetStyle();
  };

  if (!upsellBanner) {
    return null;
  }

  return (
    <div className={styles.root} ref={getUpsellBannerRef}>
      <div className={styles.content}>
        {/* invisible input for copying text */}
        <input
          value={link}
          ref={inputRef}
          readOnly
          className={styles.linkInput}
        />
        <img src={eve} className={styles.eve} alt="eve" />
        <span className={styles.description}>{desc}</span>
        <Button
          text={
            showingCopiedText
              ? t('premium:premium.copiedDiscountCode')
              : linkText
          }
          small
          outline
          type="dark"
          className={styles.button}
          onClick={handleClick}
        />
      </div>
      <CloseIcon onClick={handleClose} className={styles.closeIcon} />
    </div>
  );
}

UpsellBanner.propTypes = {
  page: string.isRequired,
  onClose: func.isRequired,
};
