// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".iZBYR{display:grid}@media(min-width:1024px){.iZBYR{grid-gap:3rem;gap:3rem;grid-template-columns:repeat(2,minmax(0,1fr))}}@media(max-width:1023px){.iZBYR{grid-gap:1.5rem;gap:1.5rem;grid-template-columns:repeat(1,minmax(0,1fr))}}@media(max-width:767px){.iZBYR{padding:0}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"container": "iZBYR"
};
module.exports = exports;
