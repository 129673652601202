import React, { useContext, useMemo } from 'react';
import { array, bool } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useGetCurrentImageType from 'hooks/useGetCurrentImageType';
import useStyles from 'hooks/useStyles';

import {
  HOME_COURSEFEATURE_COURSE_CLICK,
  HOME_SEE_MORE_COURSES_CLICK,
} from 'constants/gtmEvent';
import { COURSE, COURSE_GROUP_TEMPLATE } from 'constants/routingPath';
import { buildPreservedQuery, getQuery, checkQueryExist } from 'utils/query';
import useGetOfficialSiteURL from 'hooks/useGetOfficialSiteURL';
import { push } from 'reduxModules/navigation/action';
import { sendGTMEvent } from 'reduxModules/googleTagManager/action';
import CourseIntroCard from 'routes/course/components/CourseIntroCard';
import ApplicationContext from 'components/ApplicationContext';
import GradientButton from '../GradientButton';
import styles from './CourseList.scss';

const getLazyLoadSettings = ({ isMobile, isTablet }) => {
  if (isMobile) {
    return { height: 490, offset: 0 };
  }
  if (isTablet) {
    return { height: 440, offset: 0 };
  }
  return { height: 400, offset: 0 };
};

const CourseList = ({ courseList, hasMore }) => {
  useStyles(styles);
  const dispatch = useDispatch();
  const { t } = useTranslation(['landingPage']);
  const { imageType, setContainerRef } = useGetCurrentImageType();
  const getOfficialSiteURL = useGetOfficialSiteURL();

  const handleOnButtonClick = () => {
    dispatch(
      sendGTMEvent({
        eventName: HOME_SEE_MORE_COURSES_CLICK,
      }),
    );
    if (checkQueryExist('groupId')) {
      dispatch(
        push(
          `${getOfficialSiteURL(COURSE_GROUP_TEMPLATE, {
            id: getQuery('groupId'),
          })}?${buildPreservedQuery(['subjectId', 'gradeLevelId'])}`,
        ),
      );
    } else {
      dispatch(
        push(
          `${getOfficialSiteURL(COURSE)}?${buildPreservedQuery([
            'subjectId',
            'gradeLevelId',
          ])}`,
        ),
      );
    }
  };

  return (
    <>
      <h2 className={styles.title}>{t('home.courseSection.subtitle')}</h2>
      <div className={styles.cardList} ref={setContainerRef}>
        {courseList.map(item => (
          <div key={item.id} className={styles.courseCard}>
            <CourseIntroCard
              key={item.id}
              eventName={HOME_COURSEFEATURE_COURSE_CLICK}
              pictureUrl={item.picture[imageType]}
              {...item}
            />
          </div>
        ))}
      </div>
      {hasMore && (
        <GradientButton
          text={t('home.courseSection.featuredCourse.cta')}
          color="purple"
          onClick={handleOnButtonClick}
        />
      )}
    </>
  );
};

CourseList.propTypes = {
  courseList: array.isRequired,
  hasMore: bool.isRequired,
};

export default CourseList;
