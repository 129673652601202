import React, {
  Fragment,
  useContext,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { string, number, shape, func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import screenfull from 'screenfull';
import YouTube from 'react-youtube';
import cx from 'classnames';
import { isIOS } from 'react-device-detect';

import ApplicationContext from 'components/ApplicationContext';
import { LIVE_TOPIC } from 'constants/routingPath';
import useStyles from 'hooks/useStyles';
import useGetOfficialSiteURL from 'hooks/useGetOfficialSiteURL';
import Button from 'components/Button';
import Star from 'components/Star';
import { PlayerFillIcon } from 'components/Icon';
import BrightcovePlayer from 'components/BrightcovePlayer';
import LoadedImage from 'components/LoadedImage';
import Link from 'components/Link';

import { YOUTUBE, BRIGHT_COVE } from 'routes/live/constants/rcVideo';
import styles from './ClassCard.scss';

const youtubeOptions = {
  height: '100%',
  width: '100%',
  playerVars: {
    autoplay: 1,
    playsinline: 0,
    modestbranding: 1,
    controls: 0,
    rel: 0,
  },
};

const handleYouTubePlay = player => {
  player.playVideo();
};

const handleYouTubeStop = player => {
  player.stopVideo();
};

const handleBrightCovePlay = player => {
  player.handlePlay();
};

const handleBrightCoveStop = player => {
  player.handlePause();
  player.handleSeek(0);
};

const handleYoutubeRequestFullscreen = evt => {
  const iframe = evt.target.getIframe();
  if (screenfull.isEnabled) {
    screenfull.request(iframe);
  }
};

const handleBrightCoveRequestFullscreen = player => {
  player.handleRequestFullscreen();
};

const videoActionMap = {
  youtube: {
    play: handleYouTubePlay,
    stop: handleYouTubeStop,
    requestFullscreen: handleYoutubeRequestFullscreen,
  },
  brightcove: {
    play: handleBrightCovePlay,
    stop: handleBrightCoveStop,
    requestFullscreen: handleBrightCoveRequestFullscreen,
  },
};

const ClassCard = ({
  id,
  name,
  hashtag,
  frequency,
  pictureUrl,
  highlight,
  averageRate,
  exclusivePlan,
  forceVideoStop,
  firstFreeLesson,
  instructorNameList,
  onVideoEnd,
  onLinkClick,
  onVideoStart,
  className,
}) => {
  useStyles(styles);
  const { t } = useTranslation(['regularClass']);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const {
    screenWidth: { isMobile: isBriefMode },
  } = useContext(ApplicationContext);
  const videoRef = useRef();
  const videoType = firstFreeLesson?.videoSource;
  const getOfficialSiteURL = useGetOfficialSiteURL();
  const rcTopicUrl = getOfficialSiteURL(LIVE_TOPIC, {
    id,
  });

  const handleClickPlay = () => {
    // IOS mobile has its own loading issue, onSuccess & onLoaddata events of Brightcove is not working.
    if (isIOS && isBriefMode) {
      const intervalId = setInterval(() => {
        if (videoRef.current?.playerRef?.current) {
          videoActionMap[videoType].play(videoRef.current);

          clearInterval(intervalId);
        }
      }, 200);
    }
    setIsPlaying(true);
  };

  const handleVideoEnded = () => {
    if (videoRef.current) {
      setIsPlaying(false);
      if (typeof onVideoEnd === 'function') {
        onVideoEnd();
      }
    }
  };

  const handleYoutubeVideoReady = evt => {
    videoRef.current = evt.target;
    setIsPlayerReady(true);
  };

  const handleBrightcoveVideoReady = () => {
    setIsPlayerReady(true);
  };

  const handleVideoPlay = () => {
    if (typeof onVideoStart === 'function') {
      onVideoStart(id);
    }
  };

  const handleOnLinkClick = () => {
    if (typeof onLinkClick === 'function') {
      onLinkClick();
    }
    // history.push(rcTopicUrl);
  };

  const handleFullscreenchange = () => {
    // IOS mobile device will stop itself when it's not in full-screen mode
    if (!isIOS && !document.fullscreenElement && isBriefMode) {
      videoActionMap[videoType].stop(videoRef.current);
      setIsPlaying(false);
    }
  };

  const renderVideo = shouldHide => {
    switch (videoType) {
      case BRIGHT_COVE:
        return (
          <BrightcovePlayer
            isSimpleVersion
            className={shouldHide ? styles.videoHidden : styles.video}
            ref={videoRef}
            videoId={firstFreeLesson.videoId}
            onEnd={handleVideoEnded}
            onPlay={handleVideoPlay}
            onFullscreenchange={handleFullscreenchange}
            onReady={handleBrightcoveVideoReady}
            showAutoPlayController={false}
            autoplay={false}
            autoFocus={false}
            shouldHidePlayButton
          />
        );
      case YOUTUBE:
        return (
          <YouTube
            className={shouldHide ? styles.videoHidden : styles.video}
            onReady={handleYoutubeVideoReady}
            onEnd={handleVideoEnded}
            onPlay={handleVideoPlay}
            videoId={firstFreeLesson.videoId}
            containerClassName={styles.video}
            opts={youtubeOptions}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (
      isPlaying &&
      isPlayerReady &&
      ((isBriefMode && !isIOS) || !isBriefMode)
    ) {
      videoActionMap[videoType].play(videoRef.current);

      if (isBriefMode) {
        videoActionMap[videoType].requestFullscreen(videoRef.current);
      }
    }
  }, [isPlaying, isPlayerReady, videoType, isBriefMode]);

  useEffect(() => {
    if (forceVideoStop && videoRef.current) {
      videoActionMap[videoType].stop(videoRef.current);
      setIsPlaying(false);
    }
  }, [forceVideoStop, videoType]);

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.mainIntro}>
        <div className={styles.imgWrapper} data-ooo-image-container>
          <LoadedImage src={pictureUrl} className={styles.img} alt={name} />
        </div>
        <h2 className={styles.lessonName}>{name}</h2>
        <div className={styles.instructorName}>{instructorNameList}</div>
        <div className={styles.frequency}>{frequency}</div>
        {averageRate !== 0 && (
          <div className={styles.rating}>
            <Star svgSize={16} type="GRAY_FILLED" className={styles.star} />
            {averageRate}
          </div>
        )}
        {exclusivePlan && (
          <div className={styles.price}>
            {t('classCard.priceUp', {
              price: `${exclusivePlan.currency}$${Number(
                exclusivePlan.price,
              ).toLocaleString()}`,
            })}
          </div>
        )}
        {isBriefMode && (
          <Fragment>
            {hashtag && <div className={styles.hashTag}>{hashtag}</div>}
            {firstFreeLesson && (
              <Button
                onClick={handleClickPlay}
                className={styles.videoButton}
                text={t('classCard.freeEpisode.mobile')}
                outline
              />
            )}
          </Fragment>
        )}
        <Link to={rcTopicUrl} onClick={handleOnLinkClick}>
          <Button className={styles.ctaButton} text={t('classCard.cta')} />
        </Link>
      </div>
      {/* To enable video playing on mobile, render a hidden video element here */}
      {isBriefMode && isPlaying && renderVideo(isBriefMode)}
      {!isBriefMode && (
        <div className={styles.subIntro}>
          <div className={styles.lesson}>
            {renderVideo()}
            {!isPlaying && (
              <div onClick={handleClickPlay} className={styles.playField}>
                <div className={styles.videoOverlayMask} />
                <div className={styles.freeTag}>
                  {t('classCard.freeEpisode.desktop')}
                </div>
                <div className={styles.playGroup}>
                  <button type="button" className={styles.planButton}>
                    <PlayerFillIcon />
                  </button>
                  {firstFreeLesson && (
                    <h3 className={styles.freeTitle}>{firstFreeLesson.name}</h3>
                  )}
                </div>
              </div>
            )}
          </div>
          <pre className={styles.brief}>{highlight}</pre>
          {hashtag && <div className={styles.hashTag}>{hashtag}</div>}
        </div>
      )}
    </div>
  );
};

ClassCard.propTypes = {
  id: number.isRequired,
  pictureUrl: string,
  name: string.isRequired,
  instructorNameList: string.isRequired,
  exclusivePlan: shape({
    currency: string.isRequired,
    price: string.isRequired,
  }),
  firstFreeLesson: shape({
    url: string.isRequired,
    videoSource: string.isRequired,
    videoId: string.isRequired,
    name: string.isRequired,
  }),
  onLinkClick: func.isRequired,
  highlight: string,
  frequency: string,
  hashtag: string,
  forceVideoStop: bool,
  onVideoStart: func,
  onVideoEnd: func,
  className: string,
  averageRate: number,
};

ClassCard.defaultProps = {
  forceVideoStop: false,
  firstFreeLesson: null,
  hashtag: null,
  frequency: null,
  exclusivePlan: null,
  onVideoEnd: null,
  onVideoStart: null,
  highlight: null,
  className: '',
  pictureUrl: '',
  averageRate: 0,
};

export default memo(ClassCard);
