import React, { useContext, useMemo } from 'react';
import { array, bool, object } from 'prop-types';
import { useDispatch } from 'react-redux';
import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next';

import { LIVE } from 'constants/routingPath';
import { HOME } from 'constants/pageTypes';
import {
  REGULAR_CLASS_TOPIC_CLICK,
  HOME_SEE_MORE_RC_CLICK,
} from 'constants/gtmEvent';
import { buildPreservedQuery } from 'utils/query';
import useGetCurrentImageType from 'hooks/useGetCurrentImageType';
import useGetOfficialSiteURL from 'hooks/useGetOfficialSiteURL';
import useStyles from 'hooks/useStyles';
import { push } from 'reduxModules/navigation/action';
import { sendGTMEvent } from 'reduxModules/googleTagManager/action';
import ApplicationContext from 'components/ApplicationContext';
import LoadingSkeleton from 'components/LoadingSkeleton';
import ClassCard from 'components/ClassCard';
import GradientButton from '../GradientButton';
import styles from './RegularClassList.scss';

const getLazyLoadSettings = ({ isMobile, isTablet }) => {
  if (isMobile) {
    return { height: 490, offset: 0 };
  }
  if (isTablet) {
    return { height: 440, offset: 0 };
  }
  return { height: 400, offset: 0 };
};

const RegularClassList = ({ regularClassList, selectedSubject, hasMore }) => {
  useStyles(styles);
  const dispatch = useDispatch();
  const { t } = useTranslation(['landingPage']);
  const { imageType, setContainerRef } = useGetCurrentImageType();
  const { screenWidth } = useContext(ApplicationContext);
  const getOfficialSiteURL = useGetOfficialSiteURL();
  const lazyLoadSettings = useMemo(() => getLazyLoadSettings(screenWidth), [
    screenWidth,
  ]);

  const onLinkClick = ({ id, name, instructorIdList, instructorNameList }) => {
    dispatch(
      sendGTMEvent({
        eventName: REGULAR_CLASS_TOPIC_CLICK,
        data: {
          topicId: id,
          topicName: name,
          instructorId: instructorIdList,
          instructorName: instructorNameList,
          section: HOME,
          subjectTitle: selectedSubject.name,
          subjectId: selectedSubject.id,
        },
      }),
    );
  };

  const handleOnButtonClick = () => {
    dispatch(
      sendGTMEvent({
        eventName: HOME_SEE_MORE_RC_CLICK,
      }),
    );
    dispatch(
      push(
        `${getOfficialSiteURL(LIVE)}?${buildPreservedQuery([
          'subjectId',
          'gradeLevelId',
        ])}`,
      ),
    );
  };

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>{t('home.regularClassSection.subtitle')}</h2>
      <div className={styles.cardList} ref={setContainerRef}>
        {regularClassList.map(item => (
          <LazyLoad
            key={item.id}
            placeholder={<LoadingSkeleton className={styles.loading} />}
            className={styles.classCard}
            {...lazyLoadSettings}
          >
            <ClassCard
              key={item.id}
              pictureUrl={item.picture[imageType]}
              onLinkClick={() => onLinkClick(item)}
              {...item}
            />
          </LazyLoad>
        ))}
      </div>
      {hasMore && (
        <GradientButton
          text={t('home.regularClassSection.featuredClass.cta')}
          color="blue"
          onClick={handleOnButtonClick}
        />
      )}
    </div>
  );
};

RegularClassList.propTypes = {
  regularClassList: array.isRequired,
  hasMore: bool.isRequired,
  selectedSubject: object.isRequired,
};

export default RegularClassList;
