import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';
import useElementHaveSeen from 'hooks/useElementHaveSeen';
import useGetOfficialSiteURL from 'hooks/useGetOfficialSiteURL';
import Link from 'components/Link';
import { ChevronIcon } from 'components/Icon';
import { TUTORS } from 'constants/routingPath';
import { HOME_QA_CTA_CLICK, HOME_SCROLL_DEPTH } from 'constants/gtmEvent';
import { sendGTMEvent } from 'reduxModules/googleTagManager/action';
import TrackVisibility from 'components/TrackVisibility';

import { QA } from '../../constants';
import AllSubjectsContent from '../LearningSection/components/AllSubjectsContent';
import IntroCard from '../IntroCard';
import QASwiper from './components/QASwiper';
import TurtorListContent from './components/TutorListContent';

import commonHorizontalPaddingStyles from '../../commonHorizontalPadding.scss';
import commonHighlightSectionStyles from '../MainOfferingSection/commonHighlightSection.scss';
import styles from './QASection.scss';

const QASection = () => {
  useStyles(
    commonHorizontalPaddingStyles,
    commonHighlightSectionStyles,
    styles,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation(['landingPage']);
  const getOfficialSiteURL = useGetOfficialSiteURL();
  const [highlightSectionRef, highlightSectionHaveSeen] = useElementHaveSeen(
    false,
  );

  const handleClickCTA = () => {
    dispatch(
      sendGTMEvent({
        eventName: HOME_QA_CTA_CLICK,
      }),
    );
  };

  const INTRO_CONTENT_LIST = [
    {
      key: 'allSubjects',
      classNameMap: {
        root: styles.allSubjects,
      },
      content: (
        <AllSubjectsContent
          name={QA}
          showEnterAnimation={highlightSectionHaveSeen}
        />
      ),
    },
    {
      key: 'helpOnDemand',
      classNameMap: {
        root: styles.helpOnDemand,
        content: styles.helpOnDemandContent,
      },
    },
    {
      key: 'tutors',
      classNameMap: {
        root: cx(styles.tutors, styles.tutorsHighlight),
        titleSection: styles.tutorsTitleSection,
        content: styles.tutorsContent,
      },
      content: <TurtorListContent />,
      titleSectionAdditional: (
        <Link
          className={styles.link}
          to={getOfficialSiteURL(TUTORS)}
          onClick={handleClickCTA}
        >
          {t(`home.qaSection.highlights.2.cta`)}
          <ChevronIcon className={styles.icon} />
        </Link>
      ),
    },
  ];

  return (
    <Fragment>
      {/* the TrackVisibility will not working if including highlightSectionRef section for some reasons, so put it here  */}
      <TrackVisibility
        id="qa"
        eventName={HOME_SCROLL_DEPTH}
        threshold={1}
        visibleRatio={50}
      >
        <QASwiper />
      </TrackVisibility>
      <div
        ref={highlightSectionRef}
        className={cx(
          commonHorizontalPaddingStyles.container,
          commonHighlightSectionStyles.container,
          styles.highlightSection,
        )}
      >
        {INTRO_CONTENT_LIST.map(
          ({ key, classNameMap, titleSectionAdditional, content }, index) => (
            <IntroCard
              key={key}
              name={QA}
              classNameMap={classNameMap}
              title={t(`home.qaSection.highlights.${index}.title`)}
              description={t(`home.qaSection.highlights.${index}.description`)}
              titleSectionAdditional={titleSectionAdditional}
              content={content}
            />
          ),
        )}
      </div>
    </Fragment>
  );
};

QASection.propTypes = {};

export default QASection;
