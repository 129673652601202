import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';
import useElementHaveSeen from 'hooks/useElementHaveSeen';
import useGetOfficialSiteURL from 'hooks/useGetOfficialSiteURL';
import {
  INSTRUCTOR_TEMPLATE,
  LIVE,
  REGULAR_CLASS_TYPE,
} from 'constants/routingPath';
import { push } from 'reduxModules/navigation/action';
import { fetchRegularClassLessonList } from 'reduxModules/home/action';
import { getHomeRegularClassLessonList } from 'reduxModules/home/selector';
import {
  HOME_RC_ALL_INSTRUCTOR_CLICK,
  HOME_REGULAR_CLASS_LIST_ENTER,
} from 'constants/gtmEvent';
import { sendGTMEvent } from 'reduxModules/googleTagManager/action';

import { REGULAR_CLASS } from '../../constants';
import ContentListSection from '../ContentListSection';
import AllSubjectsContent from '../AllSubjectsContent';
import ChattingAnimation from '../ChattingAnimation';
import SubSectionTitle from '../MainOfferingSection/components/SubSectionTitle';
import IntroCard from '../IntroCard';
import InstructorsSection from './components/InstructorsSection';

import commonHorizontalPaddingStyles from '../../commonHorizontalPadding.scss';
import commonHighlightSectionStyles from '../MainOfferingSection/commonHighlightSection.scss';
import styles from './RegularClassSection.scss';

const RegularClassSection = () => {
  useStyles(
    commonHorizontalPaddingStyles,
    commonHighlightSectionStyles,
    styles,
  );
  const { t } = useTranslation(['landingPage']);
  const dispatch = useDispatch();
  const getOfficialSiteURL = useGetOfficialSiteURL();
  const lessonList = useSelector(getHomeRegularClassLessonList);
  const [highlightSectionRef, highlightSectionHaveSeen] = useElementHaveSeen(
    false,
  );

  const INTRO_CONTENT_LIST = [
    {
      key: 'allSubjects',
      component: (
        <AllSubjectsContent
          name={REGULAR_CLASS}
          showEnterAnimation={highlightSectionHaveSeen}
        />
      ),
    },
    {
      key: 'chattingAnimation',
      classNameMap: {
        content: styles.chattingAnimation,
      },
      component: <ChattingAnimation />,
    },
  ];

  useEffect(() => {
    dispatch(fetchRegularClassLessonList());
  }, [dispatch]);

  const handleClickCTA = (path, params) => () => {
    dispatch(push(getOfficialSiteURL(path, params)));
    dispatch(
      sendGTMEvent({
        eventName:
          path === LIVE
            ? HOME_REGULAR_CLASS_LIST_ENTER
            : HOME_RC_ALL_INSTRUCTOR_CLICK,
      }),
    );
  };

  return (
    <Fragment>
      <div>
        <SubSectionTitle
          className={commonHorizontalPaddingStyles.container}
          title={t('home.regularClassSection.instructor.title')}
          buttonText={t('home.regularClassSection.instructor.meetCta')}
          onClick={handleClickCTA(INSTRUCTOR_TEMPLATE, {
            id: 'regular-class',
          })}
        />
        <InstructorsSection />
      </div>
      <ContentListSection
        contentList={lessonList}
        title={t('home.regularClassSection.featuredClass.title')}
        description={t('home.regularClassSection.featuredClass.description')}
        CTAbuttonText={t('home.regularClassSection.featuredClass.cta')}
        onClickCTA={handleClickCTA(LIVE)}
        contentType={REGULAR_CLASS_TYPE}
      />
      <div
        ref={highlightSectionRef}
        className={cx(
          commonHorizontalPaddingStyles.container,
          commonHighlightSectionStyles.container,
        )}
      >
        {INTRO_CONTENT_LIST.map(({ key, classNameMap, component }, index) => (
          <IntroCard
            key={key}
            name={REGULAR_CLASS}
            classNameMap={classNameMap}
            title={t(`home.regularClassSection.highlights.${index}.title`)}
            description={t(
              `home.regularClassSection.highlights.${index}.description`,
            )}
            content={component}
          />
        ))}
      </div>
    </Fragment>
  );
};

RegularClassSection.propTypes = {};

export default RegularClassSection;
