// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".-aSVZ{position:relative;-webkit-perspective:1000px;perspective:1000px;cursor:pointer;color:#231815!important}.-aSVZ>div{border-radius:.5rem;width:100%;height:100%;overflow:hidden}.-aSVZ>div._3QboO{min-height:324px;min-height:20.25rem}._2jGg7{position:absolute;opacity:0;z-index:1;-webkit-transition:opacity .3s ease-out;-o-transition:opacity .3s ease-out;transition:opacity .3s ease-out}.-aSVZ:hover ._2jGg7{opacity:1}", ""]);
// Exports
exports.locals = {
	"root": "-aSVZ",
	"hasReservedHeight": "_3QboO",
	"back": "_2jGg7"
};
module.exports = exports;
