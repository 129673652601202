import React from 'react';
import { func } from 'prop-types';

import useStyles from 'hooks/useStyles';
import TriangleWithRound from 'components/TriangleWithRound';

import styles from './PlayButton.scss';

const PlayButton = ({ onClick }) => {
  useStyles(styles);
  return (
    <div className={styles.root} onClick={onClick}>
      <TriangleWithRound
        width={32}
        height={36}
        strokeWidth={8}
        color="#FFFFFF"
      />
    </div>
  );
};

PlayButton.propTypes = {
  onClick: func.isRequired,
};

export default PlayButton;
