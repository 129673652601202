import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AlertBanner as BaseAlertBanner } from '@snapask/common-components';
import { hideAlertBanner } from 'reduxModules/alertBanner/action';
import { getAlertBanner } from 'reduxModules/alertBanner/selector';

const AlertBanner = () => {
  const dispatch = useDispatch();
  const { show, message, type } = useSelector(getAlertBanner);

  return (
    show && (
      <BaseAlertBanner
        type={type}
        alertText={message}
        onClose={() => dispatch(hideAlertBanner())}
      />
    )
  );
};
export default AlertBanner;
