// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3ehmq{position:fixed;width:100%;bottom:0;background:#03d8d8;padding:0 1.5rem;z-index:var(--zIndex-upsell-banner);display:-ms-flexbox;display:flex;min-height:58px;min-height:3.625rem}@media(max-width:767px){._3ehmq{padding:.75rem 1.5rem}}@media(min-width:768px){._3ehmq{-ms-flex-align:center;align-items:center}}._3-nV8{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;-ms-flex:1 1;flex:1 1;min-width:0}@media(max-width:767px){._3-nV8{-ms-flex-pack:start;justify-content:flex-start;-ms-flex-align:start;align-items:flex-start;-ms-flex-direction:column;flex-direction:column}}._2hNtB{-ms-flex-negative:0;flex-shrink:0;cursor:pointer;justify-self:flex-end}._1Sjxs{position:fixed;bottom:150%}@media(max-width:767px){._1coDu{margin-bottom:1rem}}._350dM{margin-right:1.5rem;-ms-flex-item-align:start;align-self:flex-start}@media(max-width:767px){._350dM{display:none}}._1qaf_{display:block;overflow:hidden;white-space:nowrap;-o-text-overflow:ellipsis;text-overflow:ellipsis}@media(min-width:768px){._1qaf_{margin-left:1.5rem}}._1qaf_:active,._1qaf_:focus,._1qaf_:hover{background-color:#231815;color:#fff}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "_3ehmq",
	"content": "_3-nV8",
	"closeIcon": "_2hNtB",
	"linkInput": "_1Sjxs",
	"description": "_1coDu",
	"eve": "_350dM",
	"button": "_1qaf_"
};
module.exports = exports;
