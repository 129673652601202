// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3hZuH{font-size:56px;font-weight:500;line-height:70px;font-family:var(--heading-font);letter-spacing:.5px}@media(max-width:767px){._3hZuH{font-size:40px;line-height:50px}}._2836I{font-size:18px;font-weight:400;line-height:29px;font-family:var(--base-font)}@media(min-width:768px){._2836I{margin-top:1.5rem}}@media(max-width:767px){._2836I{margin-top:1rem}}._2NcHb{max-width:100%}@media(min-width:768px){._2NcHb{margin-top:2rem}}@media(max-width:767px){._2NcHb{margin-top:1rem}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"title": "_3hZuH",
	"description": "_2836I",
	"button": "_2NcHb"
};
module.exports = exports;
