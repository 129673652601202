// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._17DHn{position:absolute;z-index:-1}@media(min-width:1440px){._17DHn{top:-37.5rem;right:-37.5rem;width:75rem;height:75rem}._17DHn ._1FFi_{cx:37.5rem;cy:37.5rem;r:31.25rem;stroke-width:12.5rem;stroke:var(--stroke);fill:none}}@media(max-width:1439px){._17DHn{display:none}}", ""]);
// Exports
exports.locals = {
	"root": "_17DHn",
	"shape": "_1FFi_"
};
module.exports = exports;
