// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Xmfs{cursor:pointer;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;width:100px;width:6.25rem;height:100px;height:6.25rem;border-radius:100%;padding-left:.5rem;background-color:#0373f9}", ""]);
// Exports
exports.locals = {
	"root": "_1Xmfs"
};
module.exports = exports;
