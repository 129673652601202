import React from 'react';
import { useDispatch } from 'react-redux';
import { string, bool } from 'prop-types';
import dayjs from 'dayjs';
import LazyLoad from 'react-lazyload';

import useStyles from 'hooks/useStyles';
import useGetAcademyURL from 'hooks/useGetAcademyURL';

import Link from 'components/Link';
import LoadingSkeleton from 'components/LoadingSkeleton';

import { sendGTMEvent } from 'reduxModules/googleTagManager/action';

import { POST } from 'constants/routingPath';
import { HOME_ACADEMY_CLICK } from 'constants/gtmEvent';

import TriangleWithRound from 'components/TriangleWithRound';

import styles from './PostIntroCard.scss';

const PostIntroCard = ({
  id,
  imageUrl,
  title,
  description,
  author,
  publishAt,
  isVideo,
}) => {
  useStyles(styles);
  const dispatch = useDispatch();
  const getAcademyURL = useGetAcademyURL();
  const date = dayjs(publishAt).format('D MMM YYYY');

  const handleLinkClick = () => {
    dispatch(
      sendGTMEvent({ eventName: HOME_ACADEMY_CLICK, data: { postId: id } }),
    );
  };
  return (
    <div className={styles.root}>
      <Link
        className={styles.link}
        onClick={handleLinkClick}
        to={getAcademyURL(POST, { id })}
      />
      <div className={styles.imgMask}>
        <LazyLoad
          height={200}
          offset={[-200, 0]}
          placeholder={<LoadingSkeleton className={styles.loading} />}
        >
          <img className={styles.img} alt={title} src={imageUrl} />
        </LazyLoad>
        {isVideo && (
          <div className={styles.playerIcon}>
            <TriangleWithRound width={14} height={18} color="#FFFFFF" />
          </div>
        )}
      </div>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.description}>{description}</p>
      <span className={styles.authorAndPublishAt}>{`${author}．${date}`}</span>
    </div>
  );
};

PostIntroCard.propTypes = {
  id: string.isRequired,
  imageUrl: string.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  author: string.isRequired,
  publishAt: string.isRequired,
  isVideo: bool,
};

PostIntroCard.defaultProps = {
  isVideo: false,
};

export default PostIntroCard;
