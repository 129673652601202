import React from 'react';
import { node, number, string } from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { sendGTMEvent } from 'reduxModules/googleTagManager/action';

const TrackVisibility = ({
  children,
  eventName,
  threshold,
  visibleRatio,
  id,
}) => {
  const dispatch = useDispatch();

  const { ref } = useInView({
    triggerOnce: true,
    threshold,
    onChange: inView => {
      if (inView) {
        dispatch(
          sendGTMEvent({
            eventName,
            data: {
              'gtm.elementId': id,
              'gtm.visibleRatio': visibleRatio,
            },
          }),
        );
      }
    },
  });

  return (
    <div ref={ref} data-shit={id}>
      {children}
    </div>
  );
};

TrackVisibility.propTypes = {
  children: node.isRequired,
  id: string.isRequired,
  eventName: string.isRequired,
  threshold: number,
  visibleRatio: number,
};

TrackVisibility.defaultProps = {
  threshold: 0.5,
  visibleRatio: 50,
};

export default TrackVisibility;
