import React, { useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import LazyLoad from 'react-lazyload';

import useStyles from 'hooks/useStyles';
import useGetCurrentImageType from 'hooks/useGetCurrentImageType';

import { fetchBannerList } from 'reduxModules/home/action';
import { getHomeBannerList } from 'reduxModules/home/selector';
import { sendGTMEvent } from 'reduxModules/googleTagManager/action';

import BaseSwiper from 'components/BaseSwiper';
import Link from 'components/Link';
import LoadingSkeleton from 'components/LoadingSkeleton';
import LoadedImage from 'components/LoadedImage';

import { HOME_BANNER_CLICK } from 'constants/gtmEvent';

import { TABLET_WIDTH } from 'styles/variables.scss';
import commonSwiperStyles from '../commonSwiper.scss';
import styles from './BannerSection.scss';

const BannerSection = forwardRef(({ className }, ref) => {
  useStyles(commonSwiperStyles, styles);
  const dispatch = useDispatch();
  const bannerList = useSelector(getHomeBannerList);

  const { imageType, setContainerRef } = useGetCurrentImageType(true);

  useEffect(() => {
    dispatch(fetchBannerList());
  }, [dispatch]);

  const breakpoints = {
    [TABLET_WIDTH]: {
      slidesPerView: 2,
      slidesPerGroup: 1,
    },
    0: {
      slidesPerView: 1.2,
      slidesPerGroup: 1,
    },
  };

  const handleClick = ({ id, content }) => {
    dispatch(
      sendGTMEvent({
        eventName: HOME_BANNER_CLICK,
        data: {
          bannerId: id,
          bannerTitle: content,
        },
      }),
    );
  };

  const renderSlide = ({ id, url, content, image }) => (
    <Link
      newTab
      key={id}
      to={url}
      className={styles.slide}
      data-ooo-image-container
      onClick={() => handleClick({ id, content })}
    >
      <LazyLoad
        height={118}
        offset={[-118, 0]}
        placeholder={<LoadingSkeleton className={styles.loading} />}
      >
        <LoadedImage
          className={styles.img}
          alt={content || null}
          src={image[imageType]}
        />
      </LazyLoad>
    </Link>
  );

  const setRef = element => {
    // eslint-disable-next-line no-param-reassign
    ref.current = element;
    setContainerRef(element);
  };

  if (bannerList.length === 0) {
    return null;
  }

  return (
    <div ref={setRef} className={className}>
      {bannerList.length === 1 ? (
        <div className={styles.singleSlideWrapper}>
          {bannerList.map(renderSlide)}
        </div>
      ) : (
        <BaseSwiper
          id="banner-section-swiper"
          classNameMap={{
            root: commonSwiperStyles.root,
          }}
          slideDataList={bannerList}
          renderSlide={renderSlide}
          breakpoints={breakpoints}
          navigation
        />
      )}
    </div>
  );
});

BannerSection.propTypes = {
  className: string.isRequired,
};

BannerSection.displayName = 'BannerSection';

export default BannerSection;
