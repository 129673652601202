import React, { useEffect, useRef, useState } from 'react';
import { number, bool, node, shape, string } from 'prop-types';
import cx from 'classnames';

import useStyles from 'hooks/useStyles';
import useBaseOnWindowSize from 'hooks/useBaseOnWindowSize';

import styles from './Marquee.scss';

const Marquee = ({ classNameMap, speed, pauseOnHover, children }) => {
  useStyles(styles);
  const [containerWidth, setContainerWidth] = useState(0);
  const [marqueeWidth, setMarqueeWidth] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const containerRef = useRef();
  const marqueeRef = useRef();

  const calculateWidth = () => {
    if (marqueeRef.current && containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
      setMarqueeWidth(marqueeRef.current.offsetWidth);
    }
  };

  useBaseOnWindowSize(calculateWidth);

  useEffect(() => {
    const width = marqueeWidth < containerWidth ? containerWidth : marqueeWidth;
    setDuration(width / speed);
  }, [marqueeWidth, containerWidth, speed]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const marqueeRefList = [marqueeRef, null];

  return (
    isMounted && (
      <div
        ref={containerRef}
        className={cx(styles.container, classNameMap.container)}
      >
        {marqueeRefList.map((ref, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            ref={ref}
            className={cx(styles.marquee, classNameMap.marquee)}
            style={{
              '--pause-on-hover': pauseOnHover ? 'paused' : 'running',
              '--duration': `${duration}s`,
            }}
          >
            {children}
          </div>
        ))}
      </div>
    )
  );
};

Marquee.propTypes = {
  children: node.isRequired,
  speed: number,
  pauseOnHover: bool,
  classNameMap: shape({
    container: string,
    marquee: string,
  }),
};

Marquee.defaultProps = {
  speed: 70,
  pauseOnHover: false,
  classNameMap: {},
};

export default Marquee;
