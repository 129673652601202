import { useState, useEffect } from 'react';
import useElementVisibility from './useElementVisibility';

export default function useElementHaveSeen() {
  const [elementRef, elementVisible] = useElementVisibility(false);
  const [haveSeen, setHaveSeen] = useState(false);

  useEffect(() => {
    if (elementVisible && !haveSeen) {
      setHaveSeen(true);
    }
  }, [elementVisible, haveSeen]);

  return [elementRef, haveSeen];
}
