import React, { useState, useEffect, useRef, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { string, func, array, bool, oneOf, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { isMobile } from 'react-device-detect';

import useStyles from 'hooks/useStyles';

import ShortcutTooltip from '../ShortcutTooltip';
import styles from './Controller.scss';

const Controller = ({
  name,
  onClick,
  list,
  content,
  tooltipPlacement,
  shortcutList,
  hoverInPlayer,
  triggerCustomizedController: { trigger, name: triggerName },
  resetTriggerCustomizedController,
  hideInMobile,
}) => {
  useStyles(styles);
  const { t } = useTranslation(['common']);
  const menuControllerRef = useRef();
  const [haveWindow, setHaveWindow] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(prevMenuOpen => {
      document[prevMenuOpen ? 'removeEventListener' : 'addEventListener'](
        'click',
        // eslint-disable-next-line no-use-before-define
        handleOutsideClick,
      );

      return !prevMenuOpen;
    });
  };

  let handleOutsideClick = e => {
    if (!menuControllerRef.current.contains(e.target)) {
      toggleMenu();
    }
  };

  useEffect(() => {
    setHaveWindow(true);
  }, []);

  useEffect(() => {
    if (!hoverInPlayer) {
      setMenuOpen(false);
    }
  }, [hoverInPlayer]);

  useEffect(() => {
    if (triggerName === name && trigger) {
      toggleMenu();
      resetTriggerCustomizedController();
    }
  }, [trigger]);

  useEffect(() => {
    if (menuControllerRef.current) {
      menuControllerRef.current.classList[menuOpen ? 'add' : 'remove'](
        'vjs-hover',
      );
    }
  }, [menuOpen]);

  if (!haveWindow) {
    return null;
  }

  if (isMobile && hideInMobile) {
    return null;
  }

  const hasMenu = list.length > 0;
  const controllerClassName = `vjs-${name}`;

  const cotrollerButton = (
    <Fragment>
      <button
        className={cx(
          {
            'vjs-control': !hasMenu,
            'vjs-menu-button': hasMenu,
          },
          controllerClassName,
          'vjs-button',
        )}
        type="button"
        title={name}
        onClick={hasMenu ? toggleMenu : onClick}
      >
        <span className="vjs-icon-placeholder" />
      </button>
      <ShortcutTooltip
        controllerClassName={controllerClassName}
        name={name}
        shortcutList={shortcutList}
        placement={tooltipPlacement}
      />
    </Fragment>
  );

  const renderMulitText = textList =>
    textList.map(({ i18nKey, text }) => (
      <span key={text || i18nKey}>
        {i18nKey ? t(`common.videoPlayer.${i18nKey}`) : text}
      </span>
    ));

  if (list.length > 0) {
    return createPortal(
      <div
        ref={menuControllerRef}
        className={`vjs-control ${controllerClassName} vjs-menu-button vjs-menu-button-popup vjs-button`}
      >
        {cotrollerButton}
        <div className="vjs-menu">
          <ul className="vjs-menu-content">
            {list.map(({ name: itemName, text }) => {
              const mulitText = Array.isArray(text);
              return (
                <li key={itemName} className="vjs-menu-item">
                  <span
                    className={cx('vjs-menu-item-text', {
                      [styles.mulitText]: mulitText,
                    })}
                  >
                    {mulitText ? renderMulitText(text) : text}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>,
      document.querySelector('.vjs-control-bar'),
    );
  }

  return createPortal(
    <div className={`vjs-control ${controllerClassName} vjs-button`}>
      {content}
      {cotrollerButton}
    </div>,
    document.querySelector('.vjs-control-bar'),
  );
};

Controller.propTypes = {
  name: string.isRequired,
  hoverInPlayer: bool.isRequired,
  shortcutList: array.isRequired,
  triggerCustomizedController: object.isRequired,
  resetTriggerCustomizedController: func.isRequired,
  tooltipPlacement: oneOf(['left', 'center', 'right']),
  content: string,
  onClick: func,
  list: array,
  hideInMobile: bool,
};

Controller.defaultProps = {
  onClick: () => {},
  list: [],
  content: '',
  tooltipPlacement: 'center',
  hideInMobile: false,
};

export default Controller;
