// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2pdg2{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;grid-gap:12px;gap:12px;grid-gap:.75rem;gap:.75rem;text-transform:none}", ""]);
// Exports
exports.locals = {
	"mulitText": "_2pdg2"
};
module.exports = exports;
