// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3zDS2{display:-ms-flexbox;display:flex;-ms-flex-negative:0;flex-shrink:0}@media(min-width:1024px){._3zDS2{grid-gap:1.5rem;gap:1.5rem;-ms-flex-wrap:wrap;flex-wrap:wrap;width:29.25rem;height:100%}}@media(max-width:1023px){._1R0xk{grid-gap:2rem;gap:2rem;padding-right:2rem}}._1flaR{width:100%;height:120px;height:7.5rem}._3earV{overflow:hidden;border-radius:50%;-o-object-fit:cover;object-fit:cover}@media(min-width:1024px){._3earV:nth-child(6n+1){margin-left:3.75rem}}@media(min-width:768px){._3earV{width:7.5rem;height:7.5rem}}@media(max-width:767px){._3earV{width:5.625rem;height:5.625rem}}", ""]);
// Exports
exports.locals = {
	"NAVBAR_HEIGHT": "72",
	"TABLET_WIDTH": "768",
	"DESKTOP_WIDTH": "1024",
	"WIDE_DESKTOP_WIDTH": "1440",
	"root": "_3zDS2",
	"marquee": "_1R0xk",
	"loading": "_1flaR",
	"picture": "_3earV"
};
module.exports = exports;
